body {

	// Legacy v8.0.4
    .ui-dialog {
        &.ui-shadow {
            @include overlay-content-shadow();
        }

        .ui-dialog-titlebar {
            background-color: #ffffff;
            color: $textColor;
            padding: $headerPadding;

            .ui-dialog-title {
                font-weight: 500;
                font-size: 20px;
                line-height: 32px;
                margin: 0;
            }

            .ui-dialog-titlebar-icon {
                padding: 0;
                margin-top: .4em;
                @include border-radius(50%);
                @include transition(background-color $transitionDuration);

                &:hover, &:focus {
                    @include hover-element();
                }

                .pi {
                    color: $textSecondaryColor;
                    display: inline-block;
                }

                .pi-window-maximize {
                    @include icon_override('fullscreen');
                }

                .pi-window-minimize {
                    @include icon_override('fullscreen_exit');
                }
            }
        }

        .ui-dialog-content {
            padding: $contentPadding;
        }

        .ui-dialog-footer {
            text-align: right;
            padding: $headerPadding;
            border: 0 none;

            .ui-button {
                width: auto;
                margin-right: .5em;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .ui-confirm-dialog-severity {
            margin: 0 .75em;
        }

        p {
            line-height: $panelContentLineHeight;
        }
    }


	// v14.01
	.p-dialog {
		background-color: $contentBgColor;
		border: 1px solid $contentBorderColor;
		@include border-radius(3px);
		@include topBarClearance();
		max-width: 90%; // currently max-height 90% by default

		.p-dialog-header {
			background-color: #ffffff;
			color: $textColor;
			padding: .714em 10px .714em 1em;


			.p-dialog-title {
				font-weight: 500;
				font-size: 20px;
				line-height: 32px;
				margin: 0;
			}

			.p-dialog-header-icon {
				padding: 4px;
				// margin-top: .4em;
				margin-left: 72px; // to leave a minimum gap between icon and dialog header
				@include border-radius(50%);
				@include transition(background-color $transitionDuration);

				&:hover, &:focus {
					@include hover-element();
				}

				/*.pi {
					color: $textSecondaryColor;
					display: inline-block;
				}

				.pi-window-maximize {
					@include icon_override('fullscreen');
				}

				.pi-window-minimize {
					@include icon_override('fullscreen_exit');
				}*/
			}
		}

		.p-dialog-content {
			padding: $contentPadding;
		}

		.p-dialog-footer p-footer {
			display: flex;
			justify-content: flex-end;
			column-gap: 8px;
			padding: 30px 14px 14px;

			.p-button {
				/*p-button-icon {
					display: flex;
					align-items: center;
				}*/

				.p-button-icon-left {
					margin-right: 14px;
				}
			}
		}
	}

    .ui-sidebar {
        .ui-sidebar-close {
            &:hover {
                padding: 1px;
            }
        }

        .ui-button {
            width: auto;
        }
    }

    .ui-lightbox {
        &.ui-shadow {
            @include overlay-content-shadow();
        }

        .ui-lightbox-caption {
            padding: $headerPadding;

            .ui-lightbox-caption-text {
                margin: 0;
            }

            .ui-lightbox-close {
                @include border-radius(50%);
                @include transition(background-color $transitionDuration);
                padding: 0;
                margin: 0;
                width: $iconWidth;
                height: $iconHeight;

                &:hover {
                    @include hover-element-primary();
                    padding: 0;
                }
            }
        }

        .ui-lightbox-content-wrapper {
            .ui-lightbox-nav-right, .ui-lightbox-nav-left {
                top: 40%;

                .pi {
                    @include transition(color $transitionDuration);
                    font-size: 48px;
                    color: $primaryLightColor;
                }

                &:hover {
                    .pi {
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .ui-overlaypanel {
        &.ui-shadow {
            @include overlay-content-shadow();
        }

        &:after {
            border-color: rgba($contentBgColor, 0);
            border-bottom-color: $contentBgColor;
        }

        &:before {
            border-color: rgba($dividerColor, 0);
            border-bottom-color: $dividerColor;
        }

        &.ui-overlaypanel-flipped {
            &:after {
                border-top-color: $contentBgColor;
            }

            &:before {
                border-top-color: $dividerColor;
            }
        }

        .ui-overlaypanel-close {
            background-color: $accentColor;
            @include border-radius(50%);
            @include transition(background-color $transitionDuration);
            right: -16px;
            top: -16px;
            width: 2em;
            height: 2em;
            line-height: 2em;
            text-align: center;
            @include content-shadow();

            span {
                color: $accentTextColor;
                line-height: inherit;
            }

            &:hover {
                background-color: $accentDarkColor;
            }
        }
    }

    .ui-tooltip {
        @include opacity(.9);
        font-size: $fontSize - 2px;

        .ui-tooltip-text {
            background-color: #323232;
            @include overlay-content-shadow();
        }

        &.ui-tooltip-top {
            .ui-tooltip-arrow {
                bottom: 1px;
                border-top-color: #323232;
            }
        }

        &.ui-tooltip-bottom {
            .ui-tooltip-arrow {
                top: 1px;
                border-bottom-color: #323232;
            }
        }

        &.ui-tooltip-left {
            .ui-tooltip-arrow {
                border-left-color: #323232;
            }
        }

        &.ui-tooltip-right {
            .ui-tooltip-arrow {
                left: 1px;
                border-right-color: #323232;
            }
        }
    }

    .ui-state-error,
    .ui-widget.ui-state-error,
    .ui-widget-content .ui-state-error,
    .ui-widget-header .ui-state-error {
        border-color: #e62a10;
    }
}
