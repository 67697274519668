.dashboard {

    .task-box {
        @include multi-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14));

        .task-box-header {
            @include clearfix();
            padding: 8px 14px;

            i {
                float: right;
                color: #ffffff;
            }
        }

        .task-box-content {
            background-color: #ffffff;
            padding: 8px 14px;

            h3 {
                font-weight: bold;
                font-size: $fontSize;
                margin: 14px 0 7px 0;
                padding: 0;
            }

            p {
                color: $textSecondaryColor;
                margin: 0 0 28px 0;
                padding: 0;
            }
        }

        .task-box-footer {
            @include clearfix();
            background-color: #ffffff;
            padding: 8px 14px;

            img {
                width: 32px;
                float: right;
                margin-left: 4px;
            }

            .task-status {
                @include border-radius(9px);
                padding: 2px 8px;
                color: #ffffff;
            }
        }

        &.task-box-1 {
            .task-box-header {
                background-color: #e91e63;
            }

            .task-box-footer {
                .task-status {
                    background-color: #e91e63;
                }
            }
        }

        &.task-box-2 {
            .task-box-header {
                background-color: #ffc107;
            }

            .task-box-footer {
                .task-status {
                    background-color: #ffc107;
                }
            }
        }

        &.task-box-3 {
            .task-box-header {
                background-color: #00bcd4;
            }

            .task-box-footer {
                .task-status {
                    background-color: #00bcd4;
                }
            }
        }
    }

    .overview-box {
        text-align: center;
        color: #ffffff;
        @include multi-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14));

        .overview-box-header {
            height: 24px;
        }

        .overview-box-content {
            padding: 8px 14px 14px 14px;

            .overview-box-icon {
                @include border-radius(50%);
                width: 40px;
                height: 40px;
                line-height: 40px;
                margin: 0 auto;
                margin-top: -28px;

                i {
                    line-height: inherit;
                    font-size: 28px;
                }
            }

            .overview-box-title {
                font-size: 16px;
            }

            .overview-box-count {
                font-size: 24px;
            }
        }

        &.overview-box-1 {
            .overview-box-header {
                background-color: #f06292;
            }

            .overview-box-content {
                background-color: #e91e63;

                .overview-box-icon {
                    background-color: #e91e63;
                }
            }
        }

        &.overview-box-2 {
            .overview-box-header {
                background-color: #4dd0e1;
            }

            .overview-box-content {
                background-color: #00bcd4;

                .overview-box-icon {
                    background-color: #00bcd4;
                }
            }
        }

        &.overview-box-3 {
            .overview-box-header {
                background-color: #ffd54f;
            }

            .overview-box-content {
                background-color: #ffc107;

                .overview-box-icon {
                    background-color: #ffc107;
                }
            }
        }

        &.overview-box-4 {
            .overview-box-header {
                background-color: #9e9e9e;
            }

            .overview-box-content {
                background-color: #616161;

                .overview-box-icon {
                    background-color: #616161;
                }
            }
        }
    }

    .task-list {
        overflow: hidden;

        .ui-panel {
            min-height: 340px;
            border: 0 none;
            @include multi-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14));
        }

        .ui-panel-content {
            padding: 10px 0 0 0 !important;
        }

        button {
            margin-top: -28px;
            margin-right: 10px;
            float: right;
        }

        .task-list-container {
            list-style-type: none;
            margin: 0;
            padding: 0;

            > li {
                padding: 10px 14px;
                border-bottom: 1px solid #dbdbdb;

                &:first-child {
                    margin-top: 10px;
                }
            }

            .ui-chkbox {
                vertical-align: middle;
                margin-right: 5px;
            }

            .task-name {
                vertical-align: middle;
            }

            i {
                color: $textSecondaryColor;
                float: right;
            }
        }
    }

    .contact-form {
        overflow: hidden;

        .ui-panel {
            min-height: 340px;
            border: 0 none;
            @include multi-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14));
        }

        .ui-g-12 {
            padding: 16px 10px;
        }

        .ui-button {
            margin-top: 20px;
        }
    }

    .messages {
        overflow: hidden;

        .ui-panel {
            min-height: 340px;
            border: 0 none;
            @include multi-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14));
        }

        .ui-panel-content {
            padding: 15px 0 10px 0 !important;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                border-bottom: 1px solid #d8d8d8;

                > div {
                    display: inline-block;
                    padding: 9px;
                    width: 100%;
                    box-sizing: border-box;
                    text-decoration: none;
                    position: relative;
                    display: block;
                    @include border-radius(2px);
                    @include transition(background-color .2s);
                    @include clearfix();

                    img {
                        float: left;
                    }

                    > div {
                        float: left;
                        margin-left: 10px;

                        .name {
                            font-size: 14px;
                            font-weight: 700;
                            display: block;
                            color: $textColor;
                        }

                        .message {
                            font-size: 14px;
                            color: $textSecondaryColor;
                        }
                    }

                    button {
                        position: absolute;
                        top: 15px;

                        &.message-btn {
                            right: 20px;
                        }

                        &.remove-btn {
                            right: 60px;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: #e8e8e8;
                    }
                }

                &:last-child {
                    border: 0;
                }
            }
        }
    }

    .chat {
        .ui-panel {
            border: 0 none;
            @include multi-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14));
        }

        .ui-panel-content {
            padding: 0 !important;
        }
        
        .ui-tabview {
            border-color: transparent;
            
            .ui-tabview-panel {
                border: 0 none;
            }
        }

        .chat-messages {
            padding: 12px;
            margin: 0;
            list-style-type: none;

            li {
                padding: 6px 0;
                @include clearfix();

                img {
                    width: 36px;
                    float: left;
                }

                span {
                    padding: 6px 12px;
                    float: left;
                    display: inline-block;
                    margin: 4px 0;
                    @include border-radius(10px);
                }

                &.message-from {
                    img, span {
                        float: left;
                    }

                    img {
                        margin-right: 8px;
                    }

                    span {
                        background-color: #e8eaf6;
                    }
                }

                &.message-own {
                    img, span {
                        float: right;
                    }

                    img {
                        margin-left: 8px;
                    }

                    span {
                        background: #c8e6c9;
                        color: #000000;
                    }
                }
            }
        }

        .new-message {
            height: 40px;
            border-top: 1px solid #dce2e7;
            color: #afafc0;

            .message-attachment {
                display: inline-block;
                border-right: 1px solid #dce2e7;
                width: 40px;
                line-height: 40px;
                height: 100%;
                text-align: center;

                i {
                    line-height: inherit;
                    font-size: 24px;
                }
            }

            .message-input {
                position: relative;
                top: -6px;
                width: calc(100% - 100px);
                display: inline-block;

                input {
                    border: 0 none;
                    font-size: 14px;
                    width: 100%;
                    background-color: transparent;
                    outline: 0 none;
                    color: $textSecondaryColor;
                }
            }
        }
    }

    .global-sales {
        .header-helper {
            float:right;
            @include opacity(.7);
        }

        .ui-panel {
            border: 0 none;
            @include multi-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14));
        }

        .ui-panel-content {
            padding: 14px 9px 0px 9px;
        }

        table {
            width: 100%;
            border-collapse: collapse;

            th {
                font-weight: 700;
                text-align: left;
                padding: 8px 5px;
            }

            tbody {
                tr {
                    border-top: 1px solid $dividerColor;

                    img {
                        width: 36px;
                        height: 36px;
                    }

                    td {
                        padding: 8px 5px;

                        &:nth-child(1) {
                            font-weight: 700;
                            text-align: center;
                        }

                        &:nth-child(3) {
                            font-weight: 700;
                        }

                        &:nth-child(7) {
                            text-align: right;

                            button {
                                margin-left: 10px;
                            }
                        }

                        .up-arrow {
                            color: #cddc39;
                        }

                        .down-arrow {
                            color: #e91e63;
                        }
                    }
                }
            }
        }
    }

    .status-bars {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                padding: 8px 14px;
                position: relative;

                span {
                    position: absolute;
                    right: 36px;
                    top: 8px;
                }

                i {
                    position: absolute;
                    right: 4px;
                    top: 4px;
                }
            }
        }

        .status-bar {
            height: 18px;
            width: 75%;
            background-color: #d8d8d8;
            @include border-radius(6px);

            .status-bar-value {
                height: 100%;
                color: #ffffff;
                text-align: right;
                padding-right: 4px;
                padding-top: 1px;
                @include box-sizing(border-box);
                @include border-radius(6px);
            }

            &.status-bar-1 {
                .status-bar-value {
                    background-color: #e91e63;
                }

                ~ i {
                    color: #e91e63;
                }
            }

            &.status-bar-2 {
                .status-bar-value {
                    background-color: #00bcd4;
                }

                ~ i {
                    color: #00bcd4;
                }
            }

            &.status-bar-3 {
                .status-bar-value {
                    background-color: #ffc107;
                }

                ~ i {
                    color: #ffc107;
                }
            }

            &.status-bar-4 {
                .status-bar-value {
                    background-color: #cddc39;
                }

                ~ i {
                    color: #cddc39;
                }
            }

            &.status-bar-5 {
                .status-bar-value {
                    background-color: #ff9800;
                }

                ~ i {
                    color: #ff9800;
                }
            }
        }
    }

    .image-box {
        .card {
            padding: 0;
            img {
                width: 100%;
            }

            .image-box-content {
                padding: 16px;

                h3 {
                    font-weight: 700;
                    margin-top: 0;
                }

                .image-box-tag {
                    width: 40px;
                    text-align: left;
                    color: #ffffff;
                    background-color: #e91e63;
                    padding: 0 8px;
                    @include border-radius(6px);
                }
            }

            .image-box-footer {
                text-align: right;
            }
        }
    }

    .user-card {
        border:1px solid $dividerColor;
        padding: 0;
        @include border-radius($borderRadius);

        .user-card-header {
            height: 100px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .user-card-content {
            min-height: 340px;
            background-color: #ffffff;
            position: relative;

            img {
                position: absolute;
                top: -90px;
                left: 24px;
            }

            .ui-button {
                position: absolute;
                width: 36px;
                height: 36px;
                top: -18px;
                right: 24px;
            }

            .user-card-name {
                font-size: 20px;
                color: $textColor;
                position: absolute;
                top: -60px;
                margin-left: 110px;
                font-weight: 700;
                white-space: nowrap;
            }

            .user-detail {
                text-align: left;

                ul {
                   padding: 0px 0 32px 0;
                   margin: 0;
                   list-style-type: none;

                    li {
                        padding: 16px 24px;
                        border-top: 1px solid $dividerColor;

                        &:last-child {
                            border-bottom: 1px solid $dividerColor;
                        }

                        i {
                            font-size: 24px;
                            margin-right: 8px;
                            width: 32px;
                            vertical-align: middle;
                            color: $textSecondaryColor;
                        }

                        .project-title {
                            font-weight: 700;
                            margin-right: 8px;
                        }

                        .project-detail {
                            color: $textSecondaryColor;
                        }

                        .project-progressbar {
                            display: inline-block;
                            width: 100px;
                            background-color: $dividerColor;
                            float: right;
                            margin-top: 12px;

                            .project-progressbar-value {
                                background-color: $primaryColor;
                                height: 4px;
                            }
                        }
                    }
                }
            }
        }
    }

    .map {
        .ui-panel {
            border: 0 none;
            @include multi-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14));

            .ui-panel-content {
                padding: 8px;

                img {
                    width: 100%;
                }
            }
        }
    }

    .schedule {
        .ui-panel {
            border: 0 none;
            @include multi-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14));
            
            .fc-today-button {
                display: none;
            }
        }
    }
}

@media (max-width: 640px) {
    .dashboard {
        .status-bars {
            .status-bar {
                width: 65%;
            }
        }
        
        .global-sales {
            table {
                tbody {
                    tr td:nth-child(7) {
                        text-align: left;
                        
                        button {
                            display: block;
                            margin-left: 0;
                            
                            &:first-child {
                                margin-bottom: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
}
