/* Sidebar */
$sidebarLogoBgColor:#424242;
$sidebarBgColor:#ffffff;
$darkSidebarBgColor:#424242;

/* Primary */
$primaryColor:#757575;
$primaryDarkColor:#424242;
$primaryLightColor:#BDBDBD;
$primaryLightestColor:#FAFAFA;
$primaryTextColor:#ffffff;

/* Accent */
$accentColor:#FFA910;
$accentLightColor:lighten($accentColor, 20%);
$accentTextColor:#ffffff;

/* Topbar */
$topbarTextColor:#FAFAFA;
$topbarIconColor:#FAFAFA;

/* Submenu */
$submenuBgColor:#EEEEEE;
$darkSubmenuBgColor:#616161;
$horizontalSubmenuBgColor:#EEEEEE;
$horizontalDarkSubmenuBgColor:#616161;
$horizontalSubmenuitemHoverBgColor:#E0E0E0;
$horizontalSubmenuitemDarkHoverBgColor:#545454;
$horizontalSubmenuitemDarkHoverTextColor:#ffffff;
$horizontalMenuActiveTextColor:#ffffff;
$horizontalMenuActiveHoverTextColor:#212121;
$horizontalDarkMenuActiveTextColor:#ffffff;
$horizontalDarkMenuActiveHoverTextColor:#ffffff;

/* Default MenuItem */
$menuitemTextColor:#212121;
$menuitemIconTextColor:#616161;

/* Hover MenuItem */
$menuitemHoverBgColor:#E0E0E0;
$menuitemHoverTextColor:#212121;
$menuitemHoverIconTextColor:#212121;

/* Active MenuItem */
$menuitemActiveBgColor:#FFA910;
$menuitemActiveTextColor:#ffffff;
$menuitemActiveIconTextColor:#ffffff;
$subMenuitemActiveTextColor:#757575;
$subMenuitemActiveIconTextColor:#757575;

/* Dark Default MenuItem */
$darkMenuitemTextColor:#dee0e3;
$darkMenuitemIconTextColor:#dee0e3;

/* Dark Hover MenuItem */
$darkMenuitemHoverBgColor:#545454;
$darkMenuitemHoverTextColor:#ffffff;
$darkMenuitemHoverIconTextColor:#ffffff;

/* Dark Active MenuItem */
$darkMenuitemActiveBgColor:#7986CB;
$darkMenuitemActiveTextColor:#ffffff;
$darkMenuitemActiveIconTextColor:#ffffff;
$darksubMenuitemActiveTextColor:#7986CB;
$darksubMenuitemActiveIconTextColor:#7986CB;

@import '../../sass/layout/_layout';
