body {
    .ui-breadcrumb {
        a {
            color: #ffffff;
        }

        li:first-child {
            a {
                position: relative;
                margin-top: 0;

                span {
                    font-size: $iconFontSize;
                }
            }
        }

        .ui-breadcrumb-chevron {
            margin: 0;
            font-size: $iconFontSize;
        }

        li {
            margin: 0;
        }
    }

    .ui-steps {
        position: relative;

        .ui-steps-item {
            background-color: transparent;

            &.ui-state-disabled {
                @include opacity(1);
            }

            .ui-menuitem-link {
                display: inline-block;
                text-align: left;
                background-color: #ffffff;
                overflow: hidden;

                .ui-steps-number {
                    display: inline-block;
                    background-color: #757575;
                    @include border-radius(50%);
                    font-size: 16px;
                    color: #ffffff;
                    vertical-align: middle;
                    text-align: center;
                    width: 25px;
                    height: 27px;
                    padding: 0;
                    line-height: 27px;
                }

                .ui-steps-title {
                    display: inline;
                    margin-left: .714em;
                    color: $textSecondaryColor;
                }
            }

            &.ui-state-highlight {
                .ui-steps-number {
                    background-color: $primaryColor;
                }

                .ui-steps-title {
                    font-weight: 700;
                    color: $textColor;
                }
            }

            &:last-child {
                .ui-menuitem-link {
                    display: block;
                }
            }
        }

        &:before {
            content:' ';
            border: 1px solid $dividerColor;
            width: 90%;
            top: 45%;
            left: 0;
            display: block;
            position: absolute;
        }
    }

    .ui-menu .ui-menuitem-link,
    .ui-menubar .ui-menuitem-link,
    .ui-tieredmenu .ui-menuitem-link,
    .ui-contextmenu .ui-menuitem-link,
    .ui-megamenu .ui-menuitem-link,
    .ui-slidemenu .ui-menuitem-link {
        padding: $menuitemPadding;
        color: $textColor;
        min-height: 2.286em;
        @include border-radius(0);

        &:hover {
            @include hover-element();
        }

        &:focus {
            @include hover-element();
        }

        .ui-menuitem-text {
            vertical-align: middle;
        }

        .ui-menuitem-icon {
            font-size: 18px;
            color: $textSecondaryColor;
        }

        .ui-submenu-icon {
            right: .857em;
            margin-right: -.5em;
        }
    }

	.ui-menubar, .ui-contextmenu,
	.ui-slidemenu, .ui-tieredmenu {
		.ui-submenu-list {
			padding: .5em 0;

			.ui-menuitem {
				margin: 0;
			}
		}
	}

	.ui-menu {
		min-width: 12.5em;
		padding: $menuListPadding;

		.ui-menuitem {
			margin: 0;
			@include border-radius(0);

			.ui-menuitem-link {
				.ui-menuitem-icon {
					vertical-align: middle;
				}
			}
		}

		&.ui-shadow, .ui-shadow {
			@include overlay-input-shadow();
		}

		.ui-submenu-header {
			border: 0 none;
			margin: 0;
			font-size: $fontSize;
			padding: $menuitemPadding;
			font-weight: 400;
		}
	}

	.p-menu {
		min-width: 12.5em;
		margin-top: 8px;
		background-color: #fff;
		@include border-radius(3px);

		&.ng-trigger-overlayAnimation {
			@include overlay-input-shadow();
		}

		.p-menuitem-link {
			padding: $menuitemPadding;
			color: $textColor;
			min-height: 2.286em;
			@include border-radius(0);

			&:hover {
				@include hover-element();
			}

			&:focus {
				@include hover-element();
			}

			.p-menuitem-icon + .p-menuitem-text {
				margin-left: 8px;
				display: flex;
				align-items: center;
				line-height: unset; // override default primeNG value
		}

		.p-menuitem-icon {
			font-size: 18px;
			color: $textSecondaryColor;
			vertical-align: middle;
		}

		/*.ui-submenu-icon {
			right: .857em;
			margin-right: -.5em;
		}*/
		}

		/*.ui-submenu-header {
			border: 0 none;
			margin: 0;
			font-size: $fontSize;
			padding: $menuitemPadding;
			font-weight: 400;
		}*/
	}

    .ui-menubar {
        padding: 0;

        .ui-menubar-root-list {
            &>.ui-menuitem>.ui-menuitem-link {
                padding: $menuitemPadding;
            }
        }

        .ui-submenu-list {
            .ui-menuitem-link {
                .ui-submenu-icon {
                    right: .857em;
                    margin-right: -.5em;
                }
            }
        }

        .ui-menuitem {
            .ui-menuitem-link {
                .ui-menuitem-icon {
                    vertical-align: middle;
                }

                .ui-submenu-icon {
                    vertical-align: middle;
                }
            }
            &.ui-menuitem-active {
                > .ui-menuitem-link {
                    @include hover-element;

                    .ui-menuitem-icon {
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .ui-contextmenu {
        padding: .5em 0;

        .ui-menuitem {

            .ui-menuitem-link {
                .ui-menuitem-icon {
                    vertical-align: middle;
                }
            }
            &.ui-menuitem-active {
                > .ui-menuitem-link {
                    @include hover-element;
                }
            }
        }
    }

    .ui-slidemenu {
        padding: .5em 0;

        .ui-menuitem {
            margin: 0;

            .ui-menuitem-link {
                .ui-menuitem-icon {
                    vertical-align: middle;
                }
            }

            &.ui-menuitem-active {
                > .ui-menuitem-link {
                    @include hover-element;
                }
            }
        }

        .ui-slidemenu-backward {
            margin-bottom: .4em;
            padding: $headerPadding;
            width: 100%;
            background-color: $primaryColor;
            box-sizing: border-box;
            @include border-radius(0);
            border: 0 none;

            &:hover {
                background-color: darken($primaryColor, 10%);
            }
        }
    }

    .ui-tieredmenu {
        padding: .5em 0;

        .ui-menuitem {

            .ui-menuitem-link {
                .ui-menuitem-icon {
                    vertical-align: middle;
                }
            }
            &.ui-menuitem-active {
                > .ui-menuitem-link {
                    @include hover-element;
                }
            }
        }
    }

    .ui-megamenu {
        padding: 0;

        .ui-megamenu-panel {
            padding: .5em 0;
        }

        .ui-megamenu-submenu-header {
            border-radius: 0;
            padding: .25em;
        }

        .ui-submenu-list {
            padding: 0;
            border: 0 none;

            .ui-menuitem {
                margin: 0;
            }
        }

        .ui-menuitem {
            margin: 0;

            .ui-menuitem-link {
                .ui-menuitem-icon {
                    vertical-align: middle;
                }

                .ui-submenu-icon {
                    vertical-align: middle;
                }
            }

            &.ui-menuitem-active {
                > .ui-menuitem-link {
                    @include hover-element;
                }
            }
        }
    }
    .ui-megamenu-vertical {
        .ui-megamenu-root-list {
            .ui-menuitem {
                .ui-menuitem-link {
                    .ui-submenu-icon {
                        right: .857em;
                        margin-right: -.5em;
                    }
                }

            }
        }
    }

    .ui-tabmenu {
        padding: 0;

        .ui-tabmenu-nav {
            padding: 0;
            background-color: #ffffff;
            border: 0 none;
            @include border-radius(0);

            > .ui-tabmenuitem {
                top: auto;
                margin: 0 4px 0 0;
                padding: 0;
                border-style: solid;
                border-width: 0 0 2px 0;
                @include transition(border-color $transitionDuration);

                > a {
                    padding: $headerPadding;

                    &:focus {
                        outline: 0 none;
                    }

                    .ui-menuitem-icon, .ui-menuitem-text {
                        float: none;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .ui-menuitem-icon {
                        margin-right: .25em;
                    }
                }

                &.ui-state-default {
                    a {
                        color: $textSecondaryColor;

                        .pi {
                            color: $textSecondaryColor;
                        }
                    }
                }

                &:not(.ui-state-active):hover {
                    background-color: #ffffff;
                }

                &.ui-state-active {
                    background-color: #ffffff;
                    border-color: $accentColor;
                    border-style: solid;

                    a {
                        color: $primaryColor;

                        .pi {
                            color: $primaryColor;
                        }
                    }
                }
            }
        }
    }

    .ui-panelmenu {

        span {
            vertical-align: middle;
        }
        .ui-panelmenu-header {
            background-color: $accordionHeaderBgColor;
            margin-top: .25em;

            a {
                color: $accordionHeaderTextColor;
                font-size: $fontSize;
                font-weight: $headerFontWeight;
            }

            .ui-panelmenu-icon,
            .ui-menuitem-icon {
                font-size: $iconFontSize - 2;
            }

            &.ui-state-active {
                background-color: $primaryColor;
                color: $primaryTextColor;
                @include border-radius-bottom(0);

                a, .pi {
                    color: $primaryTextColor;
                }
            }
        }

        .ui-panelmenu-panel {
            margin-bottom: 1px;
        }

        .ui-panelmenu-content {
            padding: 0;

            .ui-menuitem {
                margin: 0;

                .ui-menuitem-link {
                    border: 0 none;
                    padding: $menuitemPadding;
                    width: 100%;
                    min-height: 30px;
                    box-sizing: border-box;
                    color: $textColor;
                    @include border-radius(0);
                    position: relative;

                    &:hover {
                        @include hover-element();
                    }

                    .ui-panelmenu-icon,
                    .ui-menuitem-icon {
                        font-size: $iconFontSize - 2;
                    }

                    .ui-menuitem-text {
                        display: inline-block;
                        vertical-align: middle;
                        float: none;
                    }

                    .pi {
                        position: static;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }

        .ui-menu-parent .ui-menu-list {
            margin-left: 28px;
        }
    }
}

@media (max-width: 640px) {
    body {
        .ui-steps {
            .ui-steps-item {
                .ui-menuitem-link {
                    .ui-steps-title {
                        display: none;
                    }
                }
            }
        }
    }
}
