$primaryColor: #757575;
$primaryDarkColor: #424242;
$primaryLightColor: #BDBDBD;
$primaryLightestColor: #FAFAFA;
$primaryTextColor: #ffffff;
$accentColor: #FFA910;
$accentDarkColor: darken($accentColor, 20%);
$accentLightColor: lighten($accentColor, 20%);
$accentTextColor: #ffffff;

@import '../sass/theme/_theme';


.ui-datatable .ui-datatable-data > tr > td {
	word-break: break-word;
}

.ui-datatable .ui-datatable-data > tr.ui-widget-content:not(.ui-state-highlight):not(.ui-datatable-emptymessage-row):hover {
	background-color: #e8e8e8 !important;
}

.ui-table {
	.ui-table-caption {
		.fa {
			color: $primaryTextColor !important;
		}

		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}

	.ui-table-tbody {
		> tr {
			&:not(.ui-state-highlight) {
				&:hover {
					background-color: #e8e8e8 !important;
				}
			}

			> td {
				word-break: break-word;
			}
		}
	}
}

.mobile-logo {
	display: block;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	min-width: 141px !important;
	max-width: 150px !important;
	margin: 0 auto;
}

.layout-wrapper {
	.layout-sidebar {
		.ui-scrollpanel {
			.layout-menu {
				padding-bottom: 0 !important;
			}
		}
	}
}

body {
	iframe {
		border: 0;
	}

	.p-button {
		// Seperated from &.helio-orange-btn since not all btn are orange
		@include border-radius($borderRadius);
	}

	// TODO Remove legacy .ui-button (replaced by .p-button) once verified it's not used statically
	.ui-button, .p-button, .ui-datepicker-buttonpane > button {
		&.helio-orange-btn {
			background-color: $accentColor;

			&:enabled:not(:focus):hover {
				background-color: darken($accentColor, 5%);
			}

			&:focus {
				outline: 0 none;
				background-color: lighten($accentColor, 10%);
			}
		}

		&.transparent-btn {
			background-color: transparent;
			color: $primaryDarkColor;

			.fa {
				color: $primaryDarkColor;
			}

			&.light-text {
				color: #fff;
			}

			&:enabled:not(:focus):hover {
				background-color: darken(#fff, 5%);
			}

			&:focus {
				outline: 0 none;
				background-color: lighten(#fff, 10%);
			}
		}
	}

	.ui-datatable-header {
		.fa {
			color: $primaryTextColor !important;
		}
	}

	// TODO Remove legacy .ui-button (replaced by .p-button) once verified it's not used statically
	.ui-buttonset {
		.ui-button, .p-button {
			border: 1px solid $accentColor;

			&:not(.ui-state-active) {
				background-color: #fff;
				color: $primaryDarkColor;

				&:not(.ui-state-disabled):hover {
					background-color: darken(#fff, 5%);
				}
			}

			.ui-button-text {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
			}
		}
	}

	.ui-paginator {
		background-color: $primaryColor;

		> a {
			box-sizing: border-box;
		}
	}
}
