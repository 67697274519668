body {
    .ui-draggable-dragging.ui-state-default {
        padding: $headerPadding !important;
        background-color: $primaryColor;
    }

    .jqplot-target {
        font-family: "Roboto","Helvetica Neue",sans-serif;
    }

    .ui-progressbar {
        height: 1.5em;
        padding: 0;
        overflow: hidden;
        border-color: $contentBorderColor;

        .ui-progressbar-value {
            height: 1.5em;
            border: 1px solid $primaryColor;
        }

        .ui-progressbar-label {
            color: #ffffff;
            display: none !important;
        }
    }

    .ui-progressbar-indeterminate {
        .ui-progressbar-value {
            background-color: $primaryLightColor;
            &::before {
                background-color: $primaryColor;
            }
        }
    }

    .ui-galleria {
        padding: 0;

        .ui-galleria-nav-prev {
            left: 0;
            margin-bottom: -.4em;
        }

        .ui-galleria-nav-next {
            right: 0;
            margin-bottom: -.4em;
        }
    }

    .ui-inplace {
        .ui-inplace-display {
            @include transition(background-color .3s);
            @include border-radius(3px);

            &:hover {
                background-color: $hoverBgColor;
            }
        }
    }

    .ui-terminal {
        .ui-terminal-input {
            font-size: $fontSize;
        }
    }
}
