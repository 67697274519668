/* You can add global styles to this file, and also import other style files */
@import './app/app-variables.style';
@import './assets/theme/theme-helio-engine';
@import './assets/layout/css/layout-helio-engine';
@import 'src/assets/sass/mixins';
@import 'src/assets/sass/variables/layout';

*, *:before, *:after {
	box-sizing: border-box;
}

html, body {
	width: 100%;
	height: 100%;
	overflow: auto;
	margin: 0;
	padding: 0;
	font-family: $font !important;

	.p-skeleton {
		background-color: #dee2e6;
		border-radius: 6px;

		&:after {
			content: "";
			animation: p-skeleton-animation 1.2s infinite;
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			transform: translate(-100%);
			z-index: 1;

			background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
		}

		&.p-skeleton-circle {
			border-radius: 50%;
		}
	}

	.unselectable {
		@include textUnselectable();
	}

	&.elevateDropDown div.p-menu-overlay {
		// Used to temporarily elevate dropdown cog options in dialog with such capability
		// The class body.elevateDropDown is initiated from the code
		z-index: 9999999 !important;
	}

	.p-dropdown-trigger, .p-multiselect-trigger {
		padding-right: 12px;
	}

	p-dropdown .p-dropdown timesicon .p-dropdown-clear-icon {
		right: 2.5rem;
	}

	.p-dropdown-item.p-disabled {
		opacity: unset !important;
		background-color: #f4f1f1;
	}

	.p-dropdown-panel {
		// Targets searchableList from FilterComponents

		.p-dropdown-filter-container {
			display: grid;
			grid-template-columns: 1fr max-content;
			padding: 0 10px;
			background-color: white;

			input.p-dropdown-filter {
				grid-column: 1/3;
				grid-row: 1;
			}
		}

		searchicon {
			width: min-content;
			display: flex;
			align-items: center;
			grid-column: 2;
			grid-row: 1;
			padding: 0 8px;
		}

		// @layer primeng override
		.p-dropdown-filter-icon {
			position: unset;
			top: unset;
			margin-top: unset;
		}

		.p-dropdown-empty-message {
			background-color: white;
			padding: 8px;
		}
	}

	.he-specific-table-actions {
		display: flex;
		justify-content: right;
		align-items: center;
		// margin-right: 16px;
		column-gap: 12px;
		// margin-bottom: 8px;

		button {
			min-width: 56px;
		}
	}

	@keyframes p-skeleton-animation {
		0% {
			transform: translate(-100%);
		}
		100% {
			transform: translate(100%);
		}
	}

	// @note: not nested in p-table since this is also used by PlayerDetailsTab, a non table!!
	.p-datatable-loading-overlay, .p-dataview-loading-overlay {
		// Adds overlay on table during load, so that multiple request - during loading - is not possible
		background-color: rgba(255, 255, 255, 0.6);
		z-index: 1000 !important;
	}

	spinnericon {
		width: 100px;
		height: 100px;

		.p-datatable-loading-icon, .p-dataview-loading-icon {
			width: 100%;
			height: 100%;
			color: #ffa910;
		}
	}

	.p-component {

		// Contents with p-ripple class name, such p-paginator have their icons un-render-able, exclude these
		&:not(.p-ripple) {
			font-size: 1em;
			// Added here as relying on button causes "user agent stylesheet" specificity to override it
			font-family: Roboto, Helvetica Neue, sans-serif !important;
		}
	}

	.p-overlay {
		z-index: $topBarElevation + 10 !important;
	}

	p-tabview .p-tabview .p-tabview-panels p-tabpanel .p-tabview-panel[aria-hidden="false"] {
		// Allows vertical scroll in AdvancedSearch + Table combination in Tabbed pages such as
		// IndividualPlayer and IndividualAgent
		height: 100%;
		display: block;
		// grid-template-rows: ;
		// overflow-y: auto !important;
	}


	p-tabview {
		.p-tabview-nav-container button.p-tabview-nav-btn {
			// Add background to tab scroller UI
			background-color: white; // wheat;
		}

		ul.p-tabview-nav li {
			// Style works in tandem with with p-tabView to allow tabs titles to show their full text
			min-width: fit-content;

			a.p-tabview-nav-link {
				min-width: 48px;
			}

			&.p-disabled {
				// Targets Individual Agents and Players pages
				visibility: collapse;

				a.p-tabview-nav-link {
					min-width: 0;
					padding: 0;

					span.p-tabview-title {
						// so tab remains but does not occupy any space,
						// this is important for the indexing used to identify clicked tab
						width: 0;
					}
				}
			}
		}
	}

	table {
		.p-datatable-thead, .p-datatable-tbody {
			tr {
				th, td {

					> &:not(.heDontTargetMe) {
						max-width: 200px !important;
					}
				}
			}
		}
	}

	// @include tabbed-table-overhead-style();
}

.user-select-none {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.he-disabled {
	pointer-events: none;
	opacity: 0.4;
}

.dialog-default, p-dialog {
	position: absolute;
}

#page-wrapper {
	height: 100%;
}

#page-content-wrapper {
	width: auto;
	margin-bottom: 25px;
	margin-left: $sidebar-width;

	&.full-width {
		margin-left: 0;
		width: 100%;
	}
}

#sidebar-wrapper {
	width: $sidebar-width;
	height: 100%;
	position: fixed;
	float: left;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.main-wrapper.col-xs-12 {
	position: static;
}

.row {
	margin: 0;
	margin-bottom: 20px;
}

.datatable-header {
	padding: 5px 10px 1px;
}

.ui-datatable .ui-datatable-thead > tr > th,
.ui-table .ui-table-thead > tr > th {
	border-width: 1px 1px 2px 1px !important;
	border-bottom-color: #6C6C6C !important;
}

button {
	cursor: pointer;
}

.bulk-action-icon {
	i {
		cursor: pointer;
		display: block;
	}
}

.bulk-button {
	margin-right: 10px;
}

.ui-growl {
	z-index: 999999;
}

.ui-chkbox {
	margin: 0 5px;
}

textarea {
	resize: none;
}

/* ====== TEMPORARY FIX ====== */
.ui-menu {
	width: auto !important;
}

/* ====== TEMPORARY FIX ====== */

/* ============== UTIL CLASSES ============== */

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.pull-right {
	float: right !important;
}

.pull-left {
	float: left !important;
}

.hide {
	display: none !important;
}

.show {
	display: block !important;
}

.invisible {
	visibility: hidden;
}

/* ============== PrimeNG ProgressSpinner CLASSES ============== */

.p-progress-spinner-circle {
	stroke: $accentColor !important;
}


// TODO: Move to _data.scss


p-table {
	.p-datatable {
		.p-datatable-header {
			// background-color: #757575;
			color: #000;
			// border: 1px solid #757575;
			height: 100%;

			// padding: 6px 1em;
			font-weight: 400;

			position: sticky;
			top: 0;
			left: 0;

			z-index: 2;

			#overhead-opts-wrapper {
				background-color: #f0f0f0;
				color: #000;
				// border: 1px solid #757575;
				padding: 1em;
				font-weight: 400;

				// margin-top: 12px;
			}

			@include tabbed-table-overhead-style();
		}

		.p-datatable-thead > tr > th {
			padding: 1.2em; // 0.857em 0.857em
			border: 0;
			text-align: left;
			font-weight: 500;
			/*border-top: 1px solid #d8d8d8;
			background-color: #fff;*/

			height: 50.8px; // Make header 50px to ensure when bulk opt shown, table height does not change

			/*&:first-child {
				border-left: 1px solid $contentBorderColor;
			}

			&:last-child {
				border-right: 1px solid $contentBorderColor;
			}*/

			/*TODO: What effect does this have on column icon? doesn't appear to set the required icon type*/
			.p-sortable-column-icon {
				vertical-align: middle;
				margin: -4px 0 0 0;
				color: $textSecondaryColor;

				&.#{$iconPrefix}-carat-2-n-s {
					margin-left: 4px;
				}
			}

			p-sorticon {
				display: inline-block;
			}

			.p-column-resizer {
				@include material-icon("\e86f");
				font-size: $fontSize + 2;
				color: $textSecondaryColor;
				margin-bottom: 40px;
			}
		}

		.p-datatable-wrapper {
			z-index: 1;
		}

		/*.p-sortable-column {
			color: $textColor;

			.p-sortable-column-icon {
				color: $textSecondaryColor;
			}

			&:not(.ui-state-highlight):hover {
				@include hover-element();

				.p-sortable-column-icon {
					@include hover-element();
				}
			}

			&.ui-state-highlight {
				background-color: $accentColor;
				color: $accentTextColor;
				border-top-color: $accentColor;

				.p-sortable-column-icon {
					color: $accentTextColor;
				}
			}

			.p-sortable-column-icon {
				vertical-align: middle;
			}
		}*/

		.p-datatable-tbody {
			> tr {
				background: $contentBgColor;
				border-bottom: 1px solid $contentBorderColor;

				> td {
					background: inherit;
					padding: $dataTableCellPadding;
					border: 0 none;
				}

				/*&.ui-state-highlight {
					background-color: $accentColor;
					color: $accentTextColor;
				}

				&.ui-contextmenu-selected {
					background-color: $accentColor;
					color: $accentTextColor;
				}

				.ui-editing-cell {
					input {
						color: $accentTextColor;
					}
				}*/
			}

			/*> tr:nth-child(even) {
				background-color: $dataTableRowBgColorEven;

				&.ui-state-highlight {
					background-color: $accentColor;
					color: $accentTextColor;
				}

				&.ui-contextmenu-selected {
					background-color: $accentColor;
					color: $accentTextColor;
				}
			}*/
		}
	}

	.p-datatable-resizable {
		.p-datatable-thead > tr > th,
		.p-datatable-tfoot > tr > td,
		.p-datatable-tbody > tr > td {
			// Unset to allow table cells to wrap
			overflow: unset !important;
			white-space: unset !important;
		}
	}
}

//region Various Checkbox impl

p-checkbox {
	// align-items: end !important; // Overrides factory "display: inline-flex"

	+ p-checkbox {
		// add gap to the left if another checkbox precedes the target
		margin-left: 18px;
	}

	.p-checkbox-label {
		line-height: unset !important; // Overrides factory
		padding-left: 8px;
	}
}

.p-checkbox .p-checkbox-box {
	// =================================================================================================
	// Style affects p-table's p-tableheadercheckbox and p-tableCheckbox, p-multiselect ...
	// !!!!!!!!!!!!!!!! Thus, leave the root as ".p-checkbox .p-checkbox-box" !!!!!!!!!!!!!!!!!!!!!!
	// =================================================================================================

	border: 2px solid #757575;
	background: #ffffff;
	width: 20px;
	height: 20px;
	color: #495057;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

	&.p-highlight {
		border-color: #757575;
		background: #757575;
		color: #ffffff;
	}
}

//endregion


//region p-multiselect

p-multiselect {
	// Override factory style
	position: unset;
	top: 0 !important;

	.p-multiselect {
		background: #ffffff;
		border-bottom: 1px solid #757575;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}
}

/*.p-multiselect:not(.p-disabled):hover {
	border-color: #FFC107;
}*/

.p-multiselect:not(.p-disabled).p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #ffe69c;
	border-color: #FFC107;
}

.p-multiselect .p-multiselect-label {
	@include list-input-padding();
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-multiselect .p-multiselect-label.p-placeholder, .p-dropdown .p-dropdown-label.p-placeholder {
	color: #6c757d;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
	padding: 0.25rem 0.5rem;
	margin-right: 0.5rem;
	background: #FFF3E0;
	color: #495057;
	border-radius: 3px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
	margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
	background: transparent;
	color: #212121;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
	padding: 0.25rem 0.5rem;
}

.p-multiselect-panel {
	background: #ffffff;
	color: #495057;
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	top: 24px !important; // provides gap between the panel and dropdown
}

.p-multiselect-panel .p-multiselect-header {
	padding: 8px;
	border-bottom: 0 none;
	color: white;
	background: #757575;
	margin: 0;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
	.p-inputtext {
		padding-right: 1.5rem;
	}

	input.p-multiselect-filter {
		border-bottom: 1px solid white !important;
		color: white;
	}
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
	right: .25rem;
	color: white;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
	margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
	margin-left: 0.5rem;
	width: 2rem;
	height: 2rem;
	color: white;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
	color: black;
	border-color: transparent;
	background: rgba(255, 255, 255, 0.2);
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #ffe69c;
}

.p-multiselect-panel .p-multiselect-items {
	padding: 0.5rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
	margin: 0;
	padding: 0.5rem 1rem;
	border: 0 none;
	color: #495057;
	background: transparent;
	transition: box-shadow 0.2s;
	border-radius: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
	color: #495057;
	background: #FFF3E0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
	color: #495057;
	background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem #ffe69c;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
	margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
	margin: 0;
	padding: 0.75rem 1rem;
	color: #495057;
	background: #ffffff;
	font-weight: 600;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
	padding: 0.5rem 1rem;
	color: #495057;
	background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
	border-color: #f44336;
}

.p-input-filled .p-multiselect {
	background: #f8f9fa;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
	background-color: #f8f9fa;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
	background-color: #ffffff;
}

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
	padding-right: 1.5rem;
}

.p-multiselect-label-container {
	display: flex;
	align-items: center;
}

p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
	color: #6c757d;
	right: 2.357rem;
}

//endregion


//region p-selectbutton

.p-selectbutton .p-button {
	background: #ffffff;
	border: 1px solid #ced4da;
	color: #495057;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
	color: #6c757d;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
	background: #e9ecef;
	border-color: #ced4da;
	color: #495057;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
	color: #6c757d;
}

.p-selectbutton .p-button.p-highlight {
	background: $accentColor;
	border-color: $accentColor;
	color: white;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
	color: #212529;
}

.p-selectbutton .p-button.p-highlight:hover {
	background: #ecb100;
	border-color: #ecb100;
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
	color: #212529;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
	border-color: #f44336;
}

//endregion


//region p-toast

.p-toast {
	opacity: 0.9;
	@include topBarClearancePlus;

	.p-toast-message {
		margin: 0 0 1rem 0;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		border-radius: 3px;

		.p-toast-message-content {
			padding: 1rem;
			border-width: 0 0 0 6px;

			.p-toast-message-text {
				margin: 0 0 0 1rem;
			}

			.p-toast-message-icon {
				font-size: 2rem;

				&.pi-info-circle {
					@include material-icon("info");
					font-size: 1.75em;
				}

				&.pi-exclamation-triangle {
					@include material-icon("warning");
					font-size: 1.75em;
				}

				&.pi-check {
					@include material-icon("check_circle");
					font-size: 1.75em;
				}

				&.pi-times-circle {
					@include material-icon("error_outline");
					font-size: 1.75em;
				}
			}

			.p-toast-summary {
				color: white;
				font-weight: 700;
			}

			.p-toast-detail {
				color: white;
				margin: 0.5rem 0 0 0;
			}
		}

		.p-toast-icon-close {
			min-width: 2rem;
			min-height: 2rem;
			border-radius: 50%;
			background: transparent;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		}

		.p-toast-icon-close:hover {
			background: rgba(255, 255, 255, 0.3);
		}
	}

	// region p-toast background and colours
	.p-toast-message .p-toast-message-icon,
	.p-toast-message .p-toast-icon-close {
		color: white;
	}

	.p-toast-message.p-toast-message-info {
		background: #0891cf;
		color: #044868;
	}

	.p-toast-message.p-toast-message-success {
		background: #439446;
		color: #224a23;
	}

	.p-toast-message.p-toast-message-warn {
		background: #d9a300;
		color: #6d5100;
	}

	.p-toast-message.p-toast-message-error {
		background: #e60017;
		color: #73000c;
	}

	//endregion
}

//endregion


//region p-datatable striped
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
	background: #f7f7f7;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-highlight {
	background: #FFF3E0;
	color: #495057;
}

//endregion


//region targets p-confirmdialog p-dialog

// TODO: Move to _overlay.scss
p-dialog {
	position: absolute; // Ensures that when nested in a component it does not occupy space in grid/flex display
}

p-confirmdialog {
	@include topBarClearancePlus;
	position: relative;

	.p-dialog {
		min-width: 400px;
		max-width: 550px;

		.p-dialog-content {
			.p-confirm-dialog-icon + .p-confirm-dialog-message {
				padding-left: 8px;
			}
		}

		.p-dialog-footer {
			display: flex;
			column-gap: 14px;
			justify-content: flex-end;
			padding: 14px;

			button {
				&.p-confirm-dialog-reject, &.p-confirm-dialog-accept {
					display: flex;
					column-gap: 10px;
				}
			}
		}
	}

	.p-dialog-mask {
		background: rgba(0, 0, 0, 0.4);
		z-index: $sideMenuElevation + 1 !important; // To overlap appbar and slide-menu
	}
}

//endregion

//region Description
div.p-dataview-layout-options {
	&.p-selectbutton .p-button.p-highlight:hover {
		// override
		background: #ecb100;
		border-color: #ecb100;
	}

	&.p-buttonset {
		display: flex;
		column-gap: 8px;

		.p-button {
			&:focus {
				// override
				background-color: $accentColor;
			}

			&:hover {

			}

			&:first-of-type, &:last-of-type, & {
				// override
				border-radius: 50%;
			}

			&:not(.p-highlight) {
				.pi {
					// sets icon colour
					color: #495057;
				}
			}
		}
	}
}

//endregion


//region p-dataview: As used

.p-dataview .p-paginator-top {
	border-width: 0 0 1px 0;
	border-radius: 0;
}

.p-dataview .p-paginator-bottom {
	border-width: 0 0 1px 0;
	border-radius: 0;
}

.p-dataview .p-dataview-header {
	// background: #f8f9fa;
	color: #495057;
	// border-top: 1px solid #e9ecef;
	// border-bottom: 1px solid #e9ecef;
	padding: 1rem 1rem;
	font-weight: 600;
}

.p-dataview .p-dataview-content {
	background: #ffffff;
	color: #495057;
	border: 0 none;
	padding: 0;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
	border: solid #e9ecef;
	border-width: 0 0 1px 0;
}

.p-dataview .p-dataview-footer {
	background: #f8f9fa;
	color: #495057;
	padding: 1rem 1rem;
	font-weight: 600;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.p-dataview .p-dataview-loading-icon {
	font-size: 2rem;
}

.p-dataview .p-dataview-emptymessage {
	padding: 1rem;
}

//endregion

//region Targets "Data Processing" Page
.p-dataview {

	// .p-dataview-header {}

	&.p-dataview-grid, &.p-dataview-list {
		.p-dataview-content {
			margin-bottom: 16px; // card bottom margin is ignored in grid mode, add to content

			.p-grid {
				display: grid;
				column-gap: 15px; // 15 used to allow ease division by 3
				row-gap: 16px;
			}
		}

		// p-paginator.p-paginator-bottom {}
	}

	&.p-dataview-grid {
		.p-grid {
			grid-template-columns: repeat(3, calc(33% - 5px));
		}
	}
}

@media screen and (max-width: $drawsListItem_maxWidth) {
	// Enforce p-dataview-list characteristics
	.p-dataview {
		.p-dataview-header {
			.p-dataview-layout-options {
				display: none; // Hide since List view is being enforced through @media width
			}
		}

		.p-dataview-content .p-grid {
			column-gap: 16px;
			grid-template-columns: 100% !important;
		}
	}
}

@media screen and (min-width: 1201px) and (max-width: 1536px) {
	.p-dataview.p-dataview-grid .p-dataview-content .p-grid {
		column-gap: 16px;
		grid-template-columns: repeat(2, calc(50% - 8px)) !important;
	}
}

//endregion


.alert {
	// Target all elements implementing the class
	@include alert-style();
}

//region p-calendar
p-calendar {
	.p-calendar {
		width: 100%;
	}
}

//endregion

//region p-inputnumber

p-inputnumber {
	width: 100%;

	.p-inputnumber {
		width: 100%;

		//region ======== This entry regions places the dials within the TextInput to adhere with app-wide style =======
		&.p-inputnumber-buttons-stacked {

			display: grid !important; // overrides default <style>
			grid-template-columns: auto min-content;
			row-gap: 2px;

			input.p-inputtext {
				grid-row: 1;
				grid-column: 1/3;
			}

			span.p-inputnumber-button-group {
				grid-row: 1;
				grid-column: 2/3;
				padding-right: 4px;
				padding-bottom: 4px;

				button:nth-child(2) {
					margin-top: 2px;
					padding-bottom: 1px; // To center the bottom icon, inline with the top
				}

				button {
					.pi {
						&.pi-angle-up, &.pi-angle-down {
							font-size: 16px !important;
							// content: 'keyboard_arrow_up';
							// content: 'keyboard_arrow_down';
						}
					}
				}

				.p-button.p-button-icon-only {
					border-radius: unset !important;
					width: 16px !important;
					height: 14px !important;
				}

				.p-button.p-inputnumber-button {
					flex: unset !important; // overrides default <style>
				}
			}
		}

		//endregion ==================================================================================
	}
}

//endregion

//region p-calender


.p-datepicker {
	z-index: $topBarElevation + 10 !important; // So calender is above appbar if overlapping it
}

//endregion

//region p-menu (as used on table row items)

.p-menu {
	user-select: none;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10+ */
}

//endregion

.page-opts-style {
	display: flex;
	flex-direction: row-reverse;
	// margin-bottom: 20px;
	// margin-left: 12px;
	// margin-right: 1em; // To match with that current set on table
}

.page-opts-style-2 {
	display: flex;
	flex-wrap: wrap;
	justify-content: end;
	align-items: center;
	row-gap: 8px;
	-moz-column-gap: 12px;
	column-gap: 12px;

	// margin-bottom: 12px;
	/*margin-left: 12px;
	margin-right: 1em;*/
	// To match with that current set on table
}

//region Abstracted Table RWD
@mixin gridDisplayAndHeight100Per {
	display: grid;
	height: 100%;
}

p-table .p-datatable-wrapper p-scroller .p-scroller.p-scroller-inline {
	// height: 100% !important;
}

.layout-wrapper .layout-main .layout-content {
	height: 100% !important;

	.card {
		/*display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;*/
		// padding-bottom: 32px; // For granular selection try .card.card-w-title
		width: 100%;
		// min-height: 100%;
		// height: calc(100% + 64px + 32px - 16px); // -32px accounts for top and btm padding
		height: 100%; // for proper display of draw-processing pages

		display: grid;
		grid-template-rows: min-content 1fr; // the first cell, for min-content, contains the 0-height <router-outlet>

		he-data-table-v3 p-table .p-datatable {

			.p-datatable-thead {
				z-index: 2; // To ensure Settings icon gear is behind the header during scrolling
				background-color: #f0f0f0;

				> tr > th {
					border-top: 1px solid #d8d8d8;
					border-bottom: 1px solid #d8d8d8;
				}
			}

			/*.p-datatable-tbody > tr {
				> td:first-child {
					border-left: 1px solid #d8d8d8;
				}

				> td:last-child {
					border-right: 1px solid #d8d8d8;
				}
			}*/

			.p-datatable-wrapper {
				// border: 1px solid #d8d8d8;

				// TODO: Add background for when table content is not completely filled?
				// background-color: #d8d8d8;
			}
		}

		//region Overflow control for scroll

		// special case for nested tab components
		he-individual-player, he-agent-reconciliation, he-individual-agent {
			// overflow: visible;

			he-data-table-v3 {
				//height: 100%;
				//width: 100%;
				//overflow-y: auto !important;
				//display: block;
			}
		}

		// Use for non-nested tables
		he-players, he-player-audit, > he-kyc, > he-kyc-management, > he-winning-participations, > he-agents, > he-reconciliation,
		> he-top-up-history, > he-user-rights, > he-billing-invoices,
		he-wallet-transaction[is-global=true], he-gateway-transaction[is-tabbed=false], he-top-up-cards,
		he-payment-providers-fallback, he-payment-providers-distribution, he-withdrawal-requests,
		he-reconciliation-history, he-sales-statistics, he-distributed-batches {
			// overflow-y: auto;

			he-data-table-v3 {
				// overflow: auto !important;
			}
		}

		//endregion

		//region Vertical placement

		// Tables with 2 rows with the 2nd being the table
		he-players, he-player-audit, > he-winning-participations, > he-top-up-history, > he-upcoming-draws, he-previous-draws,
		he-wallet-transaction[is-global=true], he-gateway-transaction[is-tabbed=false],
		he-top-up-cards, he-payment-providers-fallback, he-reconciliation-history,
		 he-sales-statistics, he-individual-player he-reward-points-tab, he-distributed-batches {
			display: grid;
			grid-template-rows: min-content 1fr; // 100% and not auto or 1fr, since we want the table container to occupy 100% of parent
			height: 100%;
		}

		// Table-included components with 3 rows with the 3rd being the table
		he-individual-player, he-individual-agent, he-agent-reconciliation, > he-agents, > he-reconciliation,
		> he-user-rights, > he-billing-invoices, he-payment-providers-distribution, he-user-rights-logs, he-top-up-card-batch {
			display: grid;
			grid-template-rows: min-content min-content 1fr;
			height: 100%;
		}

		// Table-included components with 4 (element) rows with the 4th being the table
		// NOTE: THis excludes p-dialogs where position: absolute is applied globally so that when dialog is
		// shown it does not occupy any space in the grid/flex system.
		> he-kyc, > he-kyc-management, > he-withdrawal-requests {
			display: grid;
			grid-template-rows: min-content min-content min-content 1fr;
			height: 100%;
		}

		//endregion


		he-individual-player, he-individual-agent,
		he-agent-reconciliation, he-players, he-player-audit, > he-kyc, > he-kyc-management, > he-winning-participations,
		> he-agents, > he-reconciliation, > he-top-up-history, > he-user-rights, > he-billing-invoices,
		he-wallet-transaction[is-global=true], he-gateway-transaction[is-tabbed=false],
		he-top-up-cards, he-payment-providers-fallback, he-payment-providers-distribution,
		he-withdrawal-requests, he-reconciliation-history, he-sales-statistics, he-distributed-batches,
		he-draws-for-specific-game, he-user-rights-logs, he-top-up-card-batch {

			he-data-table-v3 {

				// Apply 100% for grid-cell containing table, so that when AdvanceSearch is shown it does not reduce
				// the original height of the table, which should be 100% of the parent
				display: grid;
				grid-template-rows: min-content max-content;
				height: 100%;

				p-table {
					// height: 100%;
					// display: block;

					.p-datatable {
						// min-height: 100%; // so-named id #pr_id_3
						height: 100%;
						display: grid;
						grid-template-rows: min-content 1fr min-content;
						// height: max-content;

						.p-datatable-thead {
							position: sticky;
							top: 0;
						}

						/*.p-datatable-tbody {
							overflow: auto;
							// height: 200px;
							height: 100%;
						}*/

						.p-datatable-wrapper {
							// overflow: unset;
							// height: 100%;

							table {

								&.p-datatable-table {
									border-spacing: 0;
								}
							}
						}
					}
				}
			}
		}

		he-individual-player he-data-table-v3 p-table .p-datatable .p-datatable-wrapper {
			// Intended to remove the appearance of the y-scrollbar in tabbed table, even though it is not used
			overflow-x: auto;
			overflow-y: hidden;
		}
	}
}

p-table {
	table {
		.p-datatable-thead, .p-datatable-tbody {
			tr {
				td {
					// Add ellipsis to cell content that over their max-width; added here and not in
					// column-data.component.html as this affects the current implementation of NotApplicableDirective
					overflow: hidden;
					text-overflow: ellipsis
				}
			}
		}
	}
}

[avail-screen='desktop'] {
	p-table .p-datatable .p-datatable-tbody > tr > td {
		// Setting property establishes auto distribution of available width to columns
		max-width: 120px;
	}
}

//endregion


.verticalPlacement-salesStats {
	grid-template-rows: min-content auto !important;
}

p-checkbox .p-checkbox-label::after {
	content: "" !important; // to remove ":" in-front of checkbox labels
}

.p-float-label label::after {
	content: "" !important; // to remove ":" within hint label of FloatInputs
}

he-base-dialog.basic-dialog-table {
	.p-dialog-content {
		padding: 0 1em !important; // Temp fix to close the gap above table header
		background: white;

		he-data-table-v3 {
			row-gap: unset;

			.advanced-search-wrapper {
				display: none; // Important so that header (sticky) position does not shift when applied
			}
		}
	}
}
