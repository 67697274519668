.ui-widget, .ui-widget * {
	box-sizing: border-box
}

.ui-helper-hidden {
	display: none !important
}

.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px
}

.ui-helper-hidden-accessible input, .ui-helper-hidden-accessible select {
	transform: scale(0)
}

.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	font-size: 100%;
	list-style: none
}

.ui-helper-clearfix::before, .ui-helper-clearfix::after {
	content: "";
	display: table
}

.ui-helper-clearfix::after {
	clear: both
}

.ui-helper-clearfix {
	zoom: 1
}

.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter: Alpha(Opacity=0)
}

.ui-state-disabled {
	cursor: default !important
}

.ui-state-disabled a {
	cursor: default !important
}

.ui-icon {
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat
}

.ui-widget-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.ui-resizable {
	position: relative
}

.ui-resizable-handle {
	position: absolute;
	font-size: .1px;
	display: block
}

.ui-resizable-disabled .ui-resizable-handle, .ui-resizable-autohide .ui-resizable-handle {
	display: none
}

.ui-resizable-n {
	cursor: n-resize;
	height: 7px;
	width: 100%;
	top: -5px;
	left: 0
}

.ui-resizable-s {
	cursor: s-resize;
	height: 7px;
	width: 100%;
	bottom: -5px;
	left: 0
}

.ui-resizable-e {
	cursor: e-resize;
	width: 7px;
	right: -5px;
	top: 0;
	height: 100%
}

.ui-resizable-w {
	cursor: w-resize;
	width: 7px;
	left: -5px;
	top: 0;
	height: 100%
}

.ui-resizable-se {
	cursor: se-resize;
	width: 12px;
	height: 12px;
	right: 1px;
	bottom: 1px
}

.ui-resizable-sw {
	cursor: sw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	bottom: -5px
}

.ui-resizable-nw {
	cursor: nw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	top: -5px
}

.ui-resizable-ne {
	cursor: ne-resize;
	width: 9px;
	height: 9px;
	right: -5px;
	top: -5px
}

.ui-shadow {
	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3)
}

.ui-unselectable-text {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none
}

.ui-scrollbar-measure {
	width: 100px;
	height: 100px;
	overflow: scroll;
	position: absolute;
	top: -9999px
}

.ui-overflow-hidden {
	overflow: hidden
}

::-webkit-input-placeholder {
	color: #898989
}

:-moz-placeholder {
	color: #898989;
	opacity: 1
}

::-moz-placeholder {
	color: #898989;
	opacity: 1
}

:-ms-input-placeholder {
	color: #898989
}

::-ms-input-placeholder {
	color: #898989
}

.ui-placeholder {
	color: #898989
}

input[type="button"], input[type="submit"], input[type="reset"], input[type="file"]::-webkit-file-upload-button, button {
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0
}

.ui-accordion {
	width: 100%
}

.ui-accordion .ui-accordion-header {
	cursor: pointer;
	position: relative;
	margin-top: 1px;
	zoom: 1
}

.ui-accordion .ui-accordion-header a {
	display: block;
	padding: .5em
}

.ui-accordion .ui-accordion-toggle-icon, .ui-accordion .ui-accordion-header-text {
	vertical-align: middle
}

.ui-accordion .ui-accordion-content {
	padding: 1em;
	border-top: 0;
	overflow: visible;
	zoom: 1
}

.ui-accordion .ui-accordion-header.ui-state-disabled, .ui-accordion .ui-accordion-header.ui-state-disabled a {
	cursor: default
}

.ui-accordion-content-wrapper-overflown {
	overflow: hidden
}

.ui-rtl .ui-accordion .ui-accordion-header a {
	padding: .5em 2em .5em .5em
}

.ui-rtl .ui-accordion .ui-accordion-toggle-icon {
	left: initial;
	right: .5em
}

.ui-rtl .ui-accordion .ui-accordion-toggle-icon.pi-caret-right:before {
	content: '\e904'
}

.ui-autocomplete {
	width: auto;
	zoom: 1;
	cursor: pointer;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	position: relative;
	display: inline-block
}

.ui-autocomplete .ui-autocomplete-dropdown {
	height: 100%;
	width: 2em;
	margin-right: 0;
	vertical-align: top
}

.ui-autocomplete .ui-autocomplete-input {
	padding-right: 1.5em
}

.ui-autocomplete-loader {
	position: absolute;
	right: .25em;
	top: 50%;
	margin-top: -.5em
}

.ui-autocomplete-query {
	font-weight: bold
}

.ui-autocomplete .ui-autocomplete-panel {
	min-width: 100%
}

.ui-autocomplete-panel {
	position: absolute;
	overflow: auto
}

.ui-autocomplete-panel .ui-autocomplete-list {
	padding: .4em;
	border: 0 none
}

.ui-autocomplete-panel .ui-autocomplete-list-item {
	border: 0 none;
	cursor: pointer;
	font-weight: normal;
	margin: 1px 0;
	padding: .186em .313em;
	text-align: left
}

.ui-autocomplete .ui-button-icon-only, .ui-autocomplete .ui-button-icon-only:enabled:hover, .ui-autocomplete .ui-button-icon-only:enabled:focus, .ui-autocomplete .ui-button-icon-only:enabled:active {
	border-left: 0 none
}

.ui-autocomplete-multiple-container {
	display: inline-block;
	vertical-align: middle
}

.ui-autocomplete-multiple-container.ui-inputtext {
	clear: left;
	cursor: text;
	list-style-type: none;
	margin: 0;
	overflow: hidden;
	padding: 0 1.5em 0 .25em
}

.ui-autocomplete-token {
	cursor: default;
	display: inline-block;
	vertical-align: middle;
	overflow: hidden;
	padding: .125em .5em;
	white-space: nowrap;
	position: relative;
	margin-right: .125em;
	border: 0 none;
	font-size: .9em
}

.ui-autocomplete-token-label {
	display: block;
	margin-right: 2em
}

.ui-autocomplete-token-icon {
	margin-top: -.5em;
	position: absolute;
	right: .2em;
	top: 50%;
	cursor: pointer
}

.ui-autocomplete-input-token {
	display: inline-block;
	vertical-align: middle;
	list-style-type: none;
	margin: 0 0 0 .125em;
	padding: .25em .25em .25em 0
}

.ui-autocomplete-input-token input {
	border: 0 none;
	width: 10em;
	outline: medium none;
	background-color: transparent;
	margin: 0;
	padding: 0;
	box-shadow: none;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0
}

.ui-autocomplete-dd .ui-autocomplete-loader {
	right: 2.25em
}

.ui-autocomplete-dd input.ui-corner-all, .ui-autocomplete-dd .ui-autocomplete-multiple-container.ui-corner-all {
	-moz-border-radius-topright: 0;
	-webkit-border-top-right-radius: 0;
	border-top-right-radius: 0;
	-moz-border-radius-bottomright: 0;
	-webkit-border-bottom-right-radius: 0;
	border-bottom-right-radius: 0
}

.ui-autocomplete-dd .ui-autocomplete-dropdown.ui-corner-all {
	-moz-border-radius-topleft: 0;
	-webkit-border-top-left-radius: 0;
	border-top-left-radius: 0;
	-moz-border-radius-bottomleft: 0;
	-webkit-border-bottom-left-radius: 0;
	border-bottom-left-radius: 0
}

.ui-fluid p-autocomplete, .ui-fluid .ui-autocomplete, .ui-fluid .ui-autocomplete-input {
	width: 100%
}

.ui-fluid .ui-autocomplete.ui-autocomplete-dd .ui-autocomplete-input, .ui-fluid .ui-autocomplete.ui-autocomplete-dd .ui-autocomplete-multiple-container {
	width: calc(100% - 2em)
}

.ui-fluid .ui-autocomplete .ui-autocomplete-dropdown.ui-button {
	width: 2em
}

.ui-blockui {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.ui-blockui-document {
	position: fixed
}

.ui-breadcrumb {
	margin: 0;
	padding: 0;
	padding: .5em
}

.ui-breadcrumb ul {
	margin: 0;
	padding: 0
}

.ui-breadcrumb ul li {
	display: inline-block;
	margin: 0 .25em
}

.ui-breadcrumb-chevron, .ui-breadcrumb-home {
	vertical-align: middle
}

.ui-breadcrumb ul li .ui-menuitem-link {
	text-decoration: none
}

.ui-breadcrumb .ui-menuitem-icon {
	margin-right: .25em;
	vertical-align: middle
}

.ui-breadcrumb .ui-menuitem-text {
	vertical-align: middle
}

.ui-button {
	display: inline-block;
	position: relative;
	padding: 0;
	text-decoration: none !important;
	cursor: pointer;
	text-align: center;
	zoom: 1;
	overflow: visible
}

p-button {
	display: inline-block
}

.ui-button .ui-button-text {
	display: block;
	line-height: normal
}

.ui-button-text-only .ui-button-text {
	padding: .25em 1em
}

.ui-button-icon-only .ui-button-text, .ui-button-text-empty .ui-button-text {
	padding: .25em;
	text-indent: -9999999px
}

.ui-button-text-icon-left .ui-button-text {
	padding: .25em 1em .25em 2.1em
}

.ui-button-text-icon-right .ui-button-text {
	padding: .25em 2.1em .25em 1em
}

.ui-button-icon-only .ui-button-icon-left, .ui-button-text-icon-left .ui-button-icon-left, .ui-button-text-icon-right .ui-button-icon-right {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	height: 1em
}

.ui-button-icon-only .ui-button-icon-left {
	top: 50%;
	left: 50%;
	margin-top: -.5em;
	margin-left: -.5em;
	width: 1em;
	height: 1em
}

.ui-button-icon-left {
	left: .5em
}

.ui-button-icon-right {
	right: .5em
}

.ui-buttonset .ui-button {
	margin-left: 0;
	margin-right: 0
}

button.ui-button::-moz-focus-inner {
	border: 0;
	padding: 0
}

.ui-fluid .ui-button {
	width: 100%
}

.ui-fluid .ui-button-text-icon-left .ui-button-text, .ui-fluid .ui-button-text-icon-right .ui-button-text {
	padding-left: 1em;
	padding-right: 1em
}

.ui-fluid .ui-buttonset {
	width: 100%
}

.ui-fluid .ui-buttonset.ui-buttonset-1 .ui-button {
	width: 100%
}

.ui-fluid .ui-buttonset.ui-buttonset-2 .ui-button {
	width: 50%
}

.ui-fluid .ui-buttonset.ui-buttonset-3 .ui-button {
	width: 33.3%
}

.ui-fluid .ui-buttonset.ui-buttonset-4 .ui-button {
	width: 25%
}

.ui-fluid .ui-buttonset.ui-buttonset-5 .ui-button {
	width: 20%
}

.ui-fluid .ui-buttonset.ui-buttonset-6 .ui-button {
	width: 16.6%
}

@media (max-width: 640px) {
	.ui-fluid .ui-buttonset.ui-buttonset-1 .ui-button, .ui-fluid .ui-buttonset.ui-buttonset-2 .ui-button, .ui-fluid .ui-buttonset.ui-buttonset-3 .ui-button, .ui-fluid .ui-buttonset.ui-buttonset-4 .ui-button, .ui-fluid .ui-buttonset.ui-buttonset-5 .ui-button, .ui-fluid .ui-buttonset.ui-buttonset-6 .ui-button {
		width: 100%
	}
}

.ui-calendar {
	position: relative;
	display: inline-block
}

.ui-calendar .ui-calendar-button {
	height: 100%;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	width: 2em;
	border-left: 0 none
}

.ui-calendar .ui-calendar-button:enabled:hover, .ui-calendar .ui-calendar-button:focus {
	border-left: 0 none
}

.ui-calendar .ui-datepicker {
	min-width: 100%
}

.ui-fluid .ui-calendar {
	width: 100%
}

.ui-fluid .ui-calendar-button {
	width: 2em
}

.ui-fluid .ui-datepicker-buttonbar button {
	width: auto
}

.ui-fluid .ui-calendar.ui-calendar-w-btn .ui-inputtext {
	width: calc(100% - 2em)
}

.ui-datepicker {
	width: auto;
	padding: .2em;
	position: absolute
}

.ui-datepicker.ui-datepicker-inline {
	display: inline-block;
	position: static
}

.ui-datepicker .ui-datepicker-group {
	border-left-width: 0;
	border-right-width: 0;
	border-top-width: 0;
	border-bottom-width: 0
}

.ui-datepicker .ui-datepicker-header {
	position: relative;
	padding: .5em 0
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
	position: absolute;
	top: .5em;
	width: 1.8em;
	height: 1.8em
}

.ui-datepicker .ui-datepicker-prev {
	left: .125em
}

.ui-datepicker .ui-datepicker-next {
	right: .125em
}

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -.5em;
	margin-left: -.5em
}

.ui-datepicker .ui-datepicker-title {
	margin: 0 2.3em;
	line-height: 1.8em;
	text-align: center
}

.ui-datepicker .ui-datepicker-title select {
	font-size: 1em;
	margin: .125em 0;
	vertical-align: middle
}

.ui-datepicker select.ui-datepicker-month {
	margin-right: .25em
}

.ui-datepicker span.ui-datepicker-year {
	margin-left: .25em
}

.ui-datepicker-multiple-month .ui-datepicker-group {
	display: table-cell;
	border-left-width: 0;
	border-top-width: 0;
	border-bottom-width: 0;
	border-right-width: 1px
}

.ui-datepicker table {
	width: 100%;
	font-size: .9em;
	border-collapse: collapse;
	margin: 0 0 .4em
}

.ui-datepicker th {
	padding: .5em;
	text-align: center;
	font-weight: bold;
	border: 0
}

.ui-datepicker td {
	border: 0;
	padding: 0
}

.ui-datepicker td > span, .ui-datepicker td > a {
	display: block;
	padding: .5em;
	text-decoration: none;
	border: 0 none;
	text-align: center
}

.ui-datepicker .ui-datepicker-buttonbar {
	border-left: 0 none;
	border-right: 0 none;
	border-bottom: 0 none;
	padding: .5em
}

.ui-datepicker .ui-datepicker-buttonbar > .ui-g > div:last-child {
	text-align: right
}

.ui-datepicker .ui-datepicker-buttonbar > .ui-g > div {
	padding: 0
}

.ui-calendar.ui-calendar-w-btn input {
	-moz-border-radius-topright: 0;
	-webkit-border-top-right-radius: 0;
	-khtml-border-top-right-radius: 0;
	border-top-right-radius: 0;
	-moz-border-radius-bottomright: 0;
	-webkit-border-bottom-right-radius: 0;
	-khtml-border-bottom-right-radius: 0;
	border-bottom-right-radius: 0
}

.ui-monthpicker .ui-monthpicker-month {
	width: 33.3%;
	display: inline-block;
	text-align: center;
	padding: .5em;
	cursor: pointer
}

.ui-datepicker-monthpicker select.ui-datepicker-year {
	width: auto
}

.ui-timepicker {
	text-align: center;
	padding: .5em 0
}

.ui-timepicker > div {
	display: inline-block;
	margin-left: .5em;
	min-width: 1.5em
}

.ui-timepicker > .ui-minute-picker, .ui-timepicker > .ui-second-picker {
	margin-left: 0
}

.ui-timepicker > .ui-separator {
	margin-left: 0;
	min-width: .75em
}

.ui-timepicker > .ui-separator a {
	visibility: hidden
}

.ui-timepicker > div a {
	display: block;
	opacity: .7;
	filter: Alpha(Opacity=70);
	cursor: pointer
}

.ui-timepicker > div a:last-child {
	margin-top: .3em
}

.ui-timepicker > div a:hover {
	display: block;
	opacity: 1;
	filter: Alpha(Opacity=100)
}

input[type=text]::-ms-clear {
	display: none
}

.ui-datepicker-touch-ui, .ui-calendar .ui-datepicker-touch-ui {
	position: fixed;
	top: 50%;
	left: 50%;
	min-width: 80vw
}

.ui-datepicker-touch-ui.ui-datepicker th {
	padding: 2em 0
}

.ui-datepicker-touch-ui.ui-datepicker td {
	padding: 0
}

.ui-datepicker-touch-ui.ui-datepicker td > span, .ui-datepicker-touch-ui.ui-datepicker td > a {
	padding: 2em 0
}

.ui-datepicker-touch-ui .ui-timepicker {
	padding: 1em 0
}

.ui-datepicker-touch-ui .ui-timepicker > div a {
	font-size: 2em
}

.ui-datepicker-mask {
	position: fixed;
	width: 100%;
	height: 100%
}

@media screen and (max-width: 40em) {
	.ui-datepicker-multiple-month {
		width: 17em;
		overflow: auto
	}

	.ui-datepicker-touch-ui.ui-datepicker th {
		padding: 1em 0
	}

	.ui-datepicker-touch-ui.ui-datepicker td span, .ui-datepicker-touch-ui.ui-datepicker td a {
		padding: 1em 0
	}
}

.ui-card-header img {
	width: 100%
}

.ui-card-body {
	padding: 1em
}

.ui-card-title {
	font-size: 1.5em;
	font-weight: bold;
	margin-bottom: .5em
}

.ui-card-subtitle {
	opacity: .7;
	margin-bottom: .5em;
	margin-top: -.25em;
	font-weight: bold
}

.ui-card-footer {
	padding-top: 1em
}

.ui-carousel {
	position: relative;
	padding: .063em
}

.ui-carousel .ui-carousel-viewport .ui-carousel-items {
	list-style: none outside none;
	margin: 0;
	padding: 0;
	position: relative;
	width: 32000px;
	left: 0
}

.ui-carousel .ui-carousel-viewport .ui-carousel-items .ui-carousel-item {
	margin: 1px;
	padding: 0;
	float: left;
	box-sizing: border-box
}

.ui-carousel .ui-carousel-viewport {
	overflow: hidden;
	position: relative;
	border: 0
}

.ui-carousel .ui-carousel-footer {
	margin: 1px 1px 0 1px;
	padding: .5em;
	overflow: hidden
}

.ui-carousel .ui-carousel-header {
	margin: 0 1px;
	overflow: hidden;
	padding: .625em
}

.ui-carousel .ui-carousel-header .ui-carousel-header-title {
	display: inline-block;
	overflow: hidden
}

.ui-carousel .ui-carousel-dropdown, .ui-carousel .ui-carousel-mobiledropdown {
	float: right;
	margin: 0 .625em;
	background-image: none
}

.ui-carousel .ui-carousel-dropdown option, .ui-carousel .ui-carousel-mobiledropdown option {
	background-image: none;
	border: 0 none;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none
}

.ui-carousel .ui-carousel-button {
	float: right;
	margin: .125em
}

.ui-carousel .ui-carousel-page-link {
	float: left;
	margin: 0 .125em;
	text-decoration: none
}

.ui-carousel .ui-carousel-page-link, .ui-carousel .ui-carousel-button {
	cursor: pointer
}

.ui-carousel .ui-carousel-page-links {
	margin: 0 .5em;
	margin-top: .125em;
	float: right
}

.ui-carousel .ui-carousel-mobiledropdown {
	display: none
}

.ui-chkbox {
	display: inline-block;
	cursor: pointer;
	vertical-align: middle;
	margin-right: .25em;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none
}

.ui-chkbox .ui-chkbox-box {
	width: 1.125em;
	height: 1.125em;
	line-height: 1.125em;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	text-align: center
}

.ui-chkbox .ui-chkbox-icon {
	display: block
}

.ui-chkbox-label {
	vertical-align: middle
}

.ui-chkbox-readonly {
	cursor: default
}

.ui-chips > ul.ui-inputtext {
	clear: left;
	cursor: text;
	list-style-type: none;
	margin: 0;
	overflow: hidden;
	padding: 0 .25em
}

.ui-chips-token {
	cursor: default;
	display: inline-block;
	vertical-align: middle;
	overflow: hidden;
	padding: .125em .5em;
	white-space: nowrap;
	position: relative;
	margin-right: .125em;
	border: 0 none;
	font-size: .9em
}

.ui-chips-token .ui-chips-token-label {
	display: block;
	margin-right: 2em
}

.ui-chips > .ui-state-disabled .ui-chips-token-label {
	margin-right: 0
}

.ui-chips-token .ui-chips-token-icon {
	margin-top: -.5em;
	position: absolute;
	right: .2em;
	top: 50%;
	cursor: pointer
}

.ui-chips-input-token {
	display: inline-block;
	vertical-align: middle;
	list-style-type: none;
	margin: 0 0 0 .125em;
	padding: .25em .25em .25em 0
}

.ui-chips-input-token input {
	border: 0 none;
	width: 10em;
	outline: medium none;
	background-color: transparent;
	margin: 0;
	padding: 0;
	box-shadow: none;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0
}

.ui-colorpicker {
	display: inline-block
}

.ui-colorpicker-dragging {
	cursor: pointer
}

.ui-colorpicker-overlay {
	position: relative
}

.ui-colorpicker-panel {
	position: relative;
	width: 193px;
	height: 166px;
	background-color: #323232;
	border-color: #191919
}

.ui-colorpicker-overlay-panel {
	position: absolute
}

.ui-colorpicker-preview {
	width: 2em;
	cursor: pointer
}

.ui-colorpicker-panel .ui-colorpicker-content {
	position: relative
}

.ui-colorpicker-panel .ui-colorpicker-color-selector {
	width: 150px;
	height: 150px;
	top: 8px;
	left: 8px;
	position: absolute
}

.ui-colorpicker-panel .ui-colorpicker-color {
	width: 150px;
	height: 150px;
	background: transparent url("./images/color.png") no-repeat left top
}

.ui-colorpicker-panel .ui-colorpicker-color-handle {
	position: absolute;
	top: 0;
	left: 150px;
	border-radius: 100%;
	width: 10px;
	height: 10px;
	border: 1px solid #fff;
	margin: -5px 0 0 -5px;
	cursor: pointer
}

.ui-colorpicker-panel .ui-colorpicker-hue {
	background: transparent url("./images/hue.png") no-repeat left top;
	width: 17px;
	height: 150px;
	top: 8px;
	left: 167px;
	position: absolute;
	opacity: .85
}

.ui-colorpicker-panel .ui-colorpicker-hue-handle {
	position: absolute;
	top: 150px;
	left: 0;
	width: 21px;
	margin-left: -2px;
	margin-top: -5px;
	height: 10px;
	border: 2px solid #fff;
	opacity: .85;
	cursor: pointer
}

.ui-colorpicker-panel.ui-state-disabled .ui-colorpicker-hue-handle, .ui-colorpicker-panel.ui-state-disabled .ui-colorpicker-color-handle {
	opacity: .5
}

.ui-contextmenu {
	width: 12.5em;
	padding: .25em;
	position: absolute;
	display: none
}

.ui-contextmenu .ui-menu-separator {
	border-width: 1px 0 0 0
}

.ui-contextmenu ul {
	list-style: none;
	margin: 0;
	padding: 0
}

.ui-contextmenu .ui-submenu-list {
	display: none;
	position: absolute;
	width: 12.5em;
	padding: .25em
}

.ui-contextmenu .ui-menuitem-link {
	padding: .25em;
	display: block;
	position: relative
}

.ui-contextmenu .ui-menuitem-icon {
	margin-right: .25em;
	vertical-align: middle
}

.ui-contextmenu .ui-menuitem-text {
	vertical-align: middle
}

.ui-contextmenu .ui-menuitem {
	position: relative
}

.ui-contextmenu .ui-menuitem-link .ui-submenu-icon {
	position: absolute;
	margin-top: -.5em;
	right: 0;
	top: 50%
}

.ui-contextmenu .ui-menuitem-active > .ui-submenu > .ui-submenu-list {
	display: block !important
}

.ui-datagrid .ui-paginator {
	text-align: center
}

.ui-datagrid-column {
	padding: .25em
}

.ui-datagrid-content-empty {
	padding: .25em .625em
}

.ui-datagrid .ui-datagrid-header, .ui-datagrid .ui-datagrid-footer {
	text-align: center;
	padding: .5em .75em
}

.ui-datagrid .ui-datagrid-header {
	border-bottom: 0 none
}

.ui-datagrid .ui-datagrid-footer {
	border-top: 0 none
}

.ui-datagrid .ui-paginator-top {
	border-bottom: 0 none
}

.ui-datagrid .ui-paginator-bottom {
	border-top: 0 none
}

.ui-datalist .ui-datalist-header, .ui-datalist .ui-datalist-footer {
	text-align: center;
	padding: .5em .75em
}

.ui-datalist .ui-datalist-header {
	border-bottom: 0 none
}

.ui-datalist .ui-datalist-footer {
	border-top: 0 none
}

.ui-datalist .ui-datalist-data {
	margin: 0;
	padding: 0
}

.ui-datalist .ui-datalist-data > li {
	list-style-type: none
}

.ui-datalist .ui-datalist-emptymessage {
	padding: .5em .75em
}

.ui-datalist.ui-datalist-scrollable .ui-datalist-content {
	overflow: auto
}

.ui-datalist .ui-paginator-top {
	border-bottom: 0 none
}

.ui-datalist .ui-paginator-bottom {
	border-top: 0 none
}

.ui-datascroller .ui-datascroller-header {
	text-align: center;
	padding: .5em .75em;
	border-bottom: 0 none
}

.ui-datascroller .ui-datascroller-footer {
	text-align: center;
	padding: .25em .625em;
	border-top: 0 none
}

.ui-datascroller .ui-datascroller-content {
	padding: .25em .625em
}

.ui-datascroller-inline .ui-datascroller-content {
	overflow: auto
}

.ui-datascroller .ui-datascroller-list {
	list-style-type: none;
	margin: 0;
	padding: 0
}

.ui-dataview {
	position: relative
}

.ui-dataview .ui-paginator {
	text-align: center
}

.ui-dataview-column {
	padding: .25em
}

.ui-dataview-content-empty {
	padding: .25em .625em
}

.ui-dataview .ui-dataview-header, .ui-dataview .ui-dataview-footer {
	padding: .5em .75em
}

.ui-dataview .ui-dataview-header {
	border-bottom: 0 none
}

.ui-dataview .ui-dataview-footer {
	border-top: 0 none
}

.ui-dataview .ui-paginator-top {
	border-bottom: 0 none
}

.ui-dataview .ui-paginator-bottom {
	border-top: 0 none
}

.ui-dataview.ui-dataview-list > .ui-dataview-content > div.ui-g > div {
	width: 100%
}

.ui-dataview .ui-dataview-emptymessage {
	padding: .5em .75em
}

.ui-dataview-loading {
	position: absolute;
	width: 100%;
	height: 100%;
	-ms-filter: "alpha(opacity=10)";
	opacity: .1;
	z-index: 1
}

.ui-dataview-loading-content {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 2;
	margin-top: -1em;
	margin-left: -1em
}

.ui-dataview-loading-icon {
	font-size: 2em
}

.ui-dialog {
	position: fixed;
	padding: 0
}

.ui-dynamicdialog {
	top: 50%;
	left: 50%
}

.ui-dialog .ui-dialog-titlebar {
	padding: .5em .75em;
	position: relative;
	border: 0
}

.ui-dialog .ui-dialog-content {
	position: relative;
	border: 0;
	padding: .5em .75em;
	background: 0;
	zoom: 1
}

.ui-dialog-resizable .ui-dialog-content {
	overflow: auto
}

.ui-dialog .ui-resizable-se {
	width: 14px;
	height: 14px;
	right: 3px;
	bottom: 3px
}

.ui-draggable .ui-dialog-titlebar {
	cursor: move
}

.ui-dialog .ui-dialog-titlebar-icon {
	text-decoration: none;
	float: right;
	float: right;
	padding: .125em;
	cursor: pointer;
	border: 1px solid transparent
}

.ui-dialog .ui-dialog-titlebar-icon span {
	display: block;
	margin: 0
}

.ui-dialog-footer {
	padding: 1em;
	border-width: 1px 0 0 0;
	text-align: right
}

.ui-dialog-mask {
	position: fixed;
	width: 100%;
	height: 100%
}

.ui-dialog-maximized {
	-webkit-transition: left .3s, top .3s, width .3s, height .3s;
	transition: left .3s, top .3s, width .3s, height .3s
}

.ui-confirmdialog {
	width: 30em;
	top: 50%;
	left: 50%
}

.ui-confirmdialog.ui-dialog .ui-dialog-content {
	padding: 1em 2em
}

.ui-confirmdialog .ui-dialog-content .ui-confirmdialog-icon {
	font-size: 1.5em;
	margin-right: .5em;
	position: relative;
	top: .2em
}

.ui-dialog-footer .ui-button {
	margin-right: .25em
}

.ui-fluid .ui-dialog-footer .ui-button {
	width: auto
}

.ui-rtl .ui-dialog .ui-dialog-titlebar-close {
	float: left
}

.ui-rtl .ui-dialog .ui-dialog-buttonpane button {
	text-align: right
}

@media screen and (max-width: 40em) {
	.ui-confirmdialog {
		width: 90%
	}
}

.ui-dropdown {
	display: inline-block;
	position: relative;
	cursor: pointer;
	vertical-align: middle;
	min-width: 12.5em
}

.ui-dropdown .ui-dropdown-hidden-select {
	bottom: 0;
	clip: auto
}

.ui-dropdown .ui-dropdown-hidden-select select {
	-webkit-transform: none;
	transform: none;
	height: 1px;
	position: absolute;
	top: 0;
	clip: rect(0, 0, 0, 0);
	pointer-events: none
}

.ui-dropdown .ui-dropdown-clear-icon {
	position: absolute;
	right: 2em;
	top: 50%;
	font-size: 1em;
	height: 1em;
	margin-top: -.5em
}

.ui-dropdown .ui-dropdown-trigger {
	border-right: 0;
	border-top: 0;
	border-bottom: 0;
	cursor: pointer;
	width: 1.5em;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	padding: 0 .25em
}

.ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon {
	top: 50%;
	left: 50%;
	margin-top: -.5em;
	margin-left: -.5em;
	position: absolute
}

.ui-dropdown .ui-dropdown-label {
	display: block;
	border: 0;
	white-space: nowrap;
	overflow: hidden;
	font-weight: normal;
	width: 100%;
	padding-right: 2.5em
}

.ui-dropdown-item-empty, .ui-dropdown-label-empty {
	text-indent: -9999px;
	overflow: hidden
}

.ui-dropdown.ui-state-disabled .ui-dropdown-trigger, .ui-dropdown.ui-state-disabled .ui-dropdown-label {
	cursor: default
}

.ui-dropdown label.ui-dropdown-label {
	cursor: pointer
}

.ui-dropdown input.ui-dropdown-label {
	cursor: default
}

.ui-dropdown .ui-dropdown-panel {
	min-width: 100%
}

.ui-dropdown-panel {
	position: absolute;
	height: auto
}

.ui-dropdown-panel .ui-dropdown-items-wrapper {
	overflow: auto
}

.ui-dropdown-panel .ui-dropdown-item {
	font-weight: normal;
	border: 0 none;
	cursor: pointer;
	margin: 1px 0;
	padding: .125em .25em;
	text-align: left
}

.ui-dropdown-panel .ui-dropdown-empty-message {
	padding: .5em
}

.ui-dropdown-panel .ui-dropdown-item-group {
	font-weight: bold;
	cursor: default
}

.ui-dropdown-panel .ui-dropdown-list {
	padding: .4em;
	border: 0 none
}

.ui-dropdown-panel .ui-dropdown-filter {
	width: 100%;
	box-sizing: border-box;
	padding-right: 1.5em
}

.ui-dropdown-panel .ui-dropdown-filter-container {
	position: relative;
	margin: 0;
	padding: .4em;
	display: inline-block;
	width: 100%
}

.ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
	position: absolute;
	top: .8em;
	right: 1em
}

.ui-fluid .ui-dropdown {
	width: 100%;
	min-width: 100%
}

.ui-fieldset, .ui-fieldset .ui-fieldset-legend {
	padding: .5em 1em
}

.ui-fieldset-toggleable .ui-fieldset-legend {
	padding: 0
}

.ui-fieldset-toggleable .ui-fieldset-legend a {
	padding: .5em 1em;
	cursor: pointer;
	white-space: nowrap;
	display: block
}

.ui-fieldset .ui-fieldset-toggler {
	margin-right: .1em;
	display: inline-block;
	vertical-align: middle
}

.ui-fieldset .ui-fieldset-legend-text {
	vertical-align: middle
}

.ui-fieldset .ui-fieldset-content-wrapper-overflown {
	overflow: hidden
}

.ui-fileupload-buttonbar .ui-fileupload-choose.ui-state-disabled input {
	cursor: default
}

.ui-fileupload-buttonbar {
	padding: .5em;
	border-bottom: 0 none
}

.ui-fileupload-buttonbar .ui-button {
	vertical-align: middle;
	margin-right: .25em
}

.ui-fileupload-content {
	padding: 1em;
	position: relative;
	transition: border-color .3s
}

.ui-fileupload-content.ui-fileupload-highlight {
	border-color: #156090
}

.ui-fileupload-files img {
	border: 0
}

.ui-fileupload-files {
	display: table
}

.ui-fileupload-row {
	display: table-row
}

.ui-fileupload-row > div {
	display: table-cell;
	padding: .5em 1em;
	vertical-align: middle
}

.ui-fileupload-content .ui-progressbar {
	width: 100%;
	position: absolute;
	top: 1px;
	left: 0;
	height: .25em;
	border: 0 none
}

.ui-fileupload-content .ui-progressbar-value {
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	border: 0 none
}

.ui-fileupload-choose {
	position: relative;
	overflow: hidden
}

.ui-fileupload-choose input[type=file] {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	opacity: 0;
	min-height: 100%;
	font-size: 100px;
	text-align: right;
	filter: alpha(opacity=0);
	direction: ltr;
	cursor: pointer;
	z-index: 1
}

.ui-fileupload-choose.ui-fileupload-choose-selected input[type=file] {
	display: none
}

.ui-fluid .ui-fileupload .ui-button {
	width: auto
}

.ui-fluid .ui-fileupload-content .ui-button-icon-only {
	width: 2em
}

@media (max-width: 40em) {
	.ui-fileupload-buttonbar .ui-button {
		display: block;
		width: 100%;
		margin-right: 0;
		margin-bottom: .25em
	}

	.ui-fileupload-buttonbar .ui-button:last-child {
		margin-bottom: 0
	}

	.ui-fileupload-row > div {
		display: block
	}
}

.ui-fluid .fc .ui-button {
	width: auto
}

.ui-galleria {
	overflow: hidden;
	visibility: hidden;
	position: relative
}

.ui-galleria .ui-galleria-panel-wrapper {
	position: relative;
	padding: 0;
	margin: 0
}

.ui-galleria .ui-galleria-panel {
	filter: inherit;
	position: absolute;
	top: 0;
	left: 0;
	list-style-type: none
}

.ui-galleria .ui-galleria-filmstrip-wrapper {
	overflow: hidden;
	margin: .25em auto;
	position: relative
}

.ui-galleria .ui-galleria-filmstrip {
	list-style: none outside none;
	margin: 0;
	padding: 0;
	width: 2340px;
	position: absolute;
	top: 0;
	left: 0
}

.ui-galleria .ui-galleria-frame {
	float: left;
	margin-right: 5px;
	opacity: .3;
	cursor: pointer
}

.ui-galleria .ui-galleria-frame-active {
	opacity: 1
}

.ui-galleria .ui-galleria-frame-content {
	overflow: hidden
}

.ui-galleria .ui-galleria-nav-next, .ui-galleria .ui-galleria-nav-prev {
	cursor: pointer;
	position: absolute;
	z-index: 1
}

.ui-galleria .ui-galleria-nav-prev {
	left: 4px
}

.ui-galleria .ui-galleria-nav-next {
	right: 4px
}

.ui-galleria .ui-galleria-caption {
	position: absolute;
	left: 1px;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	color: #ededed;
	padding: .2em 1em
}

.ui-galleria .ui-galleria-caption h4 {
	color: #ededed
}

.ui-galleria .ui-galleria-panel-content {
	padding: 1em 1.4em
}

.ui-grid {
	clear: both;
	padding: 0;
	margin: 0
}

.ui-grid::before, .ui-grid::after {
	content: "";
	display: table
}

.ui-grid::after {
	clear: both
}

.ui-grid .ui-grid-row {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	clear: both
}

.ui-grid-row::after {
	clear: both;
	content: "";
	display: table
}

.ui-grid-col-1, .ui-grid-col-2, .ui-grid-col-3, .ui-grid-col-4, .ui-grid-col-5, .ui-grid-col-6, .ui-grid-col-7, .ui-grid-col-8, .ui-grid-col-9, .ui-grid-col-10, .ui-grid-col-11, .ui-grid-col-12 {
	float: left;
	box-sizing: border-box
}

.ui-grid-col-1 {
	width: 8.33333%
}

.ui-grid-col-2 {
	width: 16.66666%
}

.ui-grid-col-3 {
	width: 25%
}

.ui-grid-col-4 {
	width: 33.33333%
}

.ui-grid-col-5 {
	width: 41.66666%
}

.ui-grid-col-6 {
	width: 50%
}

.ui-grid-col-7 {
	width: 58.33333%
}

.ui-grid-col-8 {
	width: 66.66666%
}

.ui-grid-col-9 {
	width: 75%
}

.ui-grid-col-10 {
	width: 83.33333%
}

.ui-grid-col-11 {
	width: 91.66666%
}

.ui-grid-col-12 {
	width: 100%
}

@media (min-width: 480px) {
	.ui-grid-fixed {
		width: 480px
	}
}

@media (min-width: 768px) {
	.ui-grid-fixed {
		width: 768px
	}
}

@media (min-width: 960px) {
	.ui-grid-fixed {
		width: 960px
	}
}

@media (min-width: 1024px) {
	.ui-grid-fixed {
		width: 1024px
	}
}

@media (max-width: 640px) {
	.ui-grid-responsive .ui-grid-row {
		display: block
	}

	.ui-grid-responsive .ui-grid-col-1, .ui-grid-responsive .ui-grid-col-2, .ui-grid-responsive .ui-grid-col-3, .ui-grid-responsive .ui-grid-col-4, .ui-grid-responsive .ui-grid-col-5, .ui-grid-responsive .ui-grid-col-6, .ui-grid-responsive .ui-grid-col-7, .ui-grid-responsive .ui-grid-col-8, .ui-grid-responsive .ui-grid-col-9, .ui-grid-responsive .ui-grid-col-10, .ui-grid-responsive .ui-grid-col-11, .ui-grid-responsive .ui-grid-col-12 {
		width: 100%;
		float: none
	}
}

.ui-grid.ui-grid-pad > .ui-grid-row > div {
	padding: .25em .5em
}

@media (max-width: 640px) {
	.ui-grid-responsive .ui-grid-row {
		display: block
	}

	.ui-grid-responsive .ui-grid-col-1, .ui-grid-responsive .ui-grid-col-2, .ui-grid-responsive .ui-grid-col-3, .ui-grid-responsive .ui-grid-col-4, .ui-grid-responsive .ui-grid-col-5, .ui-grid-responsive .ui-grid-col-6, .ui-grid-responsive .ui-grid-col-7, .ui-grid-responsive .ui-grid-col-8, .ui-grid-responsive .ui-grid-col-9, .ui-grid-responsive .ui-grid-col-10, .ui-grid-responsive .ui-grid-col-11, .ui-grid-responsive .ui-grid-col-12 {
		width: 100%;
		float: none
	}
}

.ui-g {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box
}

.ui-g::after {
	clear: both;
	content: "";
	display: table;
	box-sizing: border-box
}

.ui-g-1, .ui-g-2, .ui-g-3, .ui-g-4, .ui-g-5, .ui-g-6, .ui-g-7, .ui-g-8, .ui-g-9, .ui-g-10, .ui-g-11, .ui-g-12 {
	float: left;
	box-sizing: border-box;
	padding: .5em
}

.ui-g-1 {
	width: 8.3333%
}

.ui-g-2 {
	width: 16.6667%
}

.ui-g-3 {
	width: 25%
}

.ui-g-4 {
	width: 33.3333%
}

.ui-g-5 {
	width: 41.6667%
}

.ui-g-6 {
	width: 50%
}

.ui-g-7 {
	width: 58.3333%
}

.ui-g-8 {
	width: 66.6667%
}

.ui-g-9 {
	width: 75%
}

.ui-g-10 {
	width: 83.3333%
}

.ui-g-11 {
	width: 91.6667%
}

.ui-g-12 {
	width: 100%
}

.ui-g-offset-12 {
	margin-left: 100%
}

.ui-g-offset-11 {
	margin-left: 91.66666667%
}

.ui-g-offset-10 {
	margin-left: 83.33333333%
}

.ui-g-offset-9 {
	margin-left: 75%
}

.ui-g-offset-8 {
	margin-left: 66.66666667%
}

.ui-g-offset-7 {
	margin-left: 58.33333333%
}

.ui-g-offset-6 {
	margin-left: 50%
}

.ui-g-offset-5 {
	margin-left: 41.66666667%
}

.ui-g-offset-4 {
	margin-left: 33.33333333%
}

.ui-g-offset-3 {
	margin-left: 25%
}

.ui-g-offset-2 {
	margin-left: 16.66666667%
}

.ui-g-offset-1 {
	margin-left: 8.33333333%
}

.ui-g-offset-0 {
	margin-left: 0
}

@media screen and (max-width: 40em) {
	.ui-sm-1, .ui-sm-2, .ui-sm-3, .ui-sm-4, .ui-sm-5, .ui-sm-6, .ui-sm-7, .ui-sm-8, .ui-sm-9, .ui-sm-10, .ui-sm-11, .ui-sm-12 {
		padding: .5em
	}

	.ui-sm-1 {
		width: 8.3333%
	}

	.ui-sm-2 {
		width: 16.6667%
	}

	.ui-sm-3 {
		width: 25%
	}

	.ui-sm-4 {
		width: 33.3333%
	}

	.ui-sm-5 {
		width: 41.6667%
	}

	.ui-sm-6 {
		width: 50%
	}

	.ui-sm-7 {
		width: 58.3333%
	}

	.ui-sm-8 {
		width: 66.6667%
	}

	.ui-sm-9 {
		width: 75%
	}

	.ui-sm-10 {
		width: 83.3333%
	}

	.ui-sm-11 {
		width: 91.6667%
	}

	.ui-sm-12 {
		width: 100%
	}

	.ui-sm-offset-12 {
		margin-left: 100%
	}

	.ui-sm-offset-11 {
		margin-left: 91.66666667%
	}

	.ui-sm-offset-10 {
		margin-left: 83.33333333%
	}

	.ui-sm-offset-9 {
		margin-left: 75%
	}

	.ui-sm-offset-8 {
		margin-left: 66.66666667%
	}

	.ui-sm-offset-7 {
		margin-left: 58.33333333%
	}

	.ui-sm-offset-6 {
		margin-left: 50%
	}

	.ui-sm-offset-5 {
		margin-left: 41.66666667%
	}

	.ui-sm-offset-4 {
		margin-left: 33.33333333%
	}

	.ui-sm-offset-3 {
		margin-left: 25%
	}

	.ui-sm-offset-2 {
		margin-left: 16.66666667%
	}

	.ui-sm-offset-1 {
		margin-left: 8.33333333%
	}

	.ui-sm-offset-0 {
		margin-left: 0
	}
}

@media screen and (min-width: 40.063em) {
	.ui-md-1, .ui-md-2, .ui-md-3, .ui-md-4, .ui-md-5, .ui-md-6, .ui-md-7, .ui-md-8, .ui-md-9, .ui-md-10, .ui-md-11, .ui-md-12 {
		padding: .5em
	}

	.ui-md-1 {
		width: 8.3333%
	}

	.ui-md-2 {
		width: 16.6667%
	}

	.ui-md-3 {
		width: 25%
	}

	.ui-md-4 {
		width: 33.3333%
	}

	.ui-md-5 {
		width: 41.6667%
	}

	.ui-md-6 {
		width: 50%
	}

	.ui-md-7 {
		width: 58.3333%
	}

	.ui-md-8 {
		width: 66.6667%
	}

	.ui-md-9 {
		width: 75%
	}

	.ui-md-10 {
		width: 83.3333%
	}

	.ui-md-11 {
		width: 91.6667%
	}

	.ui-md-12 {
		width: 100%
	}

	.ui-md-offset-12 {
		margin-left: 100%
	}

	.ui-md-offset-11 {
		margin-left: 91.66666667%
	}

	.ui-md-offset-10 {
		margin-left: 83.33333333%
	}

	.ui-md-offset-9 {
		margin-left: 75%
	}

	.ui-md-offset-8 {
		margin-left: 66.66666667%
	}

	.ui-md-offset-7 {
		margin-left: 58.33333333%
	}

	.ui-md-offset-6 {
		margin-left: 50%
	}

	.ui-md-offset-5 {
		margin-left: 41.66666667%
	}

	.ui-md-offset-4 {
		margin-left: 33.33333333%
	}

	.ui-md-offset-3 {
		margin-left: 25%
	}

	.ui-md-offset-2 {
		margin-left: 16.66666667%
	}

	.ui-md-offset-1 {
		margin-left: 8.33333333%
	}

	.ui-md-offset-0 {
		margin-left: 0
	}
}

@media screen and (min-width: 64.063em) {
	.ui-lg-1, .ui-lg-2, .ui-lg-3, .ui-lg-4, .ui-lg-5, .ui-lg-6, .ui-lg-7, .ui-lg-8, .ui-lg-9, .ui-lg-10, .ui-lg-11, .ui-lg-12 {
		padding: .5em
	}

	.ui-lg-1 {
		width: 8.3333%
	}

	.ui-lg-2 {
		width: 16.6667%
	}

	.ui-lg-3 {
		width: 25%
	}

	.ui-lg-4 {
		width: 33.3333%
	}

	.ui-lg-5 {
		width: 41.6667%
	}

	.ui-lg-6 {
		width: 50%
	}

	.ui-lg-7 {
		width: 58.3333%
	}

	.ui-lg-8 {
		width: 66.6667%
	}

	.ui-lg-9 {
		width: 75%
	}

	.ui-lg-10 {
		width: 83.3333%
	}

	.ui-lg-11 {
		width: 91.6667%
	}

	.ui-lg-12 {
		width: 100%
	}

	.ui-lg-offset-12 {
		margin-left: 100%
	}

	.ui-lg-offset-11 {
		margin-left: 91.66666667%
	}

	.ui-lg-offset-10 {
		margin-left: 83.33333333%
	}

	.ui-lg-offset-9 {
		margin-left: 75%
	}

	.ui-lg-offset-8 {
		margin-left: 66.66666667%
	}

	.ui-lg-offset-7 {
		margin-left: 58.33333333%
	}

	.ui-lg-offset-6 {
		margin-left: 50%
	}

	.ui-lg-offset-5 {
		margin-left: 41.66666667%
	}

	.ui-lg-offset-4 {
		margin-left: 33.33333333%
	}

	.ui-lg-offset-3 {
		margin-left: 25%
	}

	.ui-lg-offset-2 {
		margin-left: 16.66666667%
	}

	.ui-lg-offset-1 {
		margin-left: 8.33333333%
	}

	.ui-lg-offset-0 {
		margin-left: 0
	}
}

@media screen and (min-width: 90.063em) {
	.ui-xl-1, .ui-xl-2, .ui-xl-3, .ui-xl-4, .ui-xl-5, .ui-xl-6, .ui-xl-7, .ui-xl-8, .ui-xl-9, .ui-xl-10, .ui-xl-11, .ui-xl-12 {
		padding: .5em
	}

	.ui-xl-1 {
		width: 8.3333%
	}

	.ui-xl-2 {
		width: 16.6667%
	}

	.ui-xl-3 {
		width: 25%
	}

	.ui-xl-4 {
		width: 33.3333%
	}

	.ui-xl-5 {
		width: 41.6667%
	}

	.ui-xl-6 {
		width: 50%
	}

	.ui-xl-7 {
		width: 58.3333%
	}

	.ui-xl-8 {
		width: 66.6667%
	}

	.ui-xl-9 {
		width: 75%
	}

	.ui-xl-10 {
		width: 83.3333%
	}

	.ui-xl-11 {
		width: 91.6667%
	}

	.ui-xl-12 {
		width: 100%
	}

	.ui-xl-offset-12 {
		margin-left: 100%
	}

	.ui-xl-offset-11 {
		margin-left: 91.66666667%
	}

	.ui-xl-offset-10 {
		margin-left: 83.33333333%
	}

	.ui-xl-offset-9 {
		margin-left: 75%
	}

	.ui-xl-offset-8 {
		margin-left: 66.66666667%
	}

	.ui-xl-offset-7 {
		margin-left: 58.33333333%
	}

	.ui-xl-offset-6 {
		margin-left: 50%
	}

	.ui-xl-offset-5 {
		margin-left: 41.66666667%
	}

	.ui-xl-offset-4 {
		margin-left: 33.33333333%
	}

	.ui-xl-offset-3 {
		margin-left: 25%
	}

	.ui-xl-offset-2 {
		margin-left: 16.66666667%
	}

	.ui-xl-offset-1 {
		margin-left: 8.33333333%
	}

	.ui-xl-offset-0 {
		margin-left: 0
	}
}

.ui-g-nopad {
	padding: 0
}

.ui-growl {
	position: fixed;
	top: 20px;
	right: 20px;
	width: 20em
}

.ui-growl-item-container {
	position: relative;
	margin: 0 0 10px 0;
	opacity: .95;
	filter: alpha(opacity=95)
}

.ui-growl-item {
	position: relative;
	display: block;
	padding: .5em 1em
}

.ui-growl-item p {
	padding: 0;
	margin: 0
}

.ui-growl-icon-close {
	position: absolute;
	top: 4px;
	right: 4px;
	cursor: pointer
}

.ui-growl-title {
	font-weight: bold;
	padding: 0 0 .5em 0;
	display: block
}

.ui-growl-image {
	position: absolute;
	display: inline-block;
	left: .5em;
	top: .25em;
	padding: 0;
	font-size: 2em
}

.ui-growl-message {
	padding: 0 0 .25em 0;
	margin-left: 2.5em
}

.ui-growl-message p {
	font-weight: normal
}

.ui-inplace .ui-inplace-display {
	display: inline;
	cursor: pointer;
	border: 0 none;
	padding: .25em;
	font-weight: normal
}

.ui-inplace .ui-inplace-content {
	display: inline
}

.ui-fluid .ui-inputgroup p-inputmask {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto
}

.ui-inputgroup p-inputmask:not(:first-child) .ui-inputtext {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left: 0 none
}

.ui-inputgroup p-inputmask:not(:last-child) .ui-inputtext {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 0 none
}

.ui-inputswitch {
	position: relative;
	display: inline-block;
	width: 3em;
	height: 1.75em
}

.ui-inputswitch-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	-webkit-transition: .3s;
	transition: .3s;
	border-radius: 30px
}

.ui-inputswitch-slider:before {
	position: absolute;
	content: "";
	height: 1.250em;
	width: 1.250em;
	left: .25em;
	bottom: .25em;
	border-radius: 50%;
	-webkit-transition: .3s;
	transition: .3s
}

.ui-inputswitch-checked .ui-inputswitch-slider:before {
	-webkit-transform: translateX(1.250em);
	-ms-transform: translateX(1.250em);
	transform: translateX(1.250em)
}

.ui-inputswitch.ui-state-disabled .ui-inputswitch-slider, .ui-inputswitch-readonly .ui-inputswitch-slider {
	cursor: default
}

.ui-inputtext {
	margin: 0;
	outline: medium none;
	padding: .25em;
	font-weight: normal
}

.ui-widget-header .ui-inputtext, .ui-widget-content .ui-inputtext {
	font-weight: normal
}

.ui-fluid .ui-inputtext {
	width: 100%;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box
}

.ui-inputgroup {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex
}

.ui-inputgroup .ui-inputgroup-addon {
	display: inline-block;
	text-align: center;
	min-width: 1.5em;
	padding: .25em;
	border-width: 1px;
	border-style: solid
}

.ui-inputgroup .ui-inputgroup-addon + .ui-inputgroup-addon {
	border-left: 0 none
}

.ui-inputgroup .ui-inputtext {
	padding-left: .5em
}

.ui-inputgroup .ui-inputtext:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left: 0 none
}

.ui-inputgroup .ui-inputtext:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 0 none
}

.ui-inputgroup .ui-button {
	margin-right: 0;
	border-radius: 0
}

.ui-fluid .ui-inputgroup .ui-button {
	width: auto
}

.ui-fluid .ui-inputgroup .ui-inputtext {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto
}

.ui-inputgroup .ui-chkbox, .ui-inputgroup .ui-radiobutton {
	margin-right: 0;
	vertical-align: bottom
}

.ui-float-label {
	display: block;
	position: relative
}

.ui-float-label > label {
	font-weight: normal;
	position: absolute;
	pointer-events: none;
	left: .25em;
	top: 50%;
	margin-top: -.5em;
	transition: .3s ease all;
	-moz-transition: .3s ease all;
	-webkit-transition: .3s ease all;
	color: #898989;
	line-height: 1
}

.ui-float-label > input:-webkit-autofill ~ label {
	top: -.75em;
	font-size: 12px
}

.ui-float-label > input:focus ~ label, .ui-float-label > input.ui-state-filled ~ label, .ui-float-label > .ui-inputwrapper-focus ~ label, .ui-float-label > .ui-inputwrapper-filled ~ label {
	top: -.75em;
	font-size: 12px
}

.ui-inputtextarea-resizable {
	overflow: hidden;
	resize: none
}

.ui-fluid .ui-inputtextarea {
	width: 100%
}

.ui-float-label > textarea ~ label {
	left: .25em;
	top: .75em
}

.ui-float-label textarea:-webkit-autofill ~ label {
	top: -.75em;
	font-size: 12px
}

.ui-float-label textarea:focus ~ label, .ui-float-label textarea.ui-state-filled ~ label {
	top: -.75em;
	font-size: 12px
}

.ui-lightbox {
	position: fixed;
	display: none;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%)
}

.ui-lightbox-content-wrapper {
	position: relative
}

.ui-lightbox-content {
	position: relative;
	margin: 0;
	padding: 0;
	background-color: #000
}

.ui-lightbox-nav-right, .ui-lightbox-nav-left {
	position: absolute;
	top: 50%;
	cursor: pointer
}

.ui-lightbox-nav-left {
	left: 0
}

.ui-lightbox-nav-right {
	right: 0
}

.ui-lightbox-loading .ui-lightbox-content {
	background: url("./images/loading.gif") #000 center center no-repeat
}

.ui-lightbox-caption {
	padding: .2em .4em;
	display: none
}

.ui-lightbox-caption-text {
	margin: .3em 0 .1em 0;
	float: left
}

.ui-lightbox-close {
	float: right;
	margin: 0;
	padding: .125em
}

.ui-lightbox-close.ui-state-hover {
	padding: 0
}

.ui-lightbox-nav-left, .ui-lightbox-nav-right {
	opacity: .5
}

.ui-lightbox-nav-left:hover, .ui-lightbox-nav-right:hover {
	opacity: 1
}

.ui-listbox {
	padding: .25em;
	width: 10em
}

.ui-listbox .ui-listbox-list-wrapper {
	overflow: auto
}

.ui-listbox .ui-listbox-list {
	list-style-type: none;
	margin: 0;
	padding: 0
}

.ui-listbox .ui-listbox-item {
	padding: .25em;
	border: 0 none;
	cursor: pointer;
	font-weight: normal;
	margin-bottom: 1px
}

.ui-listbox .ui-listbox-item > span {
	vertical-align: middle
}

.ui-listbox .ui-listbox-item:last-child {
	margin-bottom: 0
}

.ui-listbox.ui-state-disabled .ui-listbox-item {
	cursor: default
}

.ui-listbox-header {
	margin-bottom: .3em;
	padding: .125em .2em;
	position: relative
}

.ui-listbox-header .ui-chkbox {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer
}

.ui-listbox-header .ui-listbox-filter-container {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	width: 100%
}

.ui-listbox-header.ui-listbox-header-w-checkbox .ui-listbox-filter-container {
	width: calc(100% - 2em)
}

.ui-listbox-header .ui-listbox-filter-container .ui-listbox-filter-icon {
	position: absolute;
	top: .25em;
	left: .25em
}

.ui-listbox-header .ui-inputtext {
	padding: .125em .125em .125em 1.25em;
	width: 100%
}

.ui-listbox-footer {
	padding: .125em .2em
}

.ui-megamenu {
	padding: .25em
}

.ui-megamenu-root-list {
	margin: 0;
	padding: 0;
	list-style: none
}

.ui-megamenu-root-list > .ui-menuitem {
	position: relative
}

.ui-megamenu .ui-menuitem-link {
	padding: .25em;
	display: block;
	text-decoration: none
}

.ui-megamenu .ui-menuitem-icon {
	margin-right: .25em;
	vertical-align: middle
}

.ui-megamenu .ui-menuitem-text {
	vertical-align: middle
}

.ui-megamenu-panel {
	display: none;
	position: absolute;
	width: auto
}

.ui-megamenu-root-list > .ui-menuitem-active > .ui-megamenu-panel {
	display: block
}

.ui-megamenu-panel .ui-menuitem {
	margin: .125em 0
}

.ui-megamenu-submenu {
	margin: 0;
	padding: 0;
	list-style: none;
	width: 12.5em
}

.ui-megamenu-submenu-header {
	padding: .25em
}

.ui-megamenu-horizontal .ui-megamenu-root-list > .ui-menuitem {
	display: inline-block
}

.ui-megamenu-horizontal .ui-megamenu-root-list > .ui-menuitem > .ui-menuitem-link > .ui-submenu-icon {
	vertical-align: middle;
	margin-left: .25em
}

.ui-megamenu-vertical {
	width: 12.5em
}

.ui-megamenu-vertical .ui-megamenu-root-list > .ui-menuitem {
	display: block
}

.ui-megamenu-vertical .ui-megamenu-root-list > .ui-menuitem > .ui-menuitem-link {
	position: relative
}

.ui-megamenu-vertical .ui-megamenu-root-list > .ui-menuitem > .ui-menuitem-link > .ui-submenu-icon {
	position: absolute;
	width: 1em;
	height: 1em;
	top: 50%;
	right: 0;
	margin-top: -.5em
}

.ui-megamenu .ui-g {
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap
}

.ui-menubar {
	padding: .25em
}

.ui-menubar:after {
	content: "";
	clear: both;
	display: table
}

.ui-menubar ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.ui-menubar .ui-menuitem-link {
	display: block;
	padding: .25em;
	position: relative;
	text-decoration: none
}

.ui-menubar .ui-menuitem-icon {
	margin-right: .25em
}

.ui-menubar .ui-menubar-root-list {
	display: inline-block
}

.ui-menubar .ui-menubar-root-list > .ui-menuitem {
	display: inline-block;
	position: relative
}

.ui-menubar .ui-menubar-root-list > .ui-menuitem > .ui-menuitem-link {
	padding: .5em
}

.ui-menubar .ui-menubar-root-list > .ui-menuitem > .ui-menuitem-link > .ui-submenu-icon {
	vertical-align: middle;
	margin-left: .25em
}

.ui-menubar .ui-menubar-root-list > li ul {
	display: none
}

.ui-menubar .ui-menubar-root-list > .ui-menu-separator {
	display: inline-block;
	vertical-align: middle;
	height: 1.5em
}

.ui-menubar .ui-submenu-list {
	display: none;
	position: absolute;
	min-width: 12.5em;
	padding: .25em
}

.ui-menubar .ui-submenu-list .ui-menuitem {
	margin: .125em 0;
	position: relative
}

.ui-menubar .ui-menuitem-icon {
	margin-right: .25em;
	vertical-align: middle
}

.ui-menubar .ui-menuitem-text {
	vertical-align: middle
}

.ui-menubar .ui-submenu-list .ui-menuitem-link .ui-submenu-icon {
	position: absolute;
	margin-top: -.5em;
	right: 0;
	top: 50%
}

.ui-menubar .ui-menuitem-active > .ui-submenu > .ui-submenu-list {
	display: block
}

.ui-menubar .ui-menubar-custom {
	float: right;
	padding: .25em
}

.ui-menu {
	width: 12.5em;
	padding: .25em
}

.ui-menu.ui-menu-dynamic {
	position: absolute
}

.ui-menu .ui-menu-separator {
	border-width: 1px 0 0 0
}

.ui-menu ul {
	list-style: none;
	margin: 0;
	padding: 0
}

.ui-menu .ui-submenu-header {
	padding: .25em .5em;
	margin: .125em 0
}

.ui-menu .ui-menuitem {
	margin: .125em 0
}

.ui-menu .ui-menuitem-link {
	cursor: pointer;
	padding: .25em;
	display: block;
	text-decoration: none
}

.ui-menu .ui-menuitem-icon {
	margin-right: .25em;
	vertical-align: middle
}

.ui-menu .ui-menuitem-text {
	vertical-align: middle
}

.ui-message {
	border: 1px solid;
	margin: 0 .25em;
	padding: .25em .5em;
	display: inline-block;
	vertical-align: top
}

.ui-message .ui-message-icon, .ui-message .ui-message-text {
	vertical-align: middle
}

.ui-fluid .ui-message {
	display: block
}

.ui-messages {
	border: 1px solid;
	margin: .5em 0;
	padding: 1em 1em 1em .5em;
	position: relative;
	display: flex
}

.ui-messages .ui-messages-icon {
	display: inline-block;
	padding: 0;
	vertical-align: middle;
	font-size: 2em
}

.ui-messages .ui-messages-summary {
	font-weight: bold;
	margin-left: .25em
}

.ui-messages .ui-messages-detail {
	margin-left: .25em
}

.ui-messages ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: inline-block;
	vertical-align: middle;
	display: flex;
	align-items: center
}

.ui-messages ul > li {
	padding: .25em
}

.ui-messages.ui-messages-noicon ul {
	margin: 0 1.5em 0 0
}

.ui-messages .ui-messages-close {
	cursor: pointer;
	position: absolute;
	top: 5px;
	right: 5px
}

.ui-orderlist {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.ui-orderlist-controls-left {
	flex-direction: row
}

.ui-orderlist-controls-right {
	flex-direction: row-reverse
}

.ui-orderlist-controls, .ui-orderlist-list-container {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto
}

.ui-orderlist-controls {
	padding: 0 .25em;
	width: 16.66666%;
	align-self: center
}

.ui-orderlist-controls .ui-button.ui-button-icon-only {
	display: block;
	margin-bottom: .25em;
	width: 100%
}

.ui-orderlist-list-container {
	width: 83.33333%
}

.ui-orderlist-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: auto;
	height: 12.5em
}

.ui-orderlist-caption {
	text-align: center;
	padding: .5em .75em;
	border-bottom: 0 none
}

.ui-orderlist-item {
	margin: 1px;
	padding: .125em;
	cursor: pointer;
	border: 0 none;
	font-weight: inherit
}

.ui-orderlist-filter-container {
	position: relative;
	width: 100%;
	padding: .5em .6em;
	border-bottom: 0 none
}

.ui-orderlist-filter-container .ui-inputtext {
	text-indent: 1.1em;
	width: 100%
}

.ui-orderlist-filter-container .ui-orderlist-filter-icon {
	position: absolute;
	top: 50%;
	left: 1em;
	margin-top: -.6em
}

.ui-orderlist.ui-state-disabled .ui-orderlist-item, .ui-orderlist.ui-state-disabled .ui-button {
	cursor: default
}

.ui-orderlist.ui-state-disabled .ui-orderlist-list {
	overflow: hidden
}

.ui-orderlist .ui-orderlist-droppoint {
	height: 6px;
	list-style-type: none
}

@media (max-width: 767px) {
	.ui-orderlist-controls {
		width: 100%;
		text-align: center
	}

	.ui-orderlist .ui-orderlist-list-container {
		width: 100%
	}

	.ui-orderlist .ui-orderlist-controls .ui-button.ui-button.ui-button-icon-only {
		display: inline-block;
		width: 20%;
		margin-right: .25em
	}
}

.ui-multiselect {
	display: inline-block;
	position: relative;
	width: auto;
	cursor: pointer
}

.ui-multiselect .ui-multiselect-trigger {
	border-right: 0;
	border-top: 0;
	border-bottom: 0;
	cursor: pointer;
	width: 1.5em;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	padding: 0 .25em
}

.ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon {
	top: 50%;
	left: 50%;
	margin-top: -.5em;
	margin-left: -.5em;
	position: absolute
}

.ui-multiselect .ui-multiselect-label-container {
	overflow: hidden
}

.ui-multiselect .ui-multiselect-label {
	display: block;
	padding: .25em 2em .25em .25em;
	width: auto;
	border: 0;
	cursor: pointer;
	text-overflow: ellipsis;
	overflow: hidden
}

.ui-multiselect.ui-state-disabled .ui-multiselect-trigger, .ui-multiselect.ui-state-disabled .ui-multiselect-label {
	cursor: auto
}

.ui-multiselect-panel {
	padding: .2em;
	position: absolute;
	min-width: 12em
}

.ui-multiselect .ui-multiselect-panel {
	min-width: 100%
}

.ui-multiselect-panel .ui-multiselect-items-wrapper {
	overflow: auto;
	position: relative;
	padding: .2em 0
}

.ui-multiselect-panel .ui-multiselect-list {
	border: 0 none
}

.ui-multiselect-panel .ui-multiselect-item {
	border: 0 none;
	cursor: pointer;
	font-weight: normal;
	margin: 1px 0;
	padding: .125em .25em;
	text-align: left;
	white-space: nowrap;
	display: block;
	position: relative
}

.ui-multiselect-panel .ui-multiselect-empty-message {
	padding: .5em
}

.ui-multiselect-panel .ui-multiselect-item .ui-chkbox {
	display: inline-block;
	vertical-align: middle
}

.ui-multiselect-panel .ui-multiselect-item span {
	display: inline-block;
	vertical-align: middle
}

.ui-multiselect-header {
	margin-bottom: .3em;
	padding: .25em;
	position: relative;
	text-align: left;
	min-height: 2em
}

.ui-multiselect-header .ui-chkbox {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer
}

.ui-multiselect-header .ui-multiselect-filter-container {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 65%
}

.ui-multiselect-header.ui-multiselect-header-no-toggleall .ui-multiselect-filter-container {
	width: 85%
}

.ui-multiselect-header .ui-multiselect-filter-container .ui-multiselect-filter-icon {
	position: absolute;
	top: .25em;
	left: .125em
}

.ui-multiselect-header .ui-inputtext {
	padding: .125em .125em .125em 1.25em;
	width: 100%
}

.ui-multiselect-header .ui-multiselect-close {
	position: absolute;
	right: .375em;
	top: .375em;
	display: block;
	border: 0 none;
	cursor: pointer
}

.ui-multiselect-header a.ui-multiselect-all, .ui-multiselect-header a.ui-multiselect-none {
	float: left;
	margin-right: 10px;
	display: block
}

.ui-multiselect-footer {
	padding: .25em
}

.ui-fluid .ui-multiselect {
	width: 100%;
	box-sizing: border-box
}

.ui-organizationchart .ui-organizationchart-table {
	border-spacing: 0;
	border-collapse: separate;
	margin: 0 auto
}

.ui-organizationchart .ui-organizationchart-table > tr > td {
	text-align: center;
	vertical-align: top;
	padding: 0;
	padding: 0 .75em
}

.ui-organizationchart .ui-organizationchart-node-content {
	padding: .5em .75em;
	display: inline-block;
	position: relative
}

.ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler {
	position: absolute;
	bottom: -9px;
	margin-left: -8px;
	z-index: 2;
	left: 50%
}

.ui-organizationchart .ui-organizationchart-line-down {
	margin: 0 auto;
	height: 20px;
	width: 1px;
	float: none
}

.ui-organizationchart .ui-organizationchart-line-right {
	float: none;
	border-radius: 0
}

.ui-organizationchart .ui-organizationchart-line-left {
	float: none;
	border-radius: 0
}

.ui-organizationchart .ui-organizationchart-node-content.ui-organizationchart-selectable-node {
	cursor: pointer
}

.ui-overlaypanel {
	padding: 0;
	margin: 0;
	position: absolute;
	margin-top: 10px
}

.ui-overlaypanel-flipped {
	margin-top: 0;
	margin-bottom: 10px
}

.ui-overlaypanel-content {
	padding: .5em 1em
}

.ui-overlaypanel-close {
	position: absolute;
	top: -1em;
	right: -1em;
	width: 2em;
	height: 2em;
	line-height: 2em;
	text-align: center;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	border-radius: 100%;
	cursor: pointer
}

.ui-overlaypanel-close-icon {
	line-height: inherit
}

.ui-overlaypanel:after, .ui-overlaypanel:before {
	bottom: 100%;
	left: 1.25em;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none
}

.ui-overlaypanel:after {
	border-width: 8px;
	margin-left: -8px
}

.ui-overlaypanel:before {
	border-width: 10px;
	margin-left: -10px
}

.ui-overlaypanel-shifted:after, .ui-overlaypanel-shifted:before {
	left: auto;
	right: 1.25em;
	margin-left: auto
}

.ui-overlaypanel-shifted:after {
	margin-right: -8px
}

.ui-overlaypanel:before {
	margin-right: -10px
}

.ui-overlaypanel-flipped:after, .ui-overlaypanel-flipped:before {
	bottom: auto;
	top: 100%
}

.ui-overlaypanel.ui-overlaypanel-flipped:after {
	border-bottom-color: transparent
}

.ui-overlaypanel.ui-overlaypanel-flipped:before {
	border-bottom-color: transparent
}

.ui-paginator {
	margin: 0;
	text-align: center;
	padding: .125em
}

.ui-paginator .ui-paginator-top {
	border-bottom: 0 none
}

.ui-paginator .ui-paginator-bottom {
	border-top: 0 none
}

.ui-paginator .ui-paginator-left-content {
	float: left
}

.ui-paginator .ui-paginator-right-content {
	float: right
}

.ui-paginator .ui-paginator-page, .ui-paginator .ui-paginator-pages, .ui-paginator .ui-paginator-next, .ui-paginator .ui-paginator-last, .ui-paginator .ui-paginator-first, .ui-paginator .ui-paginator-prev, .ui-paginator .ui-paginator-current {
	display: inline-block;
	min-width: 1.5em;
	height: 1.5em;
	line-height: 1.5em;
	zoom: 1;
	margin-left: .063em;
	margin-right: .063em;
	text-decoration: none;
	vertical-align: middle;
	text-align: center;
	position: relative
}

.ui-paginator .ui-paginator-pages {
	width: auto;
	line-height: 1
}

.ui-paginator .ui-paginator-icon {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 1em;
	height: 1em;
	margin-top: -.5em;
	margin-left: -.5em
}

.ui-paginator .ui-paginator-page, .ui-paginator .ui-paginator-next, .ui-paginator .ui-paginator-last, .ui-paginator .ui-paginator-first, .ui-paginator .ui-paginator-prev {
	cursor: pointer
}

.ui-paginator .ui-paginator-current, .ui-paginator .ui-paginator-rpp-options {
	margin-left: 1em;
	margin-right: 1em;
	background-image: none
}

.ui-paginator .ui-paginator-jtp-select option, .ui-paginator .ui-paginator-rpp-options option {
	background-image: none;
	border: 0 none;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none
}

.ui-paginator a.ui-state-disabled {
	outline: 0 none
}

.ui-paginator .ui-dropdown {
	min-width: 4em;
	margin-left: .375em
}

.ui-fluid .ui-paginator .ui-dropdown {
	width: auto
}

.ui-panel {
	padding: .2em
}

.ui-panel .ui-panel-titlebar {
	padding: .5em .75em
}

.ui-panel .ui-panel-titlebar.ui-panel-titlebar-clickable {
	cursor: pointer
}

.ui-panel .ui-panel-titlebar-icon {
	float: right;
	cursor: pointer
}

.ui-panel .ui-panel-titlebar-icon {
	margin-left: .2em;
	margin-top: -0.1em
}

.ui-panel .ui-panel-content {
	border: 0;
	background: 0;
	padding: .5em .75em
}

.ui-panel .ui-panel-footer {
	border-width: 1px 0 0;
	padding: .25em .5em;
	text-align: left
}

.ui-panel-content-wrapper-overflown {
	overflow: hidden
}

.ui-panelmenu {
	width: auto
}

.ui-panelmenu .ui-menu-separator {
	border-width: 1px 0 0 0
}

.ui-panelmenu .ui-panelmenu-content-wrapper {
	overflow: hidden
}

.ui-panelmenu .ui-panelmenu-header {
	margin: -1px 0 0 0;
	zoom: 1
}

.ui-panelmenu .ui-panelmenu-header-link {
	padding: .5em;
	display: block;
	text-decoration: none
}

.ui-panelmenu .ui-panelmenu-icon {
	vertical-align: middle
}

.ui-panelmenu .ui-menuitem-text {
	vertical-align: middle
}

.ui-panelmenu .ui-menuitem-icon {
	margin-right: .25em;
	vertical-align: middle
}

.ui-panelmenu .ui-panelmenu-content {
	padding: .25em;
	border-top: 0;
	margin-bottom: 1px
}

.ui-panelmenu .ui-submenu-list {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-left: 1.5em
}

.ui-panelmenu .ui-panelmenu-content > .ui-panelmenu-root-submenu > .ui-submenu-list {
	margin-left: 0
}

.ui-panelmenu .ui-menuitem {
	overflow: hidden;
	margin: .125em 0
}

.ui-panelmenu .ui-menuitem-link {
	padding: .25em;
	display: block;
	text-decoration: none
}

.ui-password-panel {
	padding: .25em .5em;
	position: absolute
}

.ui-password-panel .ui-password-meter {
	height: 10px;
	background: transparent url("./images/password-meter.png") no-repeat left top;
	padding: 0;
	margin: 0
}

.ui-password-info {
	margin-top: .25em
}

.ui-password-panel {
	-webkit-transform: translateY(5%);
	-ms-transform: translateY(5%);
	transform: translateY(5%);
	opacity: 0;
	-webkit-transition: transform .3s, opacity .3s;
	transition: transform .3s, opacity .3s
}

.ui-password-panel-visible {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1
}

.ui-password-panel-hidden {
	opacity: 0;
	-webkit-transform: translateY(5%);
	-ms-transform: translateY(5%);
	transform: translateY(5%);
	-webkit-transition: transform .3s, opacity .15s;
	transition: transform .3s, opacity .15s
}

.ui-picklist > div {
	float: left
}

.ui-picklist .ui-picklist-buttons {
	height: 12.5em;
	padding: 0 .25em
}

.ui-picklist .ui-picklist-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: auto;
	height: 12.5em;
	width: 12.5em
}

.ui-picklist .ui-picklist-list li {
	margin: 1px;
	padding: .125em
}

.ui-picklist .ui-button {
	display: block;
	margin-bottom: .25em
}

.ui-picklist .ui-button-text-icon-left {
	width: 100%
}

.ui-picklist .ui-picklist-item {
	cursor: pointer;
	border: 0 none;
	font-weight: inherit
}

.ui-picklist .ui-picklist-caption {
	text-align: center;
	padding: .5em .75em;
	border-bottom: 0 none
}

.ui-picklist table {
	width: 100%;
	border-collapse: collapse
}

.ui-picklist .ui-picklist-filter-container {
	position: relative;
	width: 100%;
	padding: .5em .6em;
	border-bottom: 0 none
}

.ui-picklist .ui-picklist-filter-container .ui-picklist-filter {
	text-indent: 1.1em;
	width: 100%
}

.ui-picklist .ui-picklist-filter-container .ui-picklist-filter-icon {
	position: absolute;
	top: 50%;
	left: 1em;
	margin-top: -.6em
}

.ui-picklist {
	display: table
}

.ui-picklist > div {
	float: none;
	display: table-cell;
	vertical-align: top
}

.ui-picklist .ui-picklist-buttons {
	vertical-align: middle
}

.ui-picklist.ui-picklist-vertical {
	display: table
}

.ui-picklist.ui-picklist-vertical > div {
	float: none;
	display: table-row;
	vertical-align: top
}

.ui-picklist.ui-picklist-vertical .ui-picklist-buttons {
	text-align: center;
	height: auto
}

.ui-picklist.ui-picklist-vertical .ui-picklist-buttons .ui-button {
	display: inline-block
}

.ui-picklist.ui-picklist-vertical .ui-button {
	margin-top: .25em
}

.ui-picklist-outline {
	outline: 1px dotted black;
	z-index: 1
}

.ui-picklist .ui-picklist-droppoint {
	height: 6px;
	list-style-type: none
}

.ui-picklist .ui-picklist-list .ui-picklist-droppoint-empty {
	height: 100%;
	list-style-type: none
}

.ui-picklist-list.ui-picklist-source, .ui-picklist-list.ui-picklist-target {
	outline: 0
}

.ui-picklist.ui-picklist-responsive * {
	box-sizing: border-box
}

.ui-picklist.ui-picklist-responsive {
	width: 100%
}

.ui-picklist.ui-picklist-responsive .ui-picklist-listwrapper {
	width: 35%
}

.ui-picklist.ui-picklist-responsive .ui-picklist-listwrapper.ui-picklist-listwrapper-nocontrols {
	width: 45%
}

.ui-picklist.ui-picklist-responsive .ui-picklist-buttons {
	width: 10%
}

.ui-picklist.ui-picklist-responsive .ui-picklist-buttons button {
	width: 100%
}

.ui-picklist.ui-picklist-responsive .ui-picklist-list {
	width: auto
}

@media (max-width: 40em) {
	.ui-picklist.ui-picklist-responsive {
		display: block
	}

	.ui-picklist.ui-picklist-responsive > div {
		display: block;
		width: 100% !important
	}

	.ui-picklist.ui-picklist-responsive .ui-picklist-buttons {
		text-align: center;
		height: auto;
		padding: .4em 0
	}

	.ui-picklist.ui-picklist-responsive .ui-picklist-buttons button {
		display: inline-block;
		width: 20%;
		margin-bottom: 0;
		margin-right: .25em
	}

	.ui-picklist.ui-picklist-responsive .ui-picklist-source-controls.ui-picklist-buttons {
		padding-bottom: .4em
	}

	.ui-picklist.ui-picklist-responsive .ui-picklist-target-controls.ui-picklist-buttons {
		padding-top: .4em
	}

	.ui-picklist.ui-picklist-responsive .ui-picklist-buttons .pi-angle-right::before {
		content: "\e930"
	}

	.ui-picklist.ui-picklist-responsive .ui-picklist-buttons .pi-angle-double-right::before {
		content: "\e92c"
	}

	.ui-picklist.ui-picklist-responsive .ui-picklist-buttons .pi-angle-left::before {
		content: "\e933"
	}

	.ui-picklist.ui-picklist-responsive .ui-picklist-buttons .pi-angle-double-left::before {
		content: "\e92f"
	}
}

.ui-progressbar {
	height: 1.2em;
	text-align: left;
	position: relative;
	overflow: hidden
}

.ui-progressbar-determinate .ui-progressbar-value {
	height: 100%;
	width: 0;
	position: absolute;
	display: none;
	border: 0 none
}

.ui-progressbar-determinate .ui-progressbar-value-animate {
	-webkit-transition: width 1s ease-in-out;
	-moz-transition: width 1s ease-in-out;
	-o-transition: width 1s ease-in-out;
	transition: width 1s ease-in-out
}

.ui-progressbar-determinate .ui-progressbar-label {
	text-align: center;
	height: 100%;
	width: 100%;
	position: absolute;
	display: none;
	font-weight: bold
}

.ui-progressbar-indeterminate {
	height: .5em
}

.ui-progressbar-indeterminate .ui-progressbar-value {
	border: 0 none
}

.ui-progressbar-indeterminate .ui-progressbar-value::before {
	content: '';
	position: absolute;
	background-color: inherit;
	top: 0;
	left: 0;
	bottom: 0;
	will-change: left, right;
	-webkit-animation: ui-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
	animation: ui-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite
}

.ui-progressbar-indeterminate .ui-progressbar-value::after {
	content: '';
	position: absolute;
	background-color: inherit;
	top: 0;
	left: 0;
	bottom: 0;
	will-change: left, right;
	-webkit-animation: ui-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
	animation: ui-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
	-webkit-animation-delay: 1.15s;
	animation-delay: 1.15s
}

@-webkit-keyframes ui-progressbar-indeterminate-anim {
	0% {
		left: -35%;
		right: 100%
	}
	60% {
		left: 100%;
		right: -90%
	}
	100% {
		left: 100%;
		right: -90%
	}
}

@keyframes ui-progressbar-indeterminate-anim {
	0% {
		left: -35%;
		right: 100%
	}
	60% {
		left: 100%;
		right: -90%
	}
	100% {
		left: 100%;
		right: -90%
	}
}

@-webkit-keyframes ui-progressbar-indeterminate-anim-short {
	0% {
		left: -200%;
		right: 100%
	}
	60% {
		left: 107%;
		right: -8%
	}
	100% {
		left: 107%;
		right: -8%
	}
}

@keyframes ui-progressbar-indeterminate-anim-short {
	0% {
		left: -200%;
		right: 100%
	}
	60% {
		left: 107%;
		right: -8%
	}
	100% {
		left: 107%;
		right: -8%
	}
}

.ui-progress-spinner {
	position: relative;
	margin: 0 auto;
	width: 100px;
	height: 100px;
	display: inline-block
}

.ui-progress-spinner::before {
	content: '';
	display: block;
	padding-top: 100%
}

.ui-progress-spinner-svg {
	animation: ui-progress-spinner-rotate 2s linear infinite;
	height: 100%;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto
}

.ui-progress-spinner-circle {
	stroke-dasharray: 89, 200;
	stroke-dashoffset: 0;
	stroke: #d62d20;
	animation: ui-progress-spinner-dash 1.5s ease-in-out infinite, ui-progress-spinner-color 6s ease-in-out infinite;
	stroke-linecap: round
}

@keyframes ui-progress-spinner-rotate {
	100% {
		transform: rotate(360deg)
	}
}

@keyframes ui-progress-spinner-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px
	}
}

@keyframes ui-progress-spinner-color {
	100%, 0% {
		stroke: #d62d20
	}
	40% {
		stroke: #0057e7
	}
	66% {
		stroke: #008744
	}
	80%, 90% {
		stroke: #ffa700
	}
}

.ui-radiobutton {
	display: inline-block;
	cursor: pointer;
	vertical-align: middle;
	margin-right: .25em;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none
}

.ui-radiobutton-box {
	width: 1.125em;
	height: 1.125em;
	line-height: 1.125em;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	border-radius: 100%;
	text-align: center;
	position: relative
}

.ui-radiobutton-icon {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 1em;
	height: 1em;
	margin-top: -.5em;
	margin-left: -.5em
}

.ui-radiobutton {
	vertical-align: middle
}

.ui-rating {
	font-size: 1.25em
}

.ui-fluid .fc .ui-button {
	width: auto
}

.ui-scrollpanel-wrapper {
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
	float: left
}

.ui-scrollpanel-content {
	height: calc(100% + 18px);
	width: calc(100% + 18px);
	padding: 0 18px 18px 0;
	position: relative;
	overflow: auto;
	box-sizing: border-box
}

.ui-scrollpanel-bar {
	position: relative;
	background: #c1c1c1;
	border-radius: 3px;
	z-index: 2;
	cursor: pointer;
	opacity: 0;
	transition: opacity .25s linear
}

.ui-scrollpanel-bar-y {
	width: 9px;
	top: 0
}

.ui-scrollpanel-bar-x {
	height: 9px;
	bottom: 0
}

.ui-scrollpanel-hidden {
	visibility: hidden
}

.ui-scrollpanel:hover .ui-scrollpanel-bar, .ui-scrollpanel:active .ui-scrollpanel-bar {
	opacity: 1
}

.ui-scrollpanel-grabbed {
	-o-user-select: none;
	-ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	user-select: none
}

.ui-selectbutton {
	display: inline-block
}

.ui-selectbutton.ui-state-error {
	padding: 0
}

.ui-selectbutton .ui-button.ui-state-focus {
	outline: 0
}

.ui-sidebar {
	position: fixed;
	padding: .5em 1em;
	-webkit-transition: transform .3s;
	transition: transform .3s
}

.ui-sidebar-left {
	top: 0;
	left: 0;
	width: 20em;
	height: 100%;
	-webkit-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%)
}

.ui-sidebar-right {
	top: 0;
	right: 0;
	width: 20em;
	height: 100%;
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%)
}

.ui-sidebar-top {
	top: 0;
	left: 0;
	width: 100%;
	height: 10em;
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%)
}

.ui-sidebar-bottom {
	bottom: 0;
	left: 0;
	width: 100%;
	height: 10em;
	-webkit-transform: translateY(100%);
	-ms-transform: translateY(100%);
	transform: translateY(100%)
}

.ui-sidebar-full {
	width: 100%;
	height: 100%;
	left: 0;
	-webkit-transition: transform 0s;
	transition: transform 0s
}

.ui-sidebar-left.ui-sidebar-active, .ui-sidebar-right.ui-sidebar-active {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0)
}

.ui-sidebar-left.ui-sidebar-sm, .ui-sidebar-right.ui-sidebar-sm {
	width: 20em
}

.ui-sidebar-left.ui-sidebar-md, .ui-sidebar-right.ui-sidebar-md {
	width: 40em
}

.ui-sidebar-left.ui-sidebar-lg, .ui-sidebar-right.ui-sidebar-lg {
	width: 60em
}

.ui-sidebar-top.ui-sidebar-active, .ui-sidebar-bottom.ui-sidebar-active {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0)
}

.ui-sidebar-top.ui-sidebar-sm, .ui-sidebar-bottom.ui-sidebar-sm {
	height: 10em
}

.ui-sidebar-top.ui-sidebar-md, .ui-sidebar-bottom.ui-sidebar-md {
	height: 20em
}

.ui-sidebar-top.ui-sidebar-lg, .ui-sidebar-bottom.ui-sidebar-lg {
	height: 30em
}

.ui-sidebar-mask {
	position: fixed;
	width: 100%;
	height: 100%
}

.ui-sidebar-close {
	float: right
}

.ui-sidebar-close {
	cursor: pointer
}

@media screen and (max-width: 64em) {
	.ui-sidebar-left.ui-sidebar-lg, .ui-sidebar-left.ui-sidebar-md, .ui-sidebar-right.ui-sidebar-lg, .ui-sidebar-right.ui-sidebar-md {
		width: 20em
	}
}

.ui-slidemenu {
	width: 12.5em;
	padding: .25em
}

.ui-slidemenu.ui-slidemenu-dynamic {
	position: absolute
}

.ui-slidemenu .ui-menu-separator {
	border-width: 1px 0 0 0
}

.ui-slidemenu ul {
	list-style: none;
	margin: 0;
	padding: 0
}

.ui-slidemenu .ui-slidemenu-rootlist {
	position: absolute;
	top: 0
}

.ui-slidemenu .ui-submenu-list {
	display: none;
	position: absolute;
	top: 0;
	width: 12.5em;
	padding: .25em
}

.ui-slidemenu .ui-menuitem-link {
	padding: .25em;
	display: block;
	position: relative;
	text-decoration: none
}

.ui-slidemenu .ui-menuitem-icon {
	margin-right: .25em;
	vertical-align: middle
}

.ui-slidemenu .ui-menuitem-text {
	vertical-align: middle
}

.ui-slidemenu .ui-menuitem {
	position: relative;
	margin: .125em 0
}

.ui-slidemenu .ui-menuitem-link .ui-submenu-icon {
	position: absolute;
	margin-top: -.5em;
	right: 0;
	top: 50%
}

.ui-slidemenu .ui-slidemenu-wrapper {
	position: relative
}

.ui-slidemenu .ui-slidemenu-content {
	overflow-x: hidden;
	overflow-y: auto;
	position: relative
}

.ui-slidemenu-backward {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: .25em;
	cursor: pointer;
	display: none
}

.ui-slidemenu-backward .ui-slidemenu-backward-icon {
	vertical-align: middle
}

.ui-slidemenu-backward span {
	vertical-align: middle
}

.ui-slidemenu .ui-menuitem-active {
	position: static
}

.ui-slidemenu .ui-menuitem-active > .ui-submenu > .ui-submenu-list {
	display: block
}

.ui-slidemenu ul:not(.ui-active-submenu) > .ui-menuitem:not(.ui-menuitem-active), .ui-slidemenu .ui-active-submenu > .ui-menuitem-active > .ui-submenu > .ui-submenu-list {
	display: none
}

.ui-slidemenu .ui-active-submenu > .ui-menuitem-active ~ .ui-menuitem {
	display: block
}

.ui-spinner {
	display: inline-block;
	overflow: visible;
	padding: 0;
	position: relative;
	vertical-align: middle
}

.ui-spinner-input {
	vertical-align: middle;
	padding-right: 1.5em
}

.ui-spinner-button {
	cursor: default;
	display: block;
	height: 50%;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	right: 0;
	text-align: center;
	vertical-align: middle;
	width: 1.5em
}

.ui-spinner .ui-spinner-button-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -.5em;
	margin-left: -.5em;
	width: 1em
}

.ui-spinner-up {
	top: 0
}

.ui-spinner-down {
	bottom: 0
}

.ui-fluid .ui-spinner {
	width: 100%
}

.ui-fluid .ui-spinner .ui-spinner-input {
	padding-right: 2em;
	width: 100%
}

.ui-fluid .ui-spinner .ui-spinner-button {
	width: 1.5em
}

.ui-fluid .ui-spinner .ui-spinner-button .ui-spinner-button-icon {
	left: .7em
}

.ui-splitbutton {
	position: relative;
	display: inline-block;
	zoom: 1
}

.ui-splitbutton .ui-button.ui-splitbutton-menubutton {
	width: 2em;
	vertical-align: top
}

.ui-splitbutton.ui-state-disabled button {
	cursor: default
}

.ui-fluid .ui-splitbutton {
	width: 100%
}

.ui-fluid .ui-splitbutton .ui-button:first-child {
	width: calc(100% - 2em)
}

.ui-fluid .ui-splitbutton .ui-button.ui-splitbutton-menubutton {
	width: 2em
}

.ui-splitbutton .ui-menuitem-link {
	cursor: pointer
}

.ui-slider {
	position: relative;
	text-align: left
}

.ui-slider .ui-slider-handle {
	position: absolute;
	width: 1.2em;
	height: 1.2em;
	cursor: default;
	-ms-touch-action: none;
	touch-action: none;
	z-index: 1
}

.ui-slider .ui-slider-handle.ui-slider-handle-active {
	z-index: 2
}

.ui-slider .ui-slider-range {
	position: absolute;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0
}

.ui-slider-horizontal {
	height: .8em
}

.ui-slider-horizontal .ui-slider-handle {
	top: -.25em;
	margin-left: -.6em
}

.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%
}

.ui-slider-horizontal .ui-slider-range-min {
	left: 0
}

.ui-slider-horizontal .ui-slider-range-max {
	right: 0
}

.ui-slider-vertical {
	width: .8em;
	height: 100px
}

.ui-slider-vertical .ui-slider-handle {
	left: -.25em;
	margin-left: 0;
	margin-bottom: -.6em
}

.ui-slider-vertical .ui-slider-range {
	left: 0;
	width: 100%
}

.ui-slider-vertical .ui-slider-range-min {
	bottom: 0
}

.ui-slider-vertical .ui-slider-range-max {
	top: 0
}

.ui-slider-animate .ui-slider-handle {
	transition: left .3s
}

.ui-steps ul {
	list-style-type: none;
	padding: 0;
	margin: 0
}

.ui-steps .ui-steps-item {
	float: left;
	box-sizing: border-box;
	cursor: pointer
}

.ui-steps.ui-steps-readonly .ui-steps-item {
	cursor: auto
}

.ui-steps .ui-steps-item .ui-menuitem-link {
	text-decoration: none;
	display: block;
	padding: 1em;
	position: relative;
	text-align: center
}

.ui-steps .ui-steps-item.ui-state-highlight .ui-menuitem-link, .ui-steps .ui-steps-item.ui-state-disabled .ui-menuitem-link {
	cursor: default
}

.ui-steps .ui-steps-number {
	font-size: 2em;
	display: block
}

.ui-steps .ui-steps-title {
	display: block;
	white-space: nowrap
}

@media (max-width: 40em) {
	.ui-steps .ui-steps-item .ui-menuitem-link {
		padding: .5em
	}

	.ui-steps .ui-steps-item .ui-steps-title {
		display: none
	}
}

.ui-table {
	position: relative
}

.ui-table table {
	border-collapse: collapse;
	width: 100%;
	table-layout: fixed
}

.ui-table .ui-table-thead > tr > th, .ui-table .ui-table-tbody > tr > td, .ui-table .ui-table-tfoot > tr > td {
	padding: .25em .5em
}

.ui-table .ui-sortable-column {
	cursor: pointer
}

.ui-table p-sorticon {
	vertical-align: middle
}

.ui-table-auto-layout > .ui-table-wrapper {
	overflow-x: auto
}

.ui-table-auto-layout > .ui-table-wrapper > table {
	table-layout: auto
}

.ui-table-caption, .ui-table-summary {
	padding: .25em .5em;
	text-align: center;
	font-weight: bold
}

.ui-table-caption {
	border-bottom: 0 none
}

.ui-table-summary {
	border-top: 0 none
}

.ui-table .ui-paginator-top {
	border-bottom: 0 none
}

.ui-table .ui-paginator-bottom {
	border-top: 0 none
}

.ui-table-scrollable-wrapper {
	position: relative
}

.ui-table-scrollable-header, .ui-table-scrollable-footer {
	overflow: hidden;
	border: 0 none
}

.ui-table-scrollable-body {
	overflow: auto;
	position: relative
}

.ui-table-virtual-table {
	position: absolute
}

.ui-table-loading-virtual-table {
	display: none
}

.ui-table-frozen-view .ui-table-scrollable-body {
	overflow: hidden
}

.ui-table-frozen-view > .ui-table-scrollable-body > table > .ui-table-tbody > tr > td:last-child {
	border-right: 0 none
}

.ui-table-unfrozen-view {
	position: absolute;
	top: 0
}

.ui-table-resizable > .ui-table-wrapper {
	overflow-x: auto
}

.ui-table-resizable .ui-table-thead > tr > th, .ui-table-resizable .ui-table-tfoot > tr > td, .ui-table-resizable .ui-table-tbody > tr > td {
	overflow: hidden
}

.ui-table-resizable .ui-resizable-column {
	background-clip: padding-box;
	position: relative
}

.ui-table-resizable-fit .ui-resizable-column:last-child .ui-column-resizer {
	display: none
}

.ui-table .ui-column-resizer {
	display: block;
	position: absolute !important;
	top: 0;
	right: 0;
	margin: 0;
	width: .5em;
	height: 100%;
	padding: 0;
	cursor: col-resize;
	border: 1px solid transparent
}

.ui-table .ui-column-resizer-helper {
	width: 1px;
	position: absolute;
	z-index: 10;
	display: none
}

.ui-table .ui-table-tbody > tr > td.ui-editing-cell {
	padding: 0
}

.ui-table .ui-table-tbody > tr > td.ui-editing-cell p-celleditor > * {
	width: 100%
}

.ui-table-reorder-indicator-up, .ui-table-reorder-indicator-down {
	position: absolute;
	display: none
}

.ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
	display: none
}

@media screen and (max-width: 40em) {
	.ui-table-responsive .ui-table-thead > tr > th, .ui-table-responsive .ui-table-tfoot > tr > td {
		display: none !important
	}

	.ui-table-responsive colgroup {
		display: none !important
	}

	.ui-table-responsive .ui-table-tbody > tr > td {
		text-align: left;
		display: block;
		border: 0 none;
		width: 100% !important;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		float: left;
		clear: left
	}

	.ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
		padding: .4em;
		min-width: 30%;
		display: inline-block;
		margin: -.4em 1em -.4em -.4em;
		font-weight: bold
	}
}

.ui-table-loading {
	position: absolute;
	width: 100%;
	height: 100%;
	-ms-filter: "alpha(opacity=10)";
	opacity: .1;
	z-index: 1
}

.ui-table-loading-content {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 2;
	margin-top: -1em;
	margin-left: -1em
}

.ui-table .ui-table-loading-icon {
	font-size: 2em
}

.ui-tabview {
	padding: .25em
}

.ui-tabview .ui-tabview-nav {
	margin: 0
}

.ui-tabview .ui-tabview-nav li {
	list-style: none;
	float: left;
	position: relative;
	margin: 0 .125em 1px 0;
	padding: 0;
	white-space: nowrap
}

.ui-tabview .ui-tabview-nav li a {
	float: left;
	padding: .5em 1em;
	text-decoration: none
}

.ui-tabview .ui-tabview-nav li.ui-tabview-selected a, .ui-tabview .ui-tabview-nav li.ui-state-disabled a, .ui-tabview .ui-tabview-nav li.ui-state-processing a {
	cursor: text
}

.ui-tabview .ui-tabview-nav li a, .ui-tabview.ui-tabview-collapsible .ui-tabview-nav li.ui-tabview-selected a {
	cursor: pointer
}

.ui-tabview .ui-tabview-panel {
	border-width: 0;
	padding: 1em;
	background: 0
}

.ui-tabview .ui-tabview-nav li {
	display: block
}

.ui-tabview .ui-tabview-nav li .ui-tabview-left-icon {
	margin-right: .25em;
	vertical-align: middle
}

.ui-tabview .ui-tabview-nav li .ui-tabview-right-icon {
	margin-left: .25em;
	vertical-align: middle
}

.ui-tabview .ui-tabview-title {
	vertical-align: middle
}

.ui-tabview .ui-tabview-nav li .ui-tabview-close {
	margin: .5em .3em 0 0;
	cursor: pointer
}

.ui-tabview.ui-tabview-top > .ui-tabview-nav li {
	border-bottom: 0;
	top: 1px
}

.ui-tabview.ui-tabview-top > .ui-tabview-nav {
	padding: .2em .2em 0
}

.ui-tabview.ui-tabview-bottom > .ui-tabview-nav {
	padding: 0 .2em .2em
}

.ui-tabview.ui-tabview-bottom > .ui-tabview-nav li {
	border-top: 0
}

.ui-tabview-left::after, .ui-tabview-right::after {
	clear: both;
	content: ".";
	display: block;
	height: 0;
	visibility: hidden
}

.ui-tabview-left > .ui-tabview-nav {
	float: left;
	width: 25%;
	height: 300px;
	background-image: none;
	padding-top: 1px
}

.ui-tabview-left > .ui-tabview-panels {
	float: right;
	width: 75%
}

.ui-tabview.ui-tabview-left > .ui-tabview-nav li, .ui-tabview.ui-tabview-right > .ui-tabview-nav li {
	display: block;
	float: right;
	white-space: normal;
	width: 99%
}

.ui-tabview.ui-tabview-left > .ui-tabview-nav li {
	margin: 0 0 1px 0;
	border-right: 0 none
}

.ui-tabview.ui-tabview-right > .ui-tabview-nav {
	float: right;
	width: 25%;
	height: 300px;
	background-image: none;
	padding-top: 1px
}

.ui-tabview.ui-tabview-right > .ui-tabview-panels {
	float: left;
	width: 75%
}

.ui-tabview.ui-tabview-right > .ui-tabview-nav li {
	margin: 0 0 1px 0;
	border-left: 0 none
}

.ui-rtl .ui-tabview .ui-tabview-nav li {
	float: right
}

.ui-terminal {
	height: 18em;
	overflow: auto;
	padding: .25em
}

.ui-terminal-input {
	border: 0 none;
	background-color: transparent;
	color: inherit;
	padding: 0;
	margin: 0 0 0 .125em;
	width: 75%;
	outline: 0;
	vertical-align: baseline
}

.ui-terminal-command {
	margin-left: .125em;
	-moz-margin-start: .125em
}

.ui-terminal-input::-ms-clear {
	display: none
}

.ui-tabmenu .ui-tabmenu-nav {
	margin: 0;
	padding: .25em .5em 0 .25em
}

.ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem {
	list-style: none;
	float: left;
	position: relative;
	margin: 0 .2em 1px 0;
	padding: 0;
	white-space: nowrap;
	display: block;
	border-bottom: 0;
	top: 1px
}

.ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem a {
	float: left;
	padding: .5em 1em;
	text-decoration: none
}

.ui-tabmenu .ui-tabmenu-nav a {
	padding: .5em 1em
}

.ui-tabmenu .ui-menuitem-icon {
	margin-right: .25em;
	vertical-align: middle
}

.ui-tabmenu .ui-menuitem-text {
	vertical-align: middle
}

.ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-disabled a {
	cursor: default
}

.ui-tieredmenu {
	width: 12.5em;
	padding: .25em
}

.ui-tieredmenu.ui-tieredmenu-dynamic {
	position: absolute
}

.ui-tieredmenu .ui-menu-separator {
	border-width: 1px 0 0 0
}

.ui-tieredmenu ul {
	list-style: none;
	margin: 0;
	padding: 0
}

.ui-tieredmenu .ui-submenu-list {
	display: none;
	position: absolute;
	width: 12.5em;
	padding: .25em
}

.ui-tieredmenu .ui-menuitem-link {
	padding: .25em;
	display: block;
	cursor: pointer;
	position: relative;
	text-decoration: none
}

.ui-tieredmenu .ui-menuitem-icon {
	margin-right: .25em;
	vertical-align: middle
}

.ui-tieredmenu .ui-menuitem-text {
	vertical-align: middle
}

.ui-tieredmenu .ui-menuitem {
	position: relative;
	margin: .125em 0
}

.ui-tieredmenu .ui-menuitem-link .ui-submenu-icon {
	position: absolute;
	margin-top: -.5em;
	right: 0;
	top: 50%
}

.ui-tieredmenu .ui-menuitem-active > .ui-submenu > .ui-submenu-list {
	display: block
}

.ui-toast {
	position: fixed;
	width: 20em
}

.ui-toast .ui-toast-message {
	position: relative;
	display: block;
	margin-bottom: .75em;
	overflow: hidden
}

.ui-toast .ui-toast-message-content {
	padding: .5em 1em
}

.ui-toast .ui-toast-close-icon {
	position: absolute;
	top: .25em;
	right: .25em;
	cursor: pointer;
	display: inline-block
}

.ui-toast .ui-toast-summary {
	font-weight: bold;
	padding: 0 0 .5em 0;
	display: block
}

.ui-toast .ui-toast-icon {
	position: absolute;
	display: inline-block;
	left: .5em;
	top: .25em;
	padding: 0;
	font-size: 2em
}

.ui-toast .ui-toast-message-text-content {
	padding: 0 0 .25em 0;
	margin-left: 2.5em
}

.ui-toast-top-right {
	top: 20px;
	right: 20px
}

.ui-toast-top-left {
	top: 20px;
	left: 20px
}

.ui-toast-bottom-right {
	bottom: 20px;
	right: 20px
}

.ui-toast-bottom-left {
	bottom: 20px;
	left: 20px
}

.ui-toast-top-center {
	top: 20px;
	left: 50%;
	margin-left: -10em
}

.ui-toast-bottom-center {
	bottom: 20px;
	left: 50%;
	margin-left: -10em
}

.ui-toast-center {
	left: 50%;
	top: 50%;
	min-width: 20vw;
	transform: translate(-50%, -50%)
}

.ui-toolbar {
	padding: .25em .5em
}

.ui-toolbar-group-left {
	float: left
}

.ui-toolbar-group-right {
	float: right
}

.ui-tooltip {
	position: absolute;
	display: none;
	padding: .25em .5em;
	max-width: 12.5em
}

.ui-tooltip.ui-tooltip-right, .ui-tooltip.ui-tooltip-left {
	padding: 0 .25em
}

.ui-tooltip.ui-tooltip-top, .ui-tooltip.ui-tooltip-bottom {
	padding: .25em 0
}

.ui-tooltip .ui-tooltip-text {
	padding: .125em .5em;
	background-color: #4c4c4c;
	color: #fff;
	white-space: pre-line
}

.ui-tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid
}

.ui-tooltip-right .ui-tooltip-arrow {
	top: 50%;
	left: 0;
	margin-top: -.25em;
	border-width: .25em .25em .25em 0;
	border-right-color: #4c4c4c
}

.ui-tooltip-left .ui-tooltip-arrow {
	top: 50%;
	right: 0;
	margin-top: -.25em;
	border-width: .25em 0 .25em .25em;
	border-left-color: #4c4c4c
}

.ui-tooltip.ui-tooltip-top {
	padding: .25em 0
}

.ui-tooltip-top .ui-tooltip-arrow {
	bottom: 0;
	left: 50%;
	margin-left: -.25em;
	border-width: .25em .25em 0;
	border-top-color: #4c4c4c
}

.ui-tooltip-bottom .ui-tooltip-arrow {
	top: 0;
	left: 50%;
	margin-left: -.25em;
	border-width: 0 .25em .25em;
	border-bottom-color: #4c4c4c
}

.ui-tree {
	width: 18em
}

.ui-tree .ui-treenode-selectable.ui-treenode-content {
	cursor: pointer
}

.ui-tree .ui-tree-container {
	height: 100%;
	margin: 0;
	overflow: auto;
	padding: .25em;
	white-space: nowrap
}

.ui-tree-empty-message {
	padding: .25em
}

.ui-tree .ui-treenode-children {
	margin: 0;
	padding: 0 0 0 1em
}

.ui-tree .ui-treenode {
	background-attachment: scroll;
	background-color: transparent;
	background-image: none;
	background-position: 0 0;
	background-repeat: repeat-y;
	list-style: none outside none;
	margin: 0;
	padding: .125em 0 0 0
}

.ui-tree .ui-treenode-droppoint {
	height: 4px;
	list-style-type: none
}

.ui-tree .ui-treenode-droppoint-active {
	border: 0 none
}

.ui-tree .ui-tree-toggler {
	cursor: pointer;
	display: inline-block;
	vertical-align: middle
}

.ui-tree .ui-treenode-icon {
	display: inline-block;
	vertical-align: middle
}

.ui-tree .ui-treenode-label {
	display: inline-block;
	padding: 0 .25em;
	vertical-align: middle
}

.ui-tree .ui-treenode-label.ui-state-hover, .ui-tree .ui-treenode-label.ui-state-highlight {
	font-weight: normal;
	border: 0 none
}

.ui-tree .ui-treenode.ui-treenode-leaf > .ui-treenode-content > .ui-tree-toggler {
	visibility: hidden
}

.ui-tree .ui-chkbox-box {
	cursor: pointer
}

.ui-tree .ui-chkbox {
	display: inline-block;
	vertical-align: middle
}

.ui-tree .ui-chkbox .ui-chkbox-icon {
	margin-left: 1px
}

.ui-tree .ui-tree-filter {
	width: 100%;
	box-sizing: border-box;
	padding-right: 1.5em
}

.ui-tree .ui-tree-filter-container {
	position: relative;
	margin: 0;
	padding: .4em;
	display: inline-block;
	width: 100%
}

.ui-tree .ui-tree-filter-container .ui-tree-filter-icon {
	position: absolute;
	top: .8em;
	right: 1em
}

.ui-fluid .ui-tree {
	width: 100%
}

.ui-tree-horizontal {
	width: auto;
	padding: .5em 0;
	overflow: auto
}

.ui-tree.ui-tree-horizontal table, .ui-tree.ui-tree-horizontal tr, .ui-tree.ui-tree-horizontal td {
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	vertical-align: middle
}

.ui-tree.ui-tree-horizontal .ui-tree-toggler {
	vertical-align: middle;
	margin: 0
}

.ui-tree-horizontal .ui-treenode-content {
	font-weight: normal;
	padding: .4em 1em .4em .2em
}

.ui-tree.ui-tree-horizontal .ui-tree-node-label {
	margin: 0
}

.ui-tree-horizontal .ui-treenode-parent .ui-treenode-content {
	font-weight: normal;
	white-space: nowrap
}

.ui-tree.ui-tree-horizontal .ui-treenode {
	background: url("./images/line.gif") repeat-x scroll center center transparent;
	padding: .25em 2.5em
}

.ui-tree.ui-tree-horizontal .ui-treenode.ui-treenode-leaf, .ui-tree.ui-tree-horizontal .ui-treenode.ui-treenode-collapsed {
	padding-right: 0
}

.ui-tree.ui-tree-horizontal .ui-treenode-children {
	padding: 0;
	margin: 0
}

.ui-tree.ui-tree-horizontal .ui-treenode-connector {
	width: 1px
}

.ui-tree.ui-tree-horizontal .ui-treenode-connector-table {
	height: 100%;
	width: 1px
}

.ui-tree.ui-tree-horizontal .ui-treenode-connector-line {
	background: url("./images/line.gif") repeat-y scroll 0 0 transparent;
	width: 1px
}

.ui-tree.ui-tree-horizontal table {
	height: 0
}

.ui-tree.ui-tree-horizontal .ui-chkbox {
	vertical-align: bottom;
	margin-right: .25em
}

.ui-tree.ui-tree-loading {
	position: relative;
	min-height: 4em
}

.ui-tree .ui-tree-loading-mask {
	position: absolute;
	width: 100%;
	height: 100%;
	-ms-filter: "alpha(opacity=10)";
	opacity: .1;
	z-index: 1
}

.ui-tree .ui-tree-loading-content {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 2;
	margin-top: -1em;
	margin-left: -1em
}

.ui-tree .ui-tree-loading-content .ui-tree-loading-icon {
	font-size: 2em
}

.ui-treetable {
	position: relative
}

.ui-treetable table {
	border-collapse: collapse;
	width: 100%;
	table-layout: fixed
}

.ui-treetable .ui-treetable-thead > tr > th, .ui-treetable .ui-treetable-tbody > tr > td, .ui-treetable .ui-treetable-tfoot > tr > td {
	padding: .25em .5em
}

.ui-treetable .ui-treetable-toggler {
	cursor: pointer;
	display: inline-block;
	height: 1em;
	position: relative;
	top: 50%;
	margin-top: -.5em
}

.ui-treetable .ui-sortable-column {
	cursor: pointer
}

.ui-treetable p-treetablesorticon {
	vertical-align: middle
}

.ui-treetable-auto-layout > .ui-treetable-wrapper {
	overflow-x: auto
}

.ui-treetable-auto-layout > .ui-treetable-wrapper > table {
	table-layout: auto
}

.ui-treetable .ui-treetable-chkbox {
	margin: 0 .25em;
	vertical-align: middle
}

.ui-treetable-caption, .ui-treetable-summary {
	padding: .25em .5em;
	text-align: center;
	font-weight: bold
}

.ui-treetable-caption {
	border-bottom: 0 none
}

.ui-treetable-summary {
	border-top: 0 none
}

.ui-treetable .ui-paginator-top {
	border-bottom: 0 none
}

.ui-treetable .ui-paginator-bottom {
	border-top: 0 none
}

.ui-treetable-scrollable-wrapper {
	position: relative
}

.ui-treetable-scrollable-header, .ui-treetable-scrollable-footer {
	overflow: hidden;
	border: 0 none
}

.ui-treetable-scrollable-body {
	overflow: auto;
	position: relative
}

.ui-treetable-scrollable-body > table > .ui-treetable-tbody > tr:first-child > td {
	border-top: 0 none
}

.ui-treetable-virtual-table {
	position: absolute
}

.ui-treetable-frozen-view .ui-treetable-scrollable-body {
	overflow: hidden
}

.ui-treetable-frozen-view > .ui-treetable-scrollable-body > table > .ui-treetable-tbody > tr > td:last-child {
	border-right: 0 none
}

.ui-treetable-unfrozen-view {
	position: absolute;
	top: 0
}

.ui-treetable-resizable > .ui-treetable-wrapper {
	overflow-x: auto
}

.ui-treetable-resizable .ui-treetable-thead > tr > th, .ui-treetable-resizable .ui-treetable-tfoot > tr > td, .ui-treetable-resizable .ui-treetable-tbody > tr > td {
	overflow: hidden
}

.ui-treetable-resizable .ui-resizable-column {
	background-clip: padding-box;
	position: relative
}

.ui-treetable-resizable-fit .ui-resizable-column:last-child .ui-column-resizer {
	display: none
}

.ui-treetable .ui-column-resizer {
	display: block;
	position: absolute !important;
	top: 0;
	right: 0;
	margin: 0;
	width: .5em;
	height: 100%;
	padding: 0;
	cursor: col-resize;
	border: 1px solid transparent
}

.ui-treetable .ui-column-resizer-helper {
	width: 1px;
	position: absolute;
	z-index: 10;
	display: none
}

.ui-treetable-reorder-indicator-up, .ui-treetable-reorder-indicator-down {
	position: absolute;
	display: none
}

.ui-treetable-responsive .ui-treetable-tbody > tr > td .ui-column-title {
	display: none
}

@media screen and (max-width: 40em) {
	.ui-treetable-responsive .ui-treetable-thead > tr > th, .ui-treetable-responsive .ui-treetable-tfoot > tr > td {
		display: none !important
	}

	.ui-treetable-responsive .ui-treetable-tbody > tr > td {
		text-align: left;
		display: block;
		border: 0 none;
		width: 100% !important;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		float: left;
		clear: left
	}

	.ui-treetable-responsive .ui-treetable-tbody > tr > td .ui-column-title {
		padding: .4em;
		min-width: 30%;
		display: inline-block;
		margin: -.4em 1em -.4em -.4em;
		font-weight: bold
	}
}

.ui-treetable-loading {
	position: absolute;
	width: 100%;
	height: 100%;
	-ms-filter: "alpha(opacity=10)";
	opacity: .1;
	z-index: 1
}

.ui-treetable-loading-content {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 2;
	margin-top: -1em;
	margin-left: -1em
}

.ui-treetable .ui-treetable-loading-icon {
	font-size: 2em
}

.ui-virtualscroller .ui-virtualscroller-header {
	text-align: center;
	padding: .5em .75em;
	border-bottom: 0 none
}

.ui-virtualscroller .ui-virtualscroller-footer {
	text-align: center;
	padding: .25em .625em;
	border-top: 0 none
}

.ui-virtualscroller .ui-virtualscroller-list {
	list-style-type: none;
	margin: 0;
	padding: 0
}
