// @use 'src/assets/sass/mixins';

body {
	.ui-widget,
	.ui-widget .ui-widget {
		font-family: "Roboto", "Helvetica Neue", sans-serif;
		text-decoration: none;
	}

	.ui-widget-content {
		background-color: $contentBgColor;
		border: 1px solid $contentBorderColor;

		.pi {
			color: #757575;
		}
	}

	.ui-widget-header {
		background-color: $primaryColor;
		color: $primaryTextColor;
		border: 1px solid $primaryColor;

		.pi {
			color: $primaryTextColor;
		}
	}

	.ui-state-active, .ui-state-highlight {
		background-color: $accentColor;
		color: $accentTextColor;

		.pi {
			color: $accentTextColor;
		}
	}

	.ui-widget:disabled, .ui-state-disabled,
	.p-component:disabled, .p-element:disabled, *.p-disabled {
		// ========================================================================================================
		// .p-element:disabled targets UI elements like buttons and inputs, *.p-disabled cover all elements,
		// including div, for example. There are scenarios like with p-selectbutton, where .p-disabled is applied
		// on a div, but does not have :disabled. Therefore the two selectors are required!
		// ========================================================================================================
		opacity: .35;
		filter: Alpha(Opacity=35);
		background-image: none;
		cursor: auto !important;
	}

	.ui-corner-all {
		@include border-radius($borderRadius);
	}

	.ui-corner-top {
		@include border-radius-top($borderRadius);
	}

	.ui-corner-bottom {
		@include border-radius-bottom($borderRadius);
	}

	.ui-corner-left {
		@include border-radius-left($borderRadius);
	}

	.ui-corner-right {
		@include border-radius-right($borderRadius);
	}

	.ui-widget-overlay {
		background-color: #58575c;
		opacity: .8;
	}

	p-dialog {
		.p-component-overlay {
			// Overlay element wraps Dialog, thus this is a solution to its opacity being applied to it dialog
			background-color: rgba(88, 87, 92, 0.8);
			@include topBarClearance;
		}
	}

	.p-menu.p-component.p-menu-overlay  {
		@include topBarClearance; // To ensure that this clears any rendered .p-component-overlay
	}

	a {
		color: $primaryColor;
	}

	//region SelectButton

	p-selectbutton .p-selectbutton  .p-button {
		min-width: 100px;
	}

	//endregion
}
