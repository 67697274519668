@use 'variables/common';

@mixin border-radius($val) {
    -moz-border-radius: $val;
    -webkit-border-radius: $val;
    border-radius: $val;
}

@mixin border-radius-right($val) {
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin border-radius-left($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
}

@mixin border-radius-top($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
}

@mixin border-radius-bottom($val) {
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin multi-shadow($value1, $value2, $value3) {
    -webkit-box-shadow: $value1, $value2, $value3;
    -moz-box-shadow: $value1, $value2, $value3;
    box-shadow: $value1, $value2, $value3;
}

@mixin content-shadow() {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
    -moz-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12)
}

@mixin overlay-shadow() {
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}

@mixin overlay-content-shadow() {
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

@mixin overlay-input-shadow() {
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

@mixin no-shadow() {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

@mixin shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin hover-element {
    background-color: $hoverBgColor;
    color: $hoverTextColor;
}

@mixin hover-element-primary {
    background-color: $primaryLightColor;
    color: $hoverTextColor;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin slide-menu-transition() {
	transition: all ease-in 0.3s;
}

@mixin material-icon($icon) {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: $iconFontSize;  /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';

    &:before {
        content: $icon;
    }
}

@mixin icon-override($icon) {
    @include material-icon($icon);
}

@mixin border-box-sizing() {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -o-transform: rotate($deg);
    -ms-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin scale($deg) {
    -webkit-transform: scale($deg);
    -moz-transform: scale($deg);
    -o-transform: scale($deg);
    -ms-transform: scale($deg);
    transform: scale($deg);
}

@mixin rippleitem() {
    position: relative;
    overflow: hidden;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;
    -moz-box-sizing: $box-model;
    box-sizing: $box-model;
}

@mixin animation($delay, $duration, $animation) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@mixin flex() {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin background-gradient-top2bottom($start-color, $end-color) {
    background-color: $start-color;
    background-image: -webkit-gradient(linear, left top, left bottom, from($start-color), to($end-color));
    background-image: -webkit-linear-gradient(to bottom, $start-color, $end-color);
    background-image:    -moz-linear-gradient(to bottom, $start-color, $end-color);
    background-image:     -ms-linear-gradient(to bottom, $start-color, $end-color);
    background-image:      -o-linear-gradient(to bottom, $start-color, $end-color);
    background-image:         linear-gradient(to bottom, $start-color, $end-color);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}');
}

@mixin topBarClearancePlus() {
	z-index: calc($topBarElevation + 20) !important; // @target Toasts, to clear dialogs
}

@mixin topBarClearance() {
	z-index: calc($topBarElevation + 10) !important; // To clear TopBar (@target Dialogs)
}

@mixin textUnselectable() {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

@mixin alert-style() {
	padding: 4px 12px;
	color: #D52D13;

	width: 100%;
	display: flex;
	justify-content: flex-end;
}

@mixin invalid-input-style() {
	border-color: common.$red;
	background-image: linear-gradient(to bottom, common.$red, common.$red), linear-gradient(to bottom,common.$red, common.$red);
}

@mixin list-input-padding() {
	padding: common.$inputTopPadding common.$inputLeftRightPaddingLarge common.$inputBtmPadding;
}

@mixin tabbed-table-overhead-style() {
	.tabbed-table-overhead-opts {
		padding: 12px;
		color: #757575;

		span.tabbed-title {
			font-size: 1.2em;
		}

		&.tabbed-table-placement {
			display: grid;
			grid-template-columns: max-content 1fr;
			// column-gap: 96px; - Removed as it presence retains the contribution from tabbedTitle even when hidden
			align-items: center;
			min-height: 40px;

			background: white;
		}
	}
}

// $maxWidth should be an integer and this value is used for max-width and to determine
//  the next applicable @media - min-width - by adding 1 to it.
@mixin additional-info-rwd-at-media($maxWidth) {
	#stats-card {
		// To prevent overflow caused by inability to factor in "Column-gap = 40px" on parent container
		overflow-x: auto;

		div.ref-wrapper {

			flex-wrap: unset !important;
			row-gap: 8px !important;
			overflow-x: auto;

			h3 {
				min-width: fit-content !important;
			}
		}
	}


	//region Hide scrollbar
	.ref-wrapper::-webkit-scrollbar {
		display: none; // Chrome, Safari and Opera
	}

	.ref-wrapper {
		scrollbar-width: none; // Firefox
	}
	//endregion

	@media screen and (max-width: #{$maxWidth}px) {
		#stats-card div.ref-wrapper {
			// margin: 24px 0 30px;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			row-gap: 8px;
			column-gap: 8px;

			padding-top: 20px;
		}
	}

	@media screen and (min-width: #{$maxWidth + 1}px) {
		.container {
			display: grid;
			grid-template-columns: fit-content(50%) auto;
			column-gap: 40px; // to match gap between Find and Currency UI
		}

		#find-n-cur-wrapper {
			// flex-wrap: unset !important;
		}

		#stats-card div.ref-wrapper {
			display: flex;
			justify-content: left;
			flex-wrap: wrap;
			row-gap: 8px;
			column-gap: 8px;
		}
	}
}

@mixin table-global-rwd() {
	//region Tables global RWD
	// TODO - Dynamically set this to MIN_DESKTOP_CONTENT_WIDTH
	@media screen and (max-width: 1188px) {
		p-table {
			thead tr {
				display: none;
			}

			.p-datatable .p-datatable-tbody > tr {
				display: grid;
				// grid-template-columns: 33.33% 33.33% 33.33%;
				padding-left: 24px;
				padding-right: 52px;

				td.table-row-checkbox {
					position: absolute;
					left: 0;
					padding-left: 8px;
					padding-right: 8px;
				}

				td:not(.heDontTargetMe) {
					max-width: unset !important;
				}

				td.actions-col {
					position: absolute !important;
				}
			}
		}
	}

	@media screen and (max-width: 600px) {
		p-table {
			.p-datatable .p-datatable-tbody > tr {
				grid-template-columns: 100%;
				//padding-left: 24px;
				//padding-right: 52px;
			}
		}
	}

	@media screen and (min-width: 601px) and (max-width: 1023px) {
		:not(.ignore-rwd) {
			p-table {
				.p-datatable .p-datatable-tbody > tr {
					grid-template-columns: 50% 50%;
					//padding-left: 24px;
					//padding-right: 52px;
				}
			}
		}
	}

	@media screen and (min-width: 1024px) and (max-width: 1188px) {
		p-table {
			.p-datatable .p-datatable-tbody > tr {
				grid-template-columns: 33.33% 33.33% 33.33%;
				//padding-left: 24px;
				//padding-right: 52px;
			}
		}
	}
	//endregion
}
