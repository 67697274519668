/******************************/
/*           Common           */
/******************************/
$fontSize: 14px;
$fontFamily: "Roboto", "Helvetica Neue", sans-serif;
$textColor: #212121;
$textSecondaryColor: #757575;
$lineHeight: 18px;
$borderRadius: 3px;
$dividerColor: #dbdbdb;
$dividerLightColor: #f8f8f8;
$transitionDuration: .3s;
$iconWidth: 20px;
$iconHeight: 20px;
$iconFontSize: 20px;
$hoverBgColor: #e8e8e8;
$hoverTextColor: #000000;
$iconPrefix: 'ui-icon';
$topBarElevation: 999997;
$sideMenuElevation: $topBarElevation + 1;
$inputBtmPadding: 6px; // <input> tags
$inputTopPadding: 8px;
$inputLeftRightPaddingLarge: 12px;
$inputLeftRightPadding: 4px; // <input> tags

/* Predefined Colors */
$blue: #147df0;
$pink: #ed3c76;
$green: #3e9018;
$red: #da2f31;
$orange: #ffb200;
$teal: #599597;
$purple: #633ea5;
$black: #000000;
$yellow: #ffd644;
$grayBgColor: #757575;
