body {
	.ui-picklist {
		.ui-picklist-caption {
			padding: $headerPadding;
		}

		.ui-picklist-list {
			padding: 0;
		}

		li.ui-picklist-item {
			padding: $listItemPadding;
			margin: 0;
			@include border-radius(0);
			color: $textColor;

			&:not(.ui-state-highlight):hover {
				@include hover-element();
			}

			&.ui-state-highlight {
				background-color: $accentColor;
				color: $accentTextColor;
			}
		}

		.ui-picklist-buttons {
			width: 3.429em;

			.ui-button {
				&.ui-button-icon-only {
					width: 2.286em;
					margin-right: 0;
					display: inline-block;
					margin-bottom: 0.286em;
				}
			}
		}

		.ui-picklist-buttons-cell {
			text-align: center;
		}

		&.ui-picklist-responsive {
			.ui-picklist-buttons {
				width: 3.429em;

				.ui-button {
					&.ui-button-icon-only {
						margin: 0 auto;
						display: block;
						margin-bottom: 0.571em;
					}
				}
			}

			.ui-picklist-list {
				.ui-picklist-item {
					.ui-chkbox {
						margin-right: 0.571em;
						vertical-align: top;
					}

					.ui-chkbox, .ui-chkbox * {
						box-sizing: content-box;
					}
				}
			}
		}
	}

	.ui-orderlist {
		.ui-orderlist-caption {
			padding: $headerPadding;
			@include border-box-sizing();
		}

		.ui-orderlist-list {
			padding: 0;
			box-sizing: border-box;

			li.ui-orderlist-item {
				padding: $listItemPadding;
				margin: 0;
				@include border-radius(0);
				color: $textColor;

				&:not(.ui-state-highlight):hover {
					@include hover-element();
				}

				&.ui-state-highlight {
					background-color: $accentColor;
					color: $accentTextColor;
				}
			}
		}

		&.ui-orderlist-responsive {
			.ui-orderlist-controls {
				width: 4.286em;
			}
		}

		.ui-orderlist-controls {
			width: 4.286em;
			text-align: center;

			.ui-button {
				&.ui-button-icon-only {
					width: 2.286em;
					margin: 0 auto 0.571em auto;
				}
			}
		}
	}

	.ui-organizationchart {
		.ui-organizationchart-node-content {
			&.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover {
				@include hover-element();
			}

			&.ui-state-highlight {
				.ui-node-toggler {
					i {
						color: darken($accentColor, 25%);
					}
				}
			}
		}

		.ui-organizationchart-line-down {
			background-color: darken($contentBorderColor, 10%);
		}

		.ui-organizationchart-line-left {
			border-right: 1px solid darken($contentBorderColor, 10%);
		}

		.ui-organizationchart-line-right {

		}

		.ui-organizationchart-line-top {
			border-top: 1px solid darken($contentBorderColor, 10%);
		}

		.ui-organizationchart-node-content {
			border-color: darken($contentBorderColor, 10%);
		}

		.ui-organizationchart-node-content .ui-node-toggler {
			bottom: -1.143em;
			margin-left: -.825em;
			color: darken($contentBorderColor, 10%);

			.pi-chevron-down {
				@include material-icon("keyboard_arrow_down");
			}

			.pi-chevron-up {
				@include material-icon("keyboard_arrow_up");
			}
		}
	}

	.ui-paginator {
		background-color: $primaryDarkColor;
		padding: $paginatorPadding;

		> a {
			box-sizing: border-box;
			color: #ffffff;

			.pi {
				display: none;
			}

			&:not(.ui-state-disabled):not(.ui-state-active):hover {
				background-color: $primaryLightColor;
				@include border-radius(50%);
				@include transition(background-color $transitionDuration);
			}
		}

		.ui-paginator-next {
			padding: 0;
			vertical-align: middle;
			@include material-icon("navigate_next");
		}

		.ui-paginator-last {
			padding: 0;
			vertical-align: middle;
			@include material-icon("last_page");
		}

		.ui-paginator-prev {
			padding: 0;
			vertical-align: middle;
			@include material-icon("navigate_before");
		}

		.ui-paginator-first {
			padding: 0;
			vertical-align: middle;
			@include material-icon("first_page");
		}

		.ui-paginator-first, .ui-paginator-prev,
		.ui-paginator-next, .ui-paginator-last {
			min-width: 1.200em;
			width: 1.200em;
			height: 1.200em;
			line-height: 1.2;
		}

		.ui-paginator-pages {
			vertical-align: middle;
			margin: 0 6px 0 12px;
			padding: 0;

			a {
				padding: 0;
				color: $headerTextColor;
				min-width: 1.714em;
				min-height: 1.714em;
				line-height: 1.714em;
				@include border-radius(50%);
				@include transition(background-color $transitionDuration);

				&:not(.ui-state-active):hover {
					background-color: $primaryLightColor;
				}

				&.ui-state-active {
					background-color: $accentColor;
					color: $accentTextColor;
				}
			}
		}
	}

	.p-paginator {
		background-color: #f0f0f0;
		padding: $paginatorPadding;

		.p-paginator-pages button {
			color: #000;
		}

		/*> a {
			box-sizing: border-box;
			color: #ffffff;

			.pi {
				display: none;
			}

			&:not(.ui-state-disabled):not(.ui-state-active):hover {
				background-color: $primaryLightColor;
				@include border-radius(50%);
				@include transition(background-color $transitionDuration);
			}
		}*/

		.p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last {
			/*min-width: 1.200em;
			width: 1.200em;
			height: 1.200em;
			line-height: 1.2;*/

			margin: 0 8px;
			vertical-align: middle;
			color: #000;

			&.p-disabled {
				opacity: .35;
			}
		}

		.p-paginator-first {
			@include material-icon("first_page");
		}

		.p-paginator-next {
			@include material-icon("navigate_next");
		}

		.p-paginator-last {
			@include material-icon("last_page");
		}

		.p-paginator-prev {
			@include material-icon("navigate_before");
		}


		.p-paginator-pages {
			vertical-align: middle;
			margin: 0 12px;
			padding: 0;

			button {
				padding: 6px;
				// color: $headerTextColor;
				min-width: 24px;
				min-height: 24px;
				/*line-height: 1.714em;*/
				@include border-radius(50%);
				@include transition(background-color $transitionDuration);

				&:not(.p-highlight):hover {
					background-color: $primaryLightColor;
				}

				&.p-highlight {
					background-color: $accentColor;
					color: $accentTextColor;
				}

				&:not(:last-child) {
					margin-right: 4px;
				}
			}
		}
	}

	.ui-datagrid {
		.ui-datagrid-header {
			padding: $headerPadding;
		}

		.ui-panel {
			.ui-panel-titlebar {
				background-color: #ffffff;
				color: $textColor;
			}
		}
	}

	.ui-datalist {
		.ui-datalist-header {
			padding: $headerPadding;
		}
	}

	.ui-dataview {
		.ui-dataview-header {
			background-color: #ffffff;
			color: $textColor;
			padding: $headerPadding;
			border: 1px solid $dividerColor;
			border-bottom: 0 none;

			.ui-button {
				&.ui-state-active {
					&:hover {
						background-color: $accentColor;
						color: $accentTextColor;
					}
				}
			}
		}

		.ui-dataview-content {
			background-color: $contentBgColor;
			color: $textColor;
			border: 1px solid $contentBorderColor;
		}

		.ui-dataview-footer {
			background-color: #ffffff;
			color: $textColor;
			padding: $headerPadding;
			border: 1px solid $dividerColor;
			border-top: 0 none;
		}
	}

	.ui-table {
		.ui-table-caption,
		.ui-table-summary {
			padding: $headerPadding;
			font-weight: normal;
		}

		.ui-table-caption {
			border-bottom: 0 none;
		}

		.ui-table-summary {
			border-top: 0 none;
		}

		.ui-table-thead > tr > th,
		.ui-table-tbody > tr > td,
		.ui-table-tfoot > tr > td {
			padding: $dataTableHeaderPadding;
		}

		.ui-table-thead > tr > th,
		.ui-table-tfoot > tr > td {
			font-weight: $headerFontWeight;
			background-color: #ffffff;
			border: 0 none;
		}

		p-sortIcon {
			display: inline-block;
		}

		.ui-table-thead > tr > th {
			padding: $dataTableHeaderPadding;
			border: 0 none;
			text-align: left;
			font-weight: $headerFontWeight;
			border-top: 1px solid $contentBorderColor;
			background-color: #ffffff;

			&:first-child {
				border-left: 1px solid $contentBorderColor;
			}

			&:last-child {
				border-right: 1px solid $contentBorderColor;
			}

			&.ui-state-hover {
				@include hover-element();
			}

			.ui-sortable-column-icon {
				vertical-align: middle;
				margin: -4px 0 0 0;
				color: $textSecondaryColor;

				&.#{$iconPrefix}-carat-2-n-s {
					margin-left: 4px;
				}
			}

			.ui-column-resizer {
				@include material-icon("\e86f");
				font-size: $fontSize + 2;
				color: $textSecondaryColor;
			}

			&.ui-state-active, &.ui-state-highlight {
				background-color: $accentColor;
				color: $accentTextColor;
				border-top-color: $accentColor;

				.#{$iconPrefix} {
					color: $accentTextColor;
				}

				.ui-inputtext {
					color: $accentTextColor;

					&.ui-state-focus {
						border-color: $accentTextColor;
					}
				}
			}
		}

		.ui-table-tfoot > tr > td {
			padding: $dataTableHeaderPadding;
			border: 0 none;
			background-color: $contentBgColor;
			text-align: left;
			font-weight: $headerFontWeight;

			&:first-child {
				border-left: 1px solid $contentBorderColor;
			}

			&:last-child {
				border-right: 1px solid $contentBorderColor;
			}
		}

		.ui-sortable-column {
			color: $textColor;

			.ui-sortable-column-icon {
				color: $textSecondaryColor;
			}

			&:not(.ui-state-highlight):hover {
				@include hover-element();

				.ui-sortable-column-icon {
					@include hover-element();
				}
			}

			&.ui-state-highlight {
				background-color: $accentColor;
				color: $accentTextColor;
				border-top-color: $accentColor;

				.ui-sortable-column-icon {
					color: $accentTextColor;
				}
			}

			.ui-sortable-column-icon {
				vertical-align: middle;
			}
		}

		.ui-table-tbody {
			> tr {
				background: $contentBgColor;
				border: 1px solid $contentBorderColor;

				> td {
					background: inherit;
					padding: $dataTableCellPadding;
					border: 0 none;
				}

				&.ui-state-highlight {
					background-color: $accentColor;
					color: $accentTextColor;
				}

				&.ui-contextmenu-selected {
					background-color: $accentColor;
					color: $accentTextColor;
				}

				.ui-editing-cell {
					input {
						color: $accentTextColor;
					}
				}
			}

			> tr:nth-child(even) {
				background-color: $dataTableRowBgColorEven;

				&.ui-state-highlight {
					background-color: $accentColor;
					color: $accentTextColor;
				}

				&.ui-contextmenu-selected {
					background-color: $accentColor;
					color: $accentTextColor;
				}
			}
		}


		.ui-table-scrollable-wrapper {
			.ui-table-scrollable-header, .ui-table-scrollable-footer {
				border: 0 none;
				background-color: transparent;
				padding: 0;
			}

			thead {
				tr {
					th {
						color: $textColor;
						font-size: $fontSize;
					}
				}
			}

			tfoot {
				tr {
					td {
						color: $textColor;
						font-size: $fontSize;
					}
				}
			}
		}

		&.ui-table-hoverable-rows {
			.ui-table-tbody > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
				cursor: pointer;
				@include hover-element();
			}
		}
	}

	.ui-carousel {
		padding: 0;
		border: 1px solid transparent;

		.ui-carousel-header {
			padding: $headerPadding;
			overflow: visible;

			.ui-carousel-header-title {
				overflow: visible;
			}

			.pi {
				color: $headerTextColor;
			}

			.ui-carousel-button {
				margin: -.143em 0 0 0;
			}

			.ui-carousel-page-links {
				margin: -.143em 0 0 0;
			}
		}

		.ui-carousel-footer {
			margin: 0;
			padding: $headerPadding - 2px;
			font-size: $fontSize - 2px;
		}
	}

	.ui-tree {
		padding: $contentPadding;

		.ui-treenode-children {
			padding-left: 2em;
		}

		.ui-treenode-content {
			padding: $listItemPadding;
			padding-left: 0;
			padding-right: 0;
			display: inline-block;

			.ui-chkbox {
				margin: 0 0.286em 0 0.286em;

				.pi {
					color: #757575;
				}
			}

			.ui-tree-toggler {
				vertical-align: middle;
			}

			.ui-treenode-icon {
				vertical-align: middle;
				margin: 0 0.286em 0 0.286em;
			}

			.ui-treenode-label {
				padding: 0 0.286em 0 0.286em;
				margin: 0;
				vertical-align: middle;
			}

			&.ui-treenode-selectable:not(.ui-state-highlight) {
				.ui-treenode-label:not(.ui-state-highlight):hover {
					@include hover-element();
				}
			}

			&.ui-treenode-dragover {
				background: $primaryLightColor;

				> span {
					color: $primaryTextColor;
				}
			}
		}

		&.ui-tree-horizontal {
			padding-left: 0;
			padding-right: 0;

			.ui-treenode-content {
				background-color: $contentBgColor;
				border: 1px solid $contentBorderColor;
				padding: .4em 1em .4em .2em;

				.ui-tree-toggler {
					vertical-align: top;
				}

				.ui-treenode-icon {
					margin-right: 0.286em;
				}

				&.ui-treenode-selectable:not(.ui-state-highlight) {
					.ui-treenode-label:not(.ui-state-highlight):hover {
						@include hover-element();
					}
				}

				&.ui-state-highlight {
					background-color: $accentColor;
					color: $accentTextColor;

					.ui-treenode-icon {
						color: $accentTextColor;
					}
				}
			}
		}
	}

	.ui-tree-draghelper {
		border: 1px solid $primaryColor;
	}

	.fc {
		.fc-button {
			background-color: $primaryColor;
			color: $buttonTextColor;
			font-size: $fontSize;
			font-family: $fontFamily;
			height: 2.25em;
			padding: 0 1em;
			border: 0 none;

			&:enabled:not(:focus):not(.fc-button-active):hover {
				background-color: $primaryDarkColor;
			}

			&:enabled:not(.fc-button-active):focus {
				outline: 0 none;
				background-color: lighten($primaryColor, 10%);
				box-shadow: none;
			}

			&.fc-button-active {
				background-color: $accentColor;
				color: $accentTextColor;

				&:focus {
					box-shadow: none;
				}
			}
		}

		.fc-head {
			th {
				border-color: $primaryColor;
			}

			.fc-row {
				background-color: $primaryColor;
				color: $headerTextColor;
				border: 1px solid $primaryColor;
				padding: $headerPadding;
				font-weight: $headerFontWeight;
			}
		}

		td.fc-today {
			background-color: $accentColor;
			color: $accentTextColor;
		}

		.fc-toolbar {
			.fc-icon-chevron-right {
				font-family: 'Material Icons' !important;
				@include material-icon("play_circle_outline");
				text-indent: 0;
				position: relative;
				top: 1px;
			}

			.fc-icon-chevron-left {
				font-family: 'Material Icons' !important;
				@include material-icon("play_circle_outline");
				@include rotate(180deg);
				text-indent: 0;
				position: relative;
				top: 1px;
			}

			.ui-state-active {
				background-color: $accentColor;
				color: $accentTextColor;
			}
		}

		.fc-event {
			background-color: $primaryLightColor;
			color: $primaryTextColor;
		}

		table {
			box-sizing: border-box;
		}

		div.ui-widget-content {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.ui-treetable {
		.ui-treetable-caption,
		.ui-treetable-summary {
			padding: $headerPadding;
		}

		.ui-treetable-caption {
			border-bottom: 0 none;
		}

		.ui-treetable-summary {
			border-top: 0 none;
		}

		.ui-treetable-thead > tr > th,
		.ui-treetable-tbody > tr > td,
		.ui-treetable-tfoot > tr > td {
			padding: $dataTableHeaderPadding;
		}

		.ui-treetable-thead > tr > th,
		.ui-treetable-tfoot > tr > td {
			font-weight: $headerFontWeight;
			background-color: #ffffff;
			border: 0 none;
		}

		.ui-treetable-thead tr .ui-inputtext {
			width: 100%;
		}

		.ui-treetable-thead > tr > th {
			padding: $dataTableHeaderPadding;
			border: 0 none;
			text-align: left;
			font-weight: $headerFontWeight;
			border-top: 1px solid $contentBorderColor;
			background-color: #ffffff;

			&:first-child {
				border-left: 1px solid $contentBorderColor;
			}

			&:last-child {
				border-right: 1px solid $contentBorderColor;
			}

			&.ui-state-hover {
				@include hover-element();
			}

			.ui-sortable-column-icon {
				vertical-align: middle;
				margin: -4px 0 0 0;
				color: $textSecondaryColor;

				&.#{$iconPrefix}-carat-2-n-s {
					margin-left: 4px;
				}
			}

			.ui-column-resizer {
				@include material-icon("\e86f");
				font-size: $fontSize + 2;
				color: $textSecondaryColor;
			}

			&.ui-state-active, &.ui-state-highlight {
				background-color: $accentColor;
				color: $accentTextColor;
				border-top-color: $accentColor;

				.#{$iconPrefix} {
					color: $accentTextColor;
				}

				.ui-inputtext {
					color: $accentTextColor;

					&.ui-state-focus {
						border-color: $accentTextColor;
					}
				}
			}
		}

		.ui-treetable-tfoot > tr > td {
			padding: $dataTableHeaderPadding;
			border: 0 none;
			background-color: $contentBgColor;
			text-align: left;
			font-weight: $headerFontWeight;

			&:first-child {
				border-left: 1px solid $contentBorderColor;
			}

			&:last-child {
				border-right: 1px solid $contentBorderColor;
			}
		}

		.ui-treetable-scrollable-header {
			border: none;
		}

		.ui-treetable-loading-content {
			.pi-spin {
				-webkit-animation: 2s linear infinite fa-spin;
				animation: 2s linear infinite fa-spin;
				color: #ffffff;
			}
		}

		.ui-sortable-column {
			color: $textColor;

			.ui-sortable-column-icon {
				color: $textSecondaryColor;
			}

			&:not(.ui-state-highlight):hover {
				@include hover-element();

				.ui-sortable-column-icon {
					@include hover-element();
				}
			}

			&.ui-state-highlight {
				background-color: $accentColor;
				color: $accentTextColor;
				border-top-color: $accentColor;

				.ui-sortable-column-icon {
					color: $accentTextColor;
				}
			}

			.ui-sortable-column-icon {
				vertical-align: middle;
			}
		}

		.ui-treetable-tbody {
			> tr {
				background: $contentBgColor;
				border: 1px solid $contentBorderColor;

				> td {
					padding: $dataTableCellPadding;
					border: 0 none;

					.ui-treetable-toggler {
						color: $textSecondaryColor;

						.pi.pi-chevron-right {
							vertical-align: middle;
						}

						.pi.pi-chevron-down {
							vertical-align: middle;
						}
					}

					.ui-treetable-chkbox {
						margin-right: .75em;
						margin-top: 2px;

						.ui-chkbox-box.ui-state-active {
							&:before {
								-webkit-animation: none;
								animation: none;
							}
						}
					}
				}

				&.ui-state-highlight {
					background-color: $accentColor;
					color: $accentTextColor;

					> td {
						background: inherit;
						border: $dividerColor;

						.ui-treetable-toggler {
							color: $accentTextColor;
						}
					}

					.ui-chkbox {
						.ui-chkbox-box {
							border-color: $accentTextColor;
							background: transparent;

							.ui-chkbox-icon {
								color: $accentTextColor;
							}
						}
					}
				}

				&.ui-contextmenu-selected {
					background-color: $accentColor;
					color: $accentTextColor;

					> td {
						background: inherit;
						border: $dividerColor;

						.ui-treetable-toggler {
							color: $accentTextColor;
						}
					}
				}
			}
		}

		&.ui-treetable-hoverable-rows {
			.ui-treetable-tbody > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
				cursor: pointer;
				@include hover-element();
			}
		}

		.ui-treetable-scrollable-wrapper {
			.ui-treetable-scrollable-header, .ui-treetable-scrollable-footer {
				border: 0 none;
				background-color: transparent;
				padding: 0;
			}

			thead {
				tr {
					th {
						background-color: #ffffff;
						color: $textColor;
						border-bottom: 1px solid $dividerColor;
						border-top: 1px solid $dividerColor;

						&.ui-state-active {
							background-color: $accentColor;
							color: $accentTextColor;
						}
					}
				}
			}
		}

		&.ui-treetable-hoverable-rows {
			.ui-treetable-tbody > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
				cursor: pointer;
				@include hover-element();
			}
		}
	}

	.ui-virtualscroller {
		.ui-virtualscroller-content {
			padding: 0;
		}

		.ui-virtualscroller-list {
			li {
				border-bottom: 1px solid $dividerColor;
			}
		}
	}
}

@media (max-width: 40em) {
	body {
		.ui-orderlist {
			&.ui-orderlist-responsive {
				.ui-orderlist-controls {
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: 640px) {
	body {
		.ui-picklist {
			&.ui-picklist-responsive {
				.ui-picklist-list-wrapper {
					margin-bottom: .5em;
				}

				.ui-picklist-buttons {
					padding: .5em 0;

					.ui-button {
						&.ui-button-icon-only {
							display: inline-block;
							margin-right: .25em;
							margin-bottom: 0;
						}
					}

					.pi-angle-right {
						@include icon-override("play_arrow");
						@include rotate(90deg);
					}

					.pi-angle-double-right {
						@include icon-override("skip_previous");
						@include rotate(-90deg);

					}

					.pi-angle-left {
						@include icon-override("play_arrow");
						@include rotate(-90deg);
					}

					.pi-angle-double-left {
						@include icon-override("skip_next");
						@include rotate(-90deg);
					}
				}
			}
		}

		.ui-orderlist {
			&.ui-grid-responsive {
				.ui-orderlist-controls {
					text-align: center;
					width: auto;

					.ui-button {
						margin-right: .25em;
					}
				}
			}
		}
	}
}
